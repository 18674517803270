import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgEarleyGoal extends React.Component {
    initial_values = {
        item: '',
        stack: this.props.startSymbol + ' -> α•',
        n: this.props.inputLength.toString(),
        i: '0',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.item === '') {
                this.setState({
                    stack: this.props.startSymbol + ' -> α•',
                    n: this.props.inputLength.toString(),
                    i: '0'
                });
            } else {
                this.extractAdditionalItemValues(this.state.item);
            }
        } else {
            this.extractAdditionalItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const lhsSymbol = item.itemForm.substring(1, item.itemForm.indexOf(' '));
        const i = item.itemForm.substring(item.itemForm.indexOf(',') + 1,
            item.itemForm.lastIndexOf(','));
        const n = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        const rhs = item.itemForm.substring(item.itemForm.indexOf('->') + 3,
            item.itemForm.indexOf(','));
        const dotPos = rhs.indexOf('•');
        return lhsSymbol === this.props.startSymbol
            && n === this.props.inputLength.toString() && i === '0'
            && dotPos === rhs.length - 1;
    }

    extractAdditionalItemValues(item) {
        const stack = item.itemForm.substring(1, item.itemForm.indexOf(','));
        const i = item.itemForm.substring(item.itemForm.indexOf(',') + 1,
            item.itemForm.lastIndexOf(','));
        const n = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        this.setState({stack: stack, n: n, i: i});
    }

    setAntecedence(item) {
        this.setState({item: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.item !== '') {
            const goal = '[' + this.state.stack + ',' + this.state.i + ','
                + this.state.n + ']';
            this.props.sendNewGoalToTask(goal);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const stack = this.state.stack;
        const lhsSymbol = stack.substring(0, stack.indexOf(' '));
        const rhs = stack.substring(stack.indexOf('->') + 3);
        const rhsDotPos = rhs.indexOf('•');
        const leftStack = rhs.substring(0, rhsDotPos);
        const rightStack = rhsDotPos === rhs.length - 1 ? ''
            : rhs.substring(rhsDotPos + 1);
        const n = this.state.n;
        const i = this.state.i;
        let canDropLhs = 0;
        let canDropI = 0;
        let canDropN = 0;
        let canDropRhs = 0;
        let canDropDotEnd = 0;
        if (this.state.displayItem !== '') {
            canDropLhs = lhsSymbol === this.props.startSymbol ? 1 : -1;
            canDropRhs = 1;
            canDropN = n === this.props.inputLength.toString() ? 1 : -1;
            canDropI = i === '0' ? 1 : -1;
            canDropDotEnd = rightStack === '' ? 1 : -1;
        }
        return <div className='deduction-rule'>
            <div className='rule-name'>Goal:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-earley-goal'
                                 elementLists={[[lhsSymbol, '->', leftStack.trim(),
                                     '•', rightStack], [i], [n]]}
                                 markings={[[canDropLhs, 0, canDropRhs,
                                     canDropDotEnd, canDropRhs], [canDropI], [canDropN]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                    <span className={'unbreakable'}><span
                        className={canDropLhs === 1 ? 'can-drop'
                            : canDropLhs === -1 ? 'cannot-drop' : ''}>
                        {lhsSymbol}</span> ->&nbsp;
                        <span className={canDropRhs === 1 ? 'can-drop'
                            : canDropRhs === -1 ? 'cannot-drop' : ''}>
                        {rhs.replace('•', '')}</span> ∈ P</span>
            </div>
        </div>
    }
}

export default CfgEarleyGoal;
