import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from "../../taskcomponents/Task";
import Consequence from "../../taskcomponents/Consequence";
import {USER_CLEAR} from "../../constants";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgEarleyComplete extends React.Component {
    initial_values = {
        antecedence1: '',
        antecedence2: '',
        nt1: '',
        displayNt1: 'A',
        i: '',
        displayI: 'i',
        j: '',
        displayJ: 'j',
        k: '',
        displayK: 'k',
        alpha1: '',
        displayAlpha1: 'α',
        alpha2: '',
        displayAlpha2: 'ɣ',
        b: '',
        displayB: 'B',
        beta1: '',
        displayBeta1: 'β',
        displayBeta2: '',
        canDropItem1: 0,
        canDropItem2: 0,
        displayItem1: '',
        displayItem2: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItem1Values = this.extractAdditionalItem1Values.bind(this);
        this.extractAdditionalItem2Values = this.extractAdditionalItem2Values.bind(this);
        this.setDisplayItem1 = this.setDisplayItem1.bind(this);
        this.setDisplayItem2 = this.setDisplayItem2.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence1(item) {
        this.setState({
            antecedence1: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem1Values(item);
        this.checkNewItem();
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence2(item) {
        this.setState({
            antecedence2: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem2Values(item);
        this.checkNewItem();
    }

    extractAdditionalItem1Values(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            nt1: lhs,
            alpha1: alpha,
            i: i,
            j: j,
            b: b,
            beta1: beta,
            canDropItem1: 0,
            displayItem1: ''
        });
    }

    extractAdditionalItem2Values(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        this.setState({
            b: lhs,
            alpha2: alpha,
            j: i,
            k: j,
            canDropItem2: 0,
            displayItem2: ''
        });
    }

    extractAdditionalDisplayItem1Values(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            displayNt1: lhs,
            displayAlpha1: alpha,
            displayI: i,
            displayJ: j,
            displayB: b === '' ? 'ε' : b,
            displayBeta1: beta,
            canDropItem1: 0
        });
    }

    extractAdditionalDisplayItem2Values(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? 'ε' : rhs.substring(0, dotPos - 1);
        const beta = rhs.substring(dotPos + 1);
        this.setState({
            displayB: lhs,
            displayAlpha2: alpha,
            displayJ: i,
            displayK: j,
            canDropItem2: 0,
            displayBeta2: beta
        });
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem1(item) {
        const firstComma = item.itemForm.indexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const ruleSplit = ruleStack.split('->');
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        if (this.state.antecedence2 === '' && b.length > 0 && this.props.isNonterminal(b)) {
            return true;
        }
        const secondComma = item.itemForm.lastIndexOf(',');
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        return b === this.state.b && j === this.state.j && this.props.isNonterminal(b);
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem2(item) {
        const firstComma = item.itemForm.indexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        if (b.length > 0) {
            return false;
        }
        if (this.state.antecedence1 === '') {
            return true;
        }
        const secondComma = item.itemForm.lastIndexOf(',');
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        return lhs === this.state.b && i === this.state.j;
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem1(item) {
        this.setState({
            displayItem1: item
        });
        if (item === '') {
            if (this.state.antecedence1 === '') {
                this.setState({
                    displayNt1: 'A',
                    displayI: 'i',
                    displayJ: 'j',
                    displayAlpha1: 'α',
                    displayB: 'B',
                    displayBeta1: 'β'
                });
            } else {
                this.extractAdditionalDisplayItem1Values(this.state.antecedence1);
            }
            if (this.state.antecedence2 !== '') {
                this.extractAdditionalDisplayItem2Values(this.state.antecedence2);
            }
        } else {
            this.extractAdditionalDisplayItem1Values(item);
        }
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem2(item) {
        this.setState({
            displayItem2: item
        });
        if (item === '') {
            if (this.state.antecedence2 === '') {
                this.setState({
                    displayJ: 'j',
                    displayK: 'k',
                    displayB: 'B',
                    displayAlpha2: 'ɣ',
                    displayBeta2: ''
                });
            } else {
                this.extractAdditionalDisplayItem2Values(this.state.antecedence2);
            }
            if (this.state.antecedence1 !== '') {
                this.extractAdditionalDisplayItem1Values(this.state.antecedence1);
            }
        } else {
            this.extractAdditionalDisplayItem2Values(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem1(value) {
        this.setState({
            canDropItem1: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem2(value) {
        this.setState({
            canDropItem2: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence1 !== '' && this.state.antecedence2 !== '') {
            let newItem = '[' + this.state.nt1 + ' -> ';
            if (this.state.alpha1 !== '') {
                newItem = newItem + this.state.alpha1 + ' ';
            }
            newItem = newItem + this.state.b + ' •';
            if (this.state.beta1 !== '') {
                newItem = newItem + this.state.beta1;
            }
            newItem = newItem + ',' + this.state.i + ',' + this.state.k + ']';
            if (this.props.sendNewItemToTask(newItem, 'complete ' + this.state.b,
                [this.state.antecedence1.id, this.state.antecedence2.id].sort())) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession,
            'cfg-earley-complete'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const nt1 = this.state.displayNt1;
        const i = this.state.displayI;
        const j = this.state.displayJ;
        const k = this.state.displayK;
        const alpha1 = this.state.displayAlpha1;
        const alpha2 = this.state.displayAlpha2;
        const b = this.state.displayB;
        const beta1 = this.state.displayBeta1;
        const beta2 = this.state.displayBeta2;
        const canDropBeta2 = beta2.length > 0 ? -1 : 0;
        const canDropItem1 = this.state.canDropItem1;
        const canDropItem2 = this.state.canDropItem2;
        const item1Hovering = canDropItem1 === 0 ? 0 : 1;
        const item2Hovering = canDropItem2 === 0 ? 0 : 1;
        let canDropB = 0;
        if (item1Hovering === 1) {
            if (b.length > 0 && this.props.isNonterminal(b)
                && (this.state.antecedence2 === '' || this.state.b === b)) {
                canDropB = 1;
            } else {
                canDropB = -1;
            }
        }
        if (item2Hovering === 1) {
            if (b.length > 0 && this.props.isNonterminal(b)
                && (this.state.antecedence1 === '' || this.state.b === b)) {
                canDropB = 1;
            } else {
                canDropB = -1;
            }
        }
        let canDropJ = 0;
        if (item1Hovering === 1) {
            if (this.state.antecedence2 === '' || this.state.j === j) {
                canDropJ = 1;
            } else {
                canDropJ = -1;
            }
        }
        if (item2Hovering === 1) {
            if (this.state.antecedence1 === '' || this.state.j === j) {
                canDropJ = 1;
            } else {
                canDropJ = -1;
            }
        }
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                        onClick={this.clearWithLog.bind(this)}><FontAwesomeIcon
                    className={"clickable-icon"}
                    icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear earley complete</span>
                </button>
                Complete:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-earley-complete1'
                                 elementLists={[[nt1, '->', alpha1, '•' + b,
                                     beta1], [i], [j]]}
                                 markings={[[item1Hovering, 0, item1Hovering,
                                     canDropB, item1Hovering],
                                     [item1Hovering], [canDropJ]]}
                                 setAntecedence={this.setAntecedence1.bind(this)}
                                 canDropItem={this.canDropItem1.bind(this)}
                                 setDisplayItem={this.setDisplayItem1.bind(this)}
                                 setCanDropItem={this.setCanDropItem1.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>&nbsp;
                    <Antecedence antecedenceName='cfg-earley-complete2'
                                 elementLists={[[b, '->', alpha2, '•', beta2], [j], [k]]}
                                 markings={[[canDropB, 0, item2Hovering, 0,
                                     canDropBeta2],
                                     [canDropJ], [item2Hovering]]}
                                 setAntecedence={this.setAntecedence2.bind(this)}
                                 canDropItem={this.canDropItem2.bind(this)}
                                 setDisplayItem={this.setDisplayItem2.bind(this)}
                                 setCanDropItem={this.setCanDropItem2.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[nt1, "->", alpha1, b, "•" + beta1], [i],
                            [k]]}
                        markings={[[item1Hovering, 0, item1Hovering, canDropB,
                            item1Hovering], [item1Hovering], [item2Hovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
        </div>
    }
}

export default CfgEarleyComplete;
