import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidPredictAdjoined() {
    return (<div className='deduction-rule'>
            <div className='rule-name'>PredictAdjoined:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <span
                        className={'unbreakable'}>[β, p<sub>f</sub>, la, i<sub>β</sub>, i, -, -, m, 0]</span>,&nbsp;
                    <span className={'unbreakable'}>[γ, p, la, i<sub>β</sub>, ~, ~, ~, i<sub>β</sub>, 0]</span>
                </div>
                <hr/>
                <div
                    className='consequence'><span className={'unbreakable'}>[γ, p, lb, i<sub>β</sub>, m, -, -, m, 0]</span>
                </div>
            </div>
            <div className='side-condition'><span
                className={'unbreakable'}>β(p<sub>f</sub>) foot node</span>,&nbsp;
                <span className={'unbreakable'}>β ∈ f<sub>SA</sub>(γ, p)</span>
            </div>
        </div>
    )
}
