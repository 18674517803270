import React from "react";
import PropTypes from "prop-types";

/**
 * Displays the given strings slash-separated with all but the least displayed
 * as links.
 */
class Breadcrumb extends React.Component {
    render() {
        let linkedPath = [];
        let fullPath = "/";
        if (this.props.pathList.length === 0) {
            linkedPath.push("Home");
        } else {
            linkedPath.push(<a key={fullPath} href={"/"}>Home</a>);
        }
        for (let i = 0; i < this.props.pathList.length; i++) {
            let path = this.props.pathList[i];
            if(i === this.props.pathList.length-1) {
                linkedPath.push(" / ");
                fullPath += path.toLowerCase();
                linkedPath.push(<span key={fullPath}>{path}</span>);
            } else {
                linkedPath.push(" / ");
                fullPath += path.toLowerCase();
                linkedPath.push(<a key={fullPath} href={fullPath}>{path}</a>);
            }
        }
       return (<div id={"breadcrumb"}>{linkedPath}</div>);
    }
}

Breadcrumb.proptypes = {
    pathList: PropTypes.array.isRequired
};

export default Breadcrumb;
