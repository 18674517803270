import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function PcfgAstarScan() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Scan:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>|log(p)| + out(A, i − 1, 1, n − i) : [A, i − 1, i]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>p : A -> w<sub>i</sub> ∈ P, </span>
            <span className={'unbreakable'}>i in [1...n+1]</span></div>
    </div>)
}
