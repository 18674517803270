import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import SideConditionRule from '../../taskcomponents/SideConditionRule.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from "../../taskcomponents/Task";
import Consequence from "../../taskcomponents/Consequence";
import {USER_CLEAR} from "../../constants";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgLeftCornerReduce extends React.Component {
    initial_values = {
        antecedence: '',
        x: '',
        displayX: 'X1',
        alpha: '',
        displayAlpha: 'α',
        lhs: '',
        displayLhs: 'A',
        rhs: '',
        displayRhs: 'X1 X2 ... Xk',
        b: '',
        displayB: 'B',
        beta: '',
        displayBeta: 'β',
        gamma: '',
        displayGamma: 'γ',
        prodRule: '',
        canDropItem: 0,
        canDropRule: 0,
        displayItem: '',
        displayRule: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.extractAdditionalRuleValues = this.extractAdditionalRuleValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence(item) {
        this.setState({
            antecedence: item,
            eraseDisabled: false
        });
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    extractAdditionalItemValues(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const firstPart = item.itemForm.substring(1, comma1);
        const secondPart = item.itemForm.substring(comma1 + 1, comma2);
        const firstSpace = firstPart.indexOf(' ');
        const secondSpace = secondPart.indexOf(' ');
        const x = firstSpace !== -1 ? firstPart.substring(0, firstSpace)
            : (firstPart.length > 0 ? firstPart : 'ε');
        const alpha = firstSpace !== -1 ? firstPart.substring(firstSpace + 1) : '';
        const b = secondSpace !== -1 ? secondPart.substring(0, secondSpace)
            : (secondPart.length > 0 ? secondPart : 'ε');
        const beta = secondSpace !== -1 ? secondPart.substring(secondSpace + 1) : '';
        let gamma = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        if (gamma === 'ε') {
            gamma = '';
        }
        this.setState({
            x: x,
            alpha: alpha,
            b: b,
            beta: beta,
            gamma: gamma,
            canDropItem: 0,
            canDropRule: 0,
            displayItem: ''
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const firstPart = item.itemForm.substring(1, comma1);
        const secondPart = item.itemForm.substring(comma1 + 1, comma2);
        const firstSpace = firstPart.indexOf(' ');
        const secondSpace = secondPart.indexOf(' ');
        const x = firstSpace !== -1 ? firstPart.substring(0, firstSpace)
            : (firstPart.length > 0 ? firstPart : 'ε');
        const alpha = firstSpace !== -1 ? firstPart.substring(firstSpace + 1) : 'ε';
        const b = secondSpace !== -1 ? secondPart.substring(0, secondSpace)
            : (secondPart.length > 0 ? secondPart : 'ε');
        const beta = secondSpace !== -1 ? secondPart.substring(secondSpace + 1) : 'ε';
        const gamma = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        this.setState({
            displayX: x,
            displayRhs: x + " X2 ... Xk",
            displayAlpha: alpha,
            displayB: b,
            displayBeta: beta,
            displayGamma: gamma,
            canDropItem: 0,
            canDropRule: 0,
            displayItem: ''
        });
    }

    /**
     * Set rule as production rule in state and extract different other values
     * from it to be set in state.
     */
    setProdRule(rule) {
        this.setState({prodRule: rule, eraseDisabled: false});
        this.extractAdditionalRuleValues(rule);
        this.checkNewItem();
    }

    extractAdditionalRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const space = rhs.indexOf(' ');
        const x = space === -1 ? rhs : rhs.substring(0, space);
        this.setState({lhs: lhs, rhs: rhs, x: x});
    }

    extractAdditionalDisplayRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const space = rhs.indexOf(' ');
        const x = space === -1 ? rhs : rhs.substring(0, space);
        this.setState({displayLhs: lhs, displayRhs: rhs, displayX: x});
    }

    setDisplayRule(rule) {
        this.setState({displayRule: rule});
        if (rule === '') {
            if (this.state.prodRule === '') {
                if (this.state.antecedence === '') {
                    this.setState({
                        displayLhs: 'A',
                        displayRhs: 'X1 X2 ... Xk',
                        displayX: 'X1',
                    });
                } else {
                    this.setState({
                        displayLhs: 'A',
                        displayRhs: this.state.x + ' X2 ... Xk'
                    });
                    this.extractAdditionalDisplayItemValues(this.state.antecedence);
                }
            } else {
                this.extractAdditionalDisplayRuleValues(this.state.prodRule);
            }
        } else {
            this.extractAdditionalDisplayRuleValues(rule);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const firstPart = item.itemForm.substring(1, firstComma);
        const mayDollar = item.itemForm.substring(firstComma + 1, firstComma + 3);
        if (firstPart.length === 0 || mayDollar === '$ ' || mayDollar.startsWith('ε')) {
            return false;
        }
        if (this.state.prodRule === '') {
            return true;
        }
        const space = firstPart.indexOf(' ');
        const x = space === -1 ? firstPart : firstPart.substring(0, space);
        return x === this.state.x;
    }

    /**
     * Returns true if rule can be dropped on the side condition component.
     */
    canDropRule(rule) {
        const ruleSplit = rule.rule.split('->');
        const rhs = ruleSplit[1].trim();
        if (this.state.antecedence === '' && rhs.length > 0 && rhs !== 'ε') {
            return true;
        }
        const space = rhs.indexOf(' ');
        const x = space === -1 ? rhs : rhs.substring(0, space);
        return x === this.state.x && rhs.length > 0 && rhs !== 'ε';
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                if (this.state.prodRule === '') {
                    this.setState({
                        displayX: 'X1',
                        displayAlpha: 'α',
                        displayB: 'B',
                        displayBeta: 'β',
                        displayGamma: 'γ',
                        displayRhs: 'X1 X2 ... Xk'
                    });
                } else {
                    this.setState({
                        displayAlpha: 'α',
                        displayB: 'B',
                        displayBeta: 'β',
                        displayGamma: 'γ'
                    });
                    this.extractAdditionalDisplayRuleValues(this.state.prodRule);
                }
            } else {
                this.extractAdditionalDisplayItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropRule(value) {
        this.setState({
            canDropRule: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence !== '' && this.state.prodRule !== '') {
            let newItem = '[';
            if (this.state.alpha === '') {
                newItem = newItem + 'ε';
            } else {
                newItem = newItem + this.state.alpha;
            }
            newItem = newItem + ',';
            const space = this.state.rhs.indexOf(' ');
            if (space >= 0) {
                newItem = newItem + this.state.rhs.substring(space + 1) + ' ';
            }
            newItem = newItem + '$ ' + this.state.b;
            if (this.state.beta !== '') {
                newItem = newItem + ' ' + this.state.beta;
            }
            newItem = newItem + ',' + this.state.lhs;
            if (this.state.gamma !== '') {
                newItem = newItem + ' ' + this.state.gamma;
            }
            newItem = newItem + ']';

            if (this.props.sendNewItemToTask(newItem, 'reduce '
                + this.state.prodRule.rule, [this.state.antecedence.id])) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession,
            'cfg-leftcorner-reduce'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const x = this.state.displayX;
        const alpha = this.state.displayAlpha;
        const b = this.state.displayB;
        const beta = this.state.displayBeta;
        const gamma = this.state.displayGamma;
        const lhs = this.state.displayLhs;
        const rhs = this.state.displayRhs;
        const rhsSpace = rhs.indexOf(' ');
        let rhsRest = '';
        if (rhsSpace !== -1) {
            rhsRest = rhs.substring(rhsSpace + 1);
        }
        const canDropItem = this.state.canDropItem;
        const canDropRule = this.state.canDropRule;
        const ruleHovering = this.state.displayRule === '' ? 0 : 1;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        const isNotDollarAndNotEmpty = itemHovering === 0 ? 0 : (b === '$' ? -1 : (b === 'ε' ? -1 : 1));
        const canDropLC = canDropItem === -1 ? (isNotDollarAndNotEmpty !== 0 ? 0 : -1) : canDropItem;
        const canDropObject = canDropRule !== 0 ? canDropRule : canDropLC;
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                      onClick={this.clearWithLog.bind(this)}>
                    <FontAwesomeIcon className={"clickable-icon"}
                                     icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear left-corner reduce</span>
                </button>
                Reduce:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-leftcorner-reduce'
                                 elementLists={[[x, alpha], [b, beta], [gamma]]}
                                 markings={[[canDropObject, itemHovering],
                                     [isNotDollarAndNotEmpty, itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[alpha], [rhsRest, "$", b, beta], [lhs,
                            gamma]]}
                        markings={[[itemHovering], [canDropRule, ruleHovering, isNotDollarAndNotEmpty,
                            itemHovering],
                            [ruleHovering, itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <SideConditionRule
                    displayLhs={lhs} displayRhs={rhs}
                    setProdRule={this.setProdRule.bind(this)}
                    setDisplayRule={this.setDisplayRule.bind(this)}
                    canDropRule={this.canDropRule.bind(this)}
                    setCanDropRule={this.setCanDropRule.bind(this)}
                    canDropLhs={ruleHovering}
                    canDropRhs={canDropLC}
                    userSession={this.props.userSession}
                    ruleName={"cfg-leftcorner-reduce"}/> <span className="unbreakable">∈ P, {b} ≠ $</span>
            </div>
        </div>
    }
}

export default CfgLeftCornerReduce;
