import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgLeftCornerMove extends React.Component {
    initial_values = {
        antecedence: '',
        alpha: '',
        displayAlpha: 'α',
        dollar: '',
        displayDollar: '$',
        beta: '',
        displayBeta: 'β',
        a: '',
        displayA: 'A',
        gamma: '',
        displayGamma: 'γ',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    displayAlpha: 'α',
                    displayDollar: '$',
                    displayBeta: 'β',
                    displayA: 'A',
                    displayGamma: 'γ'
                });
            } else {
                this.extractAdditionalDisplayItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const thirdStack = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const secondSpace = secondStack.indexOf(' ');
        const thirdSpace = thirdStack.indexOf(' ');
        const mayDollar = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        const mayA = thirdSpace === -1 ? thirdStack : thirdStack.substring(0, thirdSpace);
        return mayDollar === '$' && this.props.isNonterminal(mayA);
    }

    extractAdditionalItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const alpha = item.itemForm.substring(1, firstComma);
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const thirdStack = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const secondSpace = secondStack.indexOf(' ');
        const thirdSpace = thirdStack.indexOf(' ');
        const mayDollar = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        const beta = secondSpace === -1 ? '' : secondStack.substring(secondSpace + 1);
        const mayA = thirdSpace === -1 ? thirdStack : thirdStack.substring(0, thirdSpace);
        const gamma = thirdSpace === -1 ? 'ε' : thirdStack.substring(thirdSpace + 1);
        this.setState({
            alpha: alpha,
            b: mayDollar,
            beta: beta,
            a: mayA,
            gamma: gamma
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const alpha = item.itemForm.substring(1, firstComma);
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const thirdStack = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const secondSpace = secondStack.indexOf(' ');
        const thirdSpace = thirdStack.indexOf(' ');
        const mayDollar = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        const beta = secondSpace === -1 ? 'ε' : secondStack.substring(secondSpace + 1);
        const mayA = thirdSpace === -1 ? thirdStack : thirdStack.substring(0, thirdSpace);
        const gamma = thirdSpace === -1 ? 'ε' : thirdStack.substring(thirdSpace + 1);
        this.setState({
            displayAlpha: alpha,
            displayDollar: mayDollar,
            displayBeta: beta,
            displayA: mayA,
            displayGamma: gamma
        });
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[';
            newItem = newItem + this.state.a;
            if (this.state.alpha !== 'ε') {
                newItem = newItem + ' ' + this.state.alpha;
            }
            newItem = newItem + ',' + this.state.beta + ',' + this.state.gamma
                + ']';
            this.props.sendNewItemToTask(newItem, 'move ' + this.state.a,
                [this.state.antecedence.id]);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const alpha = this.state.displayAlpha;
        const dollar = this.state.displayDollar;
        const beta = this.state.displayBeta;
        const a = this.state.displayA;
        const gamma = this.state.displayGamma;
        const canDropItem = this.state.canDropItem;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        const isDollar = itemHovering === 0 ? 0 : (dollar === '$' ? 1 : -1);
        const isNonterminal = itemHovering === 0 ? 0 : this.props.isNonterminal(a) ? 1 : -1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Move:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-leftcorner-move'
                                 elementLists={[[alpha], [dollar, beta], [a, gamma]]}
                                 markings={[[itemHovering], [isDollar, itemHovering], [isNonterminal, itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[a, alpha], [beta], [gamma]]}
                        markings={[[isNonterminal, itemHovering], [itemHovering], [itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <span className={'unbreakable'}><span
                    className={isNonterminal === 1 ? 'can-drop' : isNonterminal === -1 ? 'cannot-drop' : ''}>{a}</span> ∈
                    N</span>
            </div>
        </div>
    }
}

export default CfgLeftCornerMove;
