import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaintBrush} from "@fortawesome/free-solid-svg-icons";

export class DrawButton extends React.Component {
    render() {
        return (
                <button id='drawSwitchButton' onClick={this.props.handleSwitchDrawing}>
                    <FontAwesomeIcon className={'clickable-icon'}
                                     icon={faPaintBrush}/>
                    <span
                        className={'accessibility-label'}>switch drawing</span>
                </button>
        );
    }
}
