import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyAdjoin() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Adjoin:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[β, ε, ra, i, j, k, l, 0]</span>,&nbsp;
                <span className={'unbreakable'}>[γ, dot,rb, j, p, q, k, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, dot, rb, i, p, q, l, 1]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>β ∈ f<sub>SA</sub>(γ, p)</span></div>
    </div>)
}
