import React from 'react';
import ProductionRule from './ProductionRule';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faEdit} from '@fortawesome/free-solid-svg-icons';
import {pushWithBreaks} from "./display-grammar-util";

/**
 * Displays the grammar for the exercise. Might be editable or not.
 */
export class SrcgGrammar extends React.Component {
    constructor(props) {
        super(props);
        let beforeProd = '';
        let prodList = [];
        let afterProd = '';
        if (props.grammar !== '') {
            const p = /([\s\S]*P\s*=\s*{)([^}]*)(}[\s\S]*)/m;
            const match = p.exec(props.grammar);
            if (match != null) {
                beforeProd = match[1];
                const prod = match[2];
                if (prod.length > 0) {
                    prodList = SrcgGrammar.splitProductions(prod);
                } else {
                    prodList = [];
                }
                afterProd = match[3];
            } else {
                beforeProd = "Something went wrong when parsing the production rules. Please check the syntax."
            }
        }
        this.state = {
            beforeProd: beforeProd,
            prodList: prodList,
            afterProd: afterProd
        }
    }

    static displayInteractableGrammar(beforeProd, prodList, afterProd, userSession) {
        let displayGrammar = [];
        let i = 1;
        pushWithBreaks(beforeProd, displayGrammar, 'before');
        prodList.forEach(function (element) {
                displayGrammar.push(<ProductionRule rule={element} key={i}
                                                    userSession={userSession}/>);
                i++;
            }
        );
        pushWithBreaks(afterProd, displayGrammar, 'after');
        return displayGrammar;
    }

    render() {
        const editable = this.props.editable;
        const beforeProd = this.state.beforeProd.replaceAll(" = ", "\u00A0=\u00A0");
        const prodList = this.state.prodList;
        const afterProd = this.state.afterProd.replaceAll(" = ", "\u00A0=\u00A0");
        const userSession = this.props.userSession;
        return (
            <div className='panel' id='grammarPanel'>
                <button id='grammarEditIcon'
                        onClick={this.props.handleChangeGrammar}>
                    {editable
                        ? <FontAwesomeIcon className={'clickable-icon'} icon={faCheck}/>
                        : <FontAwesomeIcon className={'clickable-icon'} icon={faEdit}/>
                    }
                    {editable
                        ? <span className={'accessibility-label'}> edit grammar done</span>
                        : <span className={'accessibility-label'}>edit grammar</span>}
                </button>
                <div className='grammar'>
                    {editable
                        ? <span><label htmlFor="tGrammar"
                                       className={'accessibility-label'}>Grammar</label>
                            <textarea id='tGrammar' rows='4'
                                      defaultValue={this.props.grammar}/></span>
                        : SrcgGrammar.displayInteractableGrammar(beforeProd,
                            prodList, afterProd, userSession)
                    }
                </div>
                {prodList.length === 0 ?
                    <div className='no-task-message'>There is no task! Click New
                        in the menu to generate a task or click the edit symbols
                        for a custom task.</div> : ''}
            </div>
        );
    }

    static splitProductions(productions) {
        let splitProds = [];
        let inBrackets = 0;
        let lastCommaPos = -1;
        for (let i = 0; i < productions.length; i++) {
            const symbol = productions[i];
            if (symbol === "(") {
                inBrackets++;
            } else if (symbol === ")") {
                inBrackets--;
            } else if (symbol === "," && inBrackets === 0) {
                splitProds.push(productions.substring(lastCommaPos + 1, i).trim());
                lastCommaPos = i;
            }
        }
        splitProds.push(productions.substr(lastCommaPos + 1, productions.length).trim());
        return splitProds;
    }
}
