import React from "react";
import {Input} from "./taskcomponents/Input";
import Tree from "./taskcomponents/Tree";
import {getUrlParamDecoded} from "./taskcomponents/Task";

/**
 * A page with a text input field that renders trees from tree strings in
 * bracket notation.
 */
class TreePage extends React.Component {
    constructor(props) {
        super(props);
        const treeString = getUrlParamDecoded(props,"tree");
        this.state = {
            treeString: treeString,
            inputEditable: false
        };
    }

    static gotoLocation(treeString) {
        document.location.search = '?tree=' + encodeURIComponent(treeString);
    }

    switchEditableInput(){
        if (this.state.inputEditable === true) {
            const input = document.getElementById('wInput');
            const inputValue = input === null ? this.state.input : input.value;
            TreePage.gotoLocation(inputValue);
        }
        this.setState({inputEditable: !this.state.inputEditable});
    }
    render() {
        return (<span><Input input={this.state.treeString}
                             handleChangeInput={this.switchEditableInput.bind(this)}
                             editable={this.state.inputEditable}/><br/>
            <Tree treeString={this.state.treeString}/>
        </span>)
    }
}

export default TreePage;
