import React from 'react';

const letterWidth = 7;
const lineHeight = 50;
const distanceBetweenLabelsInLetters = 3;

class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.myCanvas = React.createRef();
        this.drawSubTree = this.drawSubTree.bind(this);
        const treeString = this.props.treeString;
        let treeDepth = 0;
        let maxTreeDepth = 0;
        let leafCount = 0;
        let overallLabelCount = 0;
        let currentLabelCount = 0;
        let leaveLengthes = [];
        let openPointedBracketPos = 0;
        for (let i = 0; i < treeString.length; i++) {
            if (treeString[i] === '(') {
                treeDepth++;
                currentLabelCount = 0;
            } else if (treeString[i] === ')') {
                overallLabelCount += currentLabelCount + distanceBetweenLabelsInLetters;
                treeDepth--;
                currentLabelCount = 0;
            } else if (treeString[i] === '<') {
                leafCount++;
                currentLabelCount++;
                overallLabelCount++;
                openPointedBracketPos = i;
            } else if (treeString[i] === '>') {
                currentLabelCount++;
                overallLabelCount++;
                overallLabelCount += currentLabelCount + distanceBetweenLabelsInLetters;
                const leafPos = treeString.substring(openPointedBracketPos + 1, i) * 1;
                leaveLengthes[leafPos] = currentLabelCount;
                currentLabelCount = 0;
            } else {
                currentLabelCount++;
            }
            if (treeDepth > maxTreeDepth) {
                maxTreeDepth = treeDepth;
            }
        }
        const newX = overallLabelCount * letterWidth/2;
        this.state = {
            treeString: treeString,
            x: newX,
            y: (maxTreeDepth + 1) * lineHeight,
            leafCount: leafCount,
            leaveLengthes: leaveLengthes
        };
    }

    componentDidMount() {
        const treeString = this.state.treeString;
        this.drawSubTree(treeString.trim(), lineHeight, 0, this.state.x);
    }

    static getNodes(treeChildrenStrings) {
        let children = [];
        let newChild = '';
        let openBrackets = 0;
        for (let i = 0; i < treeChildrenStrings.length; i++) {
            const char = treeChildrenStrings[i];
            if (openBrackets === 0) {
                if (char === ' ') {
                    if (newChild !== '') {
                        children.push(newChild);
                        newChild = '';
                    }
                } else if (char === '(') {
                    newChild += char;
                    openBrackets++;
                } else {
                    newChild += char;
                }

            } else {
                if (char === ')') {
                    newChild += char;
                    openBrackets--;
                    if (openBrackets === 0) {
                        if (newChild !== '') {
                            children.push(newChild);
                            newChild = '';
                        }
                    }
                } else if (char === '(') {
                    newChild += char;
                    openBrackets++;
                } else {
                    newChild += char;
                }
            }
        }
        if (newChild !== '') {
            children.push(newChild);
        }
        return children;
    }

    drawSubTree(treeString, height, widthFrom, widthDelta, parentX, parentY) {
        const ctx = this.myCanvas.current.getContext('2d');
        ctx.font = '14px Lucida Console';
        let rootX = widthFrom + widthDelta / 2;
        let rootY = height;
        const oPos = treeString.indexOf("<");
        const cPos = treeString.indexOf(">");
        if (oPos >= 0 && oPos < cPos && treeString.indexOf("(", 1) === -1) {
            rootY = this.state.y - lineHeight;
            let previousLetterCount = 0;
            for (let i = treeString.substring(oPos + 1, cPos) - 1; i >= 0; i--) {
                if (!isNaN(this.state.leaveLengthes[i])) {
                    previousLetterCount += this.state.leaveLengthes[i];
                    previousLetterCount += distanceBetweenLabelsInLetters;
                }
            }
            rootX = previousLetterCount * letterWidth;
        }
        if (treeString.startsWith('(') && treeString.endsWith(')')) {
            const innerTreeString = treeString.substring(1, treeString.length
                - 1).trim();
            const spacePos = innerTreeString.indexOf(' ');
            const lBracketPos = innerTreeString.indexOf('(');
            let root;
            if (spacePos === -1 && lBracketPos === -1) {
                root = innerTreeString;
                if (oPos >= 0 && oPos < cPos && treeString.indexOf("(", 1) === -1) {
                    this.drawStrokeIfParentDefined(parentX, parentY, ctx,
                        rootX + root.length * letterWidth / 2, rootY);
                    ctx.fillText(innerTreeString, rootX , rootY);
                } else {
                    this.drawStrokeIfParentDefined(parentX, parentY, ctx,
                        rootX, rootY);
                    ctx.fillText(root, widthFrom + widthDelta / 2 - root.length * letterWidth / 2, rootY);
                }
                return;
            }
            let pos;
            if ((spacePos < lBracketPos && spacePos !== -1)
                || lBracketPos === -1) {
                pos = spacePos;
            } else {
                pos = lBracketPos;
            }
            root = innerTreeString.substring(0, pos);
            const children = Tree.getNodes(innerTreeString.substring(pos + 1));
            this.drawStrokeIfParentDefined(parentX, parentY, ctx, rootX, rootY);
            ctx.fillText(root, rootX - root.length * letterWidth/2, rootY);
            let overallChildrenWidth = 0;
            for (let child of children) {
                overallChildrenWidth += child.length;
                overallChildrenWidth += distanceBetweenLabelsInLetters;
            }
            let newWidthFrom = widthFrom;
            for (let child of children) {
                const childWidth
                    = (child.length + distanceBetweenLabelsInLetters)
                    / overallChildrenWidth * widthDelta;
                this.drawSubTree(child, rootY + lineHeight - 10, newWidthFrom, childWidth,
                    rootX, rootY);
                newWidthFrom += childWidth;
            }
        } else {
            if (oPos >= 0 && oPos < cPos && treeString.indexOf("(", 1) === -1) {
                this.drawStrokeIfParentDefined(parentX, parentY, ctx, rootX + treeString.length * letterWidth / 2, rootY);
                ctx.fillText(treeString, rootX , rootY);
            } else {
                this.drawStrokeIfParentDefined(parentX, parentY, ctx, rootX, rootY);
                ctx.fillText(treeString, widthFrom + widthDelta / 2 - treeString.length * letterWidth / 2, rootY);
            }
        }
    }

    drawStrokeIfParentDefined(parentX, parentY, ctx, rootX, rootY) {
        if (parentX !== undefined && parentY !== undefined) {
            ctx.moveTo(parentX, parentY + 5);
            ctx.lineTo(rootX, rootY - 12);
            ctx.stroke();
        }
    }

    render() {
        return (<span><canvas className="treeCanvas" width={this.state.x}
                              height={this.state.y} ref={this.myCanvas}/>
        </span>)
    }
}

export default Tree;

