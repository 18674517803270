import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgCykGeneralComplete() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Complete:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[A<sub>1</sub>, ρ<sub>1</sub>-vec]</span>,&nbsp;...,&nbsp;
                <span className={'unbreakable'}>[A<sub>m</sub>, ρ<sub>m</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A, ρ-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>A(ρ-vec) → A<sub>1</sub>(ρ<sub>1</sub>-vec)</span>,&nbsp;...,&nbsp;
            <span className={'unbreakable'}>A<sub>m</sub>(ρ<sub>m</sub>-vec)</span> a range instantiated rule
        </div>
    </div>)
}
