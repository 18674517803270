export const BACKEND_PROTOCOL = `${window.location.protocol}`;
export const BACKEND_HOST = `${window.location.hostname}`;
export const BACKEND_PATH = "cl-taskbox-backend";
export const BACKEND_PORT = "8080";

/** log messages */
export const USER_CLEAR = (user, dedRuleName) => {
    return user + ' request clear ' + dedRuleName;
};

export const USER_DRAG_START_ITEM = (user, itemForm) => {
    return user + ' drag start item ' + itemForm;
};

export const USER_DRAG_STOP_ITEM = (user, itemForm) => {
    return user + ' drag stop item ' + itemForm;
};

export const USER_DRAG_START_RULE = (user, prodRuleName) => {
    return user + ' drag start rule ' + prodRuleName;
};

export const USER_DRAG_STOP_RULE = (user, prodRuleName) => {
    return user + ' drag stop rule ' + prodRuleName;
};

export const USER_DRAG_ENTER_ANTECEDENCE = (user, antecedenceName) => {
    return user + ' drag enter antecedence ' + antecedenceName;
};

export const USER_DRAG_LEAVE_ANTECEDENCE = (user, antecedenceName) => {
    return user + ' drag leave antecedence ' + antecedenceName;
};

export const USER_DRAG_ENTER_SIDECONDITION = (user, dedRuleName) => {
    return user + ' drag enter side-condition ' + dedRuleName;
};

export const USER_DRAG_LEAVE_SIDECONDITION = (user, dedRuleName) => {
    return user + ' drag leave side-condition ' + dedRuleName;
};

export const USER_DROP_ITEM = (user, itemForm, antecedenceName) => {
    return user + ' drag drop item ' + itemForm + ' on ' + antecedenceName;
};

export const USER_DROP_RULE = (user, rule, dedRuleName) => {
    return user + ' drag drop rule ' + rule + ' on ' + dedRuleName;
};

export const USER_START_TASK = (user, key, value) => {
    return user + ' task ' + key + ' ' + value;
};

export const USER_GENERATED_ITEM_ADDED = (user, itemForm, deductionRuleName,
                                          backPointerList) => {
    return user + ' generate item add ' + itemForm + ' "' + deductionRuleName
        + '" ' + backPointerList;
};

export const USER_GENERATED_ITEM_DISMISSED = (user, itemForm, deductionRuleName,
                                              backPointerList) => {
    return user + ' generate item dismiss ' + itemForm + ' "' + deductionRuleName
        + '" ' + backPointerList;
};

export const USER_GENERATED_GOALITEM_ADDED = (user, itemForm) => {
    return user + ' generate goal-item add ' + itemForm;
};

export const USER_GENERATED_GOALITEM_DISMISSED = (user, itemForm) => {
    return user + ' generate goal-item dismiss ' + itemForm;
};

export const USER_REQUEST = (user, request) => {
    return user + ' request ' + request;
};

export const USER_DIFFICULTY = (user, difficulty) => {
    return user + ' difficulty-response ' + difficulty;
};
