import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyConvert() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Convert:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[B(ψ-vec) → Ψ-vec, pos, &lt;i, j>, ρ<sub>B</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[B, ρ]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>|ψ-vec(i)| = j</span>,&nbsp;
            <span className={'unbreakable'}>|ψ-vec| = i</span>,&nbsp;
            <span className={'unbreakable'}>ρ<sub>B</sub>(ψ-vec) = ρ</span>
        </div>
    </div>)
}
