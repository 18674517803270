import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgLeftCornerAxiom(props) {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Axiom:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'>[w, {props.startSymbol}, ε]
            </div>
        </div>
    </div>)
}
