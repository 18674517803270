import React from 'react';
import ProductionRule from './ProductionRule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faEdit} from '@fortawesome/free-solid-svg-icons';
import {pushWithBreaks} from "./display-grammar-util";

/**
 * Displays the grammar for the exercise. Might be editable or not.
 */
export class TagGrammar extends React.Component {
    constructor(props) {
        super(props);
        let beforeIA = '';
        let iaList1 = [];
        let betweenIA = '';
        let iaList2 = [];
        let afterIA = '';
        if (props.grammar !== '') {
            const ia = /([\s\S]*I\s*=\s*{)([^}]*)(}[\s\S]*A\s*=\s*{)([^}]*)(}[\s\S]*)/m;
            const ai = /([\s\S]*A\s*=\s*{)([^}]*)(}[\s\S]*I\s*=\s*{)([^}]*)(}[\s\S]*)/m;
            const match1 = ia.exec(props.grammar);
            const match2 = ai.exec(props.grammar);
            let match;
            if (match1 === null) {
                match = match2;
            } else {
                match = match1;
            }
            if (match != null) {
                beforeIA = match[1];
                const ia1 = match[2];
                if (ia1.length > 0) {
                    iaList1 = ia1.split(/,\s*/);
                } else {
                    iaList1 = [];
                }
                betweenIA = match[3];
                const ia2 = match[4];
                if (ia2.length > 0) {
                    iaList2 = ia1.split(/,\s*/);
                } else {
                    iaList2 = [];
                }
                afterIA = match[5];
            } else {
                beforeIA = "Something went wrong when parsing the production rules. Please check the syntax."
            }
        }
        this.state = {
            beforeIA: beforeIA,
            iaList1: iaList1,
            betweenIA: betweenIA,
            iaList2: iaList2,
            afterIA: afterIA
        }
    }

    static displayInteractableGrammar(beforeIA, iaList1, betweenIA, iaList2, afterIA, userSession) {
        let displayGrammar = [];
        let i = 1;
        pushWithBreaks(beforeIA, displayGrammar, 'beforeIA');
        iaList1.forEach(function (element) {
                displayGrammar.push(<ProductionRule rule={element} key={i}
                                                    userSession={userSession}/>);
                i++;
            }
        );
        pushWithBreaks(betweenIA, displayGrammar, 'betweenIA');
        i++;
        iaList2.forEach(function (element) {
                displayGrammar.push(<ProductionRule rule={element} key={i}
                                              userSession={userSession}/>);
                i++;
            }
        );
        pushWithBreaks(afterIA, displayGrammar, 'afterIA');
        return displayGrammar;
    }

    render() {
        const editable = this.props.editable;
        const beforeIA = this.state.beforeIA.replaceAll(" = ", "\u00A0=\u00A0");
        const iaList1 = this.state.iaList1;
        const betweenIA = this.state.betweenIA.replaceAll(" = ", "\u00A0=\u00A0");
        const iaList2 = this.state.iaList2;
        const afterIA = this.state.afterIA.replaceAll(" = ", "\u00A0=\u00A0");
        const userSession = this.props.userSession;
        return (
            <div className='panel' id='grammarPanel'>
                <button id='grammarEditIcon'
                    onClick={this.props.handleChangeGrammar}>
                    {editable
                        ? <FontAwesomeIcon className={'clickable-icon'} icon={faCheck}/>
                        : <FontAwesomeIcon className={'clickable-icon'} icon={faEdit}/>
                    }
                    {editable
                        ? <span className={'accessibility-label'}> edit grammar done</span>
                        : <span className={'accessibility-label'}>edit grammar</span>}</button>
                <div className='grammar'>
                    {editable
                        ? <span><label htmlFor="tGrammar"
                                       className={'accessibility-label'}>Grammar</label>
                            <textarea id='tGrammar' rows='4'
                                      defaultValue={this.props.grammar}/></span>
                        : TagGrammar.displayInteractableGrammar(beforeIA,
                            iaList1, betweenIA, iaList2, afterIA, userSession)
                    }
                </div>
                {iaList1.length === 0 && iaList2.length === 0 ?
                    <div className='no-task-message'>There is no task! Click New
                        in the menu to generate a task or click the edit symbols
                        for a custom task.</div> : ''}
            </div>
        );
    }
}
