import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgUngerScan() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Scan:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[•a, i, i + 1]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[a•, i, i + 1]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>w<sub>i + 1</sub> = a</span></div>
    </div>)
}
