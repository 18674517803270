import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyCompleteFoot() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>CompleteFoot:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[γ, dot, rb, i, j, k, l, 0]</span>,&nbsp;
                <span className={'unbreakable'}>[β, dot', lb, i, −, −, i, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[β, dot', rb, i, i, l, l, 0]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>dot' = foot(β),</span>&nbsp;
            <span className={'unbreakable'}>β ∈ f<sub>SA</sub>(γ,dot')</span></div>
    </div>)
}
