import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgUngerPredict() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Predict:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>[•A, i<sub>0</sub>, i<sub>k</sub>]
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[•A<sub>1</sub>, i<sub>0</sub>, i<sub>1</sub>],</span> ...,
                <span className={'unbreakable'}>[•A<sub>k</sub>, i<sub>k-1</sub>, i<sub>k</sub>]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>A -> A<sub>1</sub> ... A<sub>k</sub> ∈ P, </span>
            <span className={'unbreakable'}>i<sub>j</sub> &lt; i<sub>j+1</sub></span></div>
    </div>)
}
