import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgCykScan() {
    return (
        <div className='deduction-rule'>
            <div className='rule-name'>Scan:</div>
            <div className='middle-rule-part'>
                <hr/>
                <div
                    className='consequence'><span className={'unbreakable'}>[A, i, 1]</span>
                </div>
            </div>
            <div className='side-condition'><span className={'unbreakable'}>A -> w<sub>i</sub> ∈ P</span>
            </div>
        </div>)
}
