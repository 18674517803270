import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import SideConditionRule from '../../taskcomponents/SideConditionRule.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from '../../taskcomponents/Task'
import Consequence from "../../taskcomponents/Consequence";
import {
    USER_CLEAR
} from "../../constants";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgShiftReduceReduce extends React.Component {
    initial_values = {
        antecedence: '',
        i: '',
        displayI: 'i',
        alpha: '',
        displayAlpha: 'α',
        lhs: '',
        displayLhs: 'A',
        gamma: '',
        displayGamma: 'Γ',
        prodRule: '',
        canDropItem: 0,
        canDropRule: 0,
        displayItem: '',
        displayRule: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.extractAdditionalRuleValues = this.extractAdditionalRuleValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence(item) {
        this.setState({
            antecedence: item,
            eraseDisabled: false
        });
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    extractAdditionalItemValues(item) {
        let stackHeadEnd;
        if (this.state.prodRule === '') {
            stackHeadEnd = item.itemForm.lastIndexOf(',');
        } else {
            stackHeadEnd = item.itemForm.indexOf(this.state.alpha) - 1;
        }
        if (stackHeadEnd === -1 || stackHeadEnd === 0) {
            stackHeadEnd = 1;
        }
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        const gamma = item.itemForm.substring(1, stackHeadEnd);
        this.setState({
            gamma: gamma,
            i: i,
            canDropItem: 0,
            canDropRule: 0,
            displayItem: ''
        });
    }

    extractAdditionalDisplayItemValues(item) {
        let stackHeadEnd;
        if (this.state.prodRule === '') {
            stackHeadEnd = item.itemForm.lastIndexOf(',');
        } else {
            stackHeadEnd = item.itemForm.indexOf(this.state.alpha) - 1;
        }
        if (stackHeadEnd === -1 || stackHeadEnd === 0) {
            stackHeadEnd = 1;
        }
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        const gamma = item.itemForm.substring(1, stackHeadEnd);
        this.setState({
            displayGamma: gamma,
            displayI: i,
            canDropItem: 0,
            canDropRule: 0,
            displayItem: ''
        });
    }

    /**
     * Set rule as production rule in state and extract different other values
     * from it to be set in state.
     */
    setProdRule(rule) {
        this.setState({prodRule: rule, eraseDisabled: false});
        this.extractAdditionalRuleValues(rule);
        this.checkNewItem();
    }

    extractAdditionalRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const alpha = ruleSplit[1].trim();
        let newGamma;
        if (this.state.gamma.length === alpha.length) {
            newGamma = '';
        } else if (this.state.gamma.length === 0) {
            newGamma = '';
        } else {
            newGamma = this.state.gamma.substring(0, this.state.gamma.length - alpha.length - 1);
        }
        this.setState({
            lhs: lhs,
            alpha: alpha,
            gamma: newGamma
        });
    }

    extractAdditionalDisplayRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const alpha = ruleSplit[1].trim();
        let newGamma;
        if (this.state.gamma.length === alpha.length) {
            newGamma = '';
        } else if (this.state.gamma.length === 0) {
            newGamma = 'Γ';
        } else {
            newGamma = this.state.gamma.substring(0, this.state.gamma.length - alpha.length - 1);
        }
        this.setState({
            displayLhs: lhs,
            displayAlpha: alpha,
            displayGamma: newGamma
        });
    }

    setDisplayRule(rule) {
        this.setState({displayRule: rule});
        if (rule === '') {
            if (this.state.prodRule === '') {
                if (this.state.antecedence === '') {
                    this.setState({
                        displayLhs: 'A',
                        displayAlpha: 'α',
                        displayGamma: 'Γ'
                    });
                } else {
                    this.setState({displayAlpha: 'α'});
                    this.extractAdditionalDisplayItemValues(this.state.antecedence);
                }
            } else {
                this.extractAdditionalDisplayRuleValues(this.state.prodRule);
            }
        } else {
            this.extractAdditionalDisplayRuleValues(rule);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const stackHeadEnd = item.itemForm.lastIndexOf(',');
        const fullStack = item.itemForm.substring(1, stackHeadEnd);
        return this.state.prodRule === '' || fullStack.endsWith(this.state.alpha);
    }

    /**
     * Returns true if rule can be dropped on the side condition component.
     */
    canDropRule(rule) {
        if (this.state.antecedence === '') {
            return true;
        }
        const ruleSplit = rule.rule.split('->');
        const alpha = ruleSplit[1].trim();
        return this.state.gamma.endsWith(alpha);
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                if (this.state.prodRule === '') {
                    this.setState({
                        displayI: 'i',
                        displayAlpha: 'α',
                        displayLhs: 'A',
                        displayGamma: 'Γ'
                    });
                } else {
                    this.setState({displayAlpha: 'α', displayGamma: 'Γ'});
                    this.extractAdditionalDisplayRuleValues(this.state.prodRule);
                }
            } else {
                this.extractAdditionalDisplayItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropRule(value) {
        this.setState({
            canDropRule: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence !== '' && this.state.prodRule !== '') {
            let newGamma = this.state.gamma
            let newItem = '[';
            if (newGamma !== '') {
                newItem = newItem + newGamma + ' ';
            }
            newItem = newItem + this.state.lhs + ',' + this.state.i + ']';
            if (this.props.sendNewItemToTask(newItem, 'reduce '
                + this.state.prodRule.rule, [this.state.antecedence.id])) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession,
            'cfg-shiftreduce-reduce'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const alpha = this.state.displayAlpha;
        const i = this.state.displayI;
        const lhs = this.state.displayLhs;
        const gamma = this.state.displayGamma;
        const canDropItem = this.state.canDropItem;
        let canDropRhs = this.state.canDropRule;
        if (canDropItem === 1 && this.state.prodRule !== '') {
            canDropRhs = 1;
        }
        if (canDropItem === -1 && this.state.prodRule !== '') {
            canDropRhs = -1;
        }
        const ruleHovering = this.state.displayRule === '' ? 0 : 1;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                   onClick={this.clearWithLog.bind(this)}>
                    <FontAwesomeIcon className={"clickable-icon"}
                                     icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear shift-reduce reduce</span>
                </button>Reduce:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-shiftreduce-reduce'
                                 elementLists={[[gamma, alpha], [i]]}
                                 markings={[[canDropItem, canDropRhs], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[gamma, lhs], [i]]}
                        markings={[[canDropItem, ruleHovering], [itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <SideConditionRule
                    displayLhs={lhs} displayRhs={alpha}
                    setProdRule={this.setProdRule.bind(this)}
                    setDisplayRule={this.setDisplayRule.bind(this)}
                    canDropRule={this.canDropRule.bind(this)}
                    setCanDropRule={this.setCanDropRule.bind(this)}
                    canDropRhs={canDropRhs}
                    canDropLhs={ruleHovering}
                    userSession={this.props.userSession}
                    ruleName='cfg-shiftreduce-reduce'/> <span className={'unbreakable'}>∈ P</span>
            </div>
        </div>
    }
}

export default CfgShiftReduceReduce;
