import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

export class ColorButton extends React.Component {
    handleClick = () => {
        this.props.setColor(this.props.color);
    };

    render() {
        return (
            <button onClick={this.handleClick}>
                <FontAwesomeIcon className={'clickable-icon'}
                                 icon={faCircle}
                                 style={{color: this.props.color}}/>
            </button>
        );
    }
}
