import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgTopDownGoal extends React.Component {
    initial_values = {
        item: '',
        stack: 'ε',
        n: this.props.inputLength.toString(),
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.item === '') {
                this.setState({
                    stack: 'ε',
                    n: this.props.inputLength.toString()
                });
            } else {
                this.extractAdditionalItemValues(this.state.item);
            }
        } else {
            this.extractAdditionalItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const stack = item.itemForm.substring(1, item.itemForm.lastIndexOf(','));
        const n = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        return stack === 'ε' && n === this.props.inputLength.toString();
    }

    extractAdditionalItemValues(item) {
        const stack = item.itemForm.substring(1, item.itemForm.lastIndexOf(','));
        const n = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        this.setState({stack: stack, n: n});
    }

    setAntecedence(item) {
        this.setState({item: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.item !== '') {
            const goal = '[' + this.state.stack + ',' + this.state.n + ']';
            this.props.sendNewGoalToTask(goal);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const stack = this.state.stack;
        const n = this.state.n;
        let canDropStack = 0;
        let canDropN = 0;
        if (this.state.displayItem !== '') {
            canDropStack = stack === 'ε' ? 1 : -1;
            canDropN = n === this.props.inputLength.toString() ? 1 : -1;
        }
        return <div className='deduction-rule'>
            <div className='rule-name'>Goal:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-topdown-goal'
                                 elementLists={[[stack], [n]]}
                                 markings={[[canDropStack], [canDropN]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
            </div>
        </div>
    }
}

export default CfgTopDownGoal;
