import React from "react";
import Tree from "./taskcomponents/Tree";

/**
 * A page that renders different trees I use to optimize the tree drawing
 * algorithm
 */
class TreeTestPage extends React.Component {
    render() {
        const treelist = ["(S a b)",
            "(S (Comp (dat<0> ))(VP (NP (Jan<1> ))(VP (NP (Piet<2> ))(VP (NP (de-kinderen<3> ))(V (zwemmen<6> )))(V (helpen<5> )))(V (zag<4> ))))",
            "(S (A (A (a<0> )(a<4> )(a<8> ))(C (a<1> )(c<5> )(c<9> )))(B (B (b<2> )(b<6> ))(B (b<3> )(b<7> ))))",
            "(S (A (D (a ))(E (b ))(F (c )))(B (G (d ))(H (e ))(I (f )))(C (J (g ))(K (h ))(L (i ))))",
            "(S (NP (PN (maria )))(VP (V (sees ))(NP (NP (NP (Det (the ))(N (man )))(PP (P (in ))(NP (Det (the ))(N (park )))))(PP (P (with ))(NP (Det (the ))(N (telescope )))))))",
            "(N0 ε)"];
        let displayTrees = [];
        treelist.forEach(function (treeString) {
                displayTrees.push(<Tree treeString={treeString}/>);
            displayTrees.push(<br/>);
            }
        );
        return (displayTrees)
    }
}

export default TreeTestPage;
