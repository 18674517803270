import React from 'react';
import {DropTarget} from 'react-dnd';
import {requestLogRestService} from './Task';
import PropTypes from 'prop-types';
import {
    USER_DRAG_ENTER_ANTECEDENCE,
    USER_DRAG_LEAVE_ANTECEDENCE,
    USER_DROP_ITEM
} from "../constants";

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    };
}

const spec = {
    canDrop(props, monitor) {
        return props.canDropItem(monitor.getItem());
    },
    hover(props, monitor) {
        const canDrop = monitor.canDrop();
        const item = monitor.getItem();
        props.setDisplayItem(item);
        if (canDrop) {
            props.setCanDropItem(1);
        } else {
            props.setCanDropItem(-1);
        }
    },

    drop(props, monitor) {
        requestLogRestService(USER_DROP_ITEM(props.userSession, monitor.getItem().itemForm, props.antecedenceName));
        if (monitor.didDrop()) {
            return;
        }
        const item = monitor.getItem();
        props.setCanDropItem(0);
        props.setAntecedence(item);
    }
};

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class Antecedence extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (!this.props.isOver && nextProps.isOver) {
            requestLogRestService(USER_DRAG_ENTER_ANTECEDENCE(this.props.userSession, nextProps.antecedenceName));
        }
        if (this.props.isOver && !nextProps.isOver) {
            requestLogRestService(USER_DRAG_LEAVE_ANTECEDENCE(this.props.userSession, nextProps.antecedenceName));
            this.props.setCanDropItem(0);
            this.props.setDisplayItem('');
        }
    }

    render() {
        const {connectDropTarget} = this.props;
        const elementLists = this.props.elementLists;
        const maxItem = this.props.maxItem;
        const markings = this.props.markings;
        let displayElementLists = [];
        for (let i = 0; i < elementLists.length; i++) {
            if (i > 0) {
                displayElementLists.push(', ');
            }
            for (let j = 0; j < elementLists[i].length; j++) {
                let canDropObject = markings[i][j];
                displayElementLists.push(
                    <span key={i + '-' + j}
                          className={canDropObject === 1 ? 'can-drop'
                              : (canDropObject === -1 ? 'cannot-drop' : '')}>
                    {(j > 0 ? ' ' : '') + elementLists[i][j]}
                </span>);
            }
        }

        return connectDropTarget(
            <span>
                <span
                    className={"droppable unbreakable"}>
            [{displayElementLists}]
            </span><br/>
                <span className={"droptarget-placeholder"}>{maxItem}</span>
            </span>);
    }
}

Antecedence.propTypes = {
    antecedenceName: PropTypes.string.isRequired,
    elementLists: PropTypes.array.isRequired,
    markings: PropTypes.array.isRequired,
    canDropItem: PropTypes.func.isRequired,
    setAntecedence: PropTypes.func.isRequired,
    setCanDropItem: PropTypes.func.isRequired,
    setDisplayItem: PropTypes.func.isRequired,
    userSession: PropTypes.string.isRequired,
    maxItem: PropTypes.string.isRequired
};

export default DropTarget('item', spec, collect)(Antecedence);
