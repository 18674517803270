import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class Consequence extends React.Component {
    render() {
        const elementLists = this.props.elementLists;
        const maxItem = this.props.maxItem;
        const markings = this.props.markings;
        let displayElementLists = [];
        for (let i = 0; i < elementLists.length; i++) {
            if (i > 0) {
                displayElementLists.push(', ');
            }
            for (let j = 0; j < elementLists[i].length; j++) {
                let canDropObject = markings[i][j];
                displayElementLists.push(<span key={i + '-' + j}
                                               className={canDropObject === 1 ? 'can-drop'
                                                   : (canDropObject === -1 ? 'cannot-drop' : '')}>
                    {(j > 0 ? ' ' : '') + elementLists[i][j]}
                </span>);
            }
        }

        return (
            <span>
            <span className={'unbreakable'}>
            [{displayElementLists}]
            </span><br/>
        <span className={"droptarget-placeholder"}>{maxItem}</span>
    </span>);
    }
}

Consequence.propTypes = {
    elementLists: PropTypes.array.isRequired,
    markings: PropTypes.array.isRequired,
    maxItem: PropTypes.string.isRequired
};

export default Consequence;
