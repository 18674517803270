import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgEarleyAxiom(props) {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Axiom:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[{props.startSymbol} -> •α, 0, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>{props.startSymbol} -> α ∈ P</span></div>
    </div>)
}
