import React, {Component} from 'react';
import TouchBackend from 'react-dnd-touch-backend';
import {DragDropContext} from 'react-dnd'
import {DeductionTable} from './DeductionTable';
import DeductionMenu from './DeductionMenu';
import {CfgGrammar} from './CfgGrammar';
import {Input} from './Input';
import {DeductionRules} from './DeductionRules';
import {
    BACKEND_HOST,
    BACKEND_PATH,
    BACKEND_PORT,
    BACKEND_PROTOCOL,
    USER_DIFFICULTY,
    USER_GENERATED_GOALITEM_ADDED,
    USER_GENERATED_GOALITEM_DISMISSED,
    USER_GENERATED_ITEM_ADDED,
    USER_GENERATED_ITEM_DISMISSED,
    USER_REQUEST,
    USER_START_TASK
} from "../constants";
import Breadcrumb from "../Breadcrumb";
import Tree from "./Tree";
import DragLayer from "./ItemPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEraser, faTimes} from "@fortawesome/free-solid-svg-icons";
import {TagGrammar} from "./TagGrammar";
import {SrcgGrammar} from "./SrcgGrammar";
import {instanceOf} from "prop-types";
import {Cookies, CookiesProvider, withCookies} from "react-cookie";
import DrawArea, {ColoredLine} from "./DrawArea";
import {DrawButton} from "./DrawButton";
import {ColorButton} from "./ColorButton";
import * as Immutable from "immutable";
import {makeId} from "../Main";

export function requestLogRestService(message) {
    const currentTime = new Date();
    const currentTimeFormatted = currentTime.toISOString();
    requestRestService(BACKEND_PROTOCOL + '//'
        + BACKEND_HOST + ':'
        + BACKEND_PORT + '/'
        + BACKEND_PATH + '/rest/log?message=' +
        encodeURIComponent(currentTimeFormatted + '\t' + message.trim()))
        .then(() => {
    }).catch(() => {
        console.log('An error occured when requesting the user log service.')
    });
}

function requestRestService(url) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            resolve(this.responseText);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.send();
    });
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

/**
 * Displays the area where error messages returned from the REST services are
 * displayed.
 */
function ErrorMessage(props) {
    return (
        <div className='error-message'>
            {props.message}
        </div>
    );
}

/**
 * Displays the area where user messages like the evaluation are displayed.
 */
class UserMessage extends Component<{}> {
    constructor(props) {
        super(props);
        this.clearUserMessage = this.clearUserMessage.bind(this);
    }

    clearUserMessage() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'close-message'));
        this.props.setUserMessage('');
    }

    render() {
        return (
            <div className='user-message'>
                {this.props.message !== '' ?
                    <button className={'clickable-icon closeable-icon'}
                            onClick={this.clearUserMessage.bind(this)}>
                        <FontAwesomeIcon icon={faTimes}/></button> : ''}
                {this.props.message}
            </div>
        );
    }
}

/**
 * Displays a throbber to indicate that the site is busy.
 */
function Loader(props) {
    return (
        <img alt='loadIndicator' src='./Spinner-1s-200px.gif'
             style={{display: props.visible ? 'block' : 'none'}}
             width={'30px'}/>
    );
}

/**
 * Displays a question for the user about the task difficulty.
 */
function Survey(props) {
    return (
        <div style={{display: props.visible ? 'block' : 'none'}} className={'panel'}>
            <button className={'clickable-icon closeable-icon'}
                    onClick={props.closeSurvey.bind(this, false)}>
                <FontAwesomeIcon icon={faTimes}/></button>
            How hard is this task?<br/>
            <div style={{display: 'flex', margin: '5px'}}>
                <div>
                    <input type="radio" name="radio-difficulty" value="dif-1"
                           onClick={props.sendSurveyResponse.bind(this, 1)}/><br/>
                    <label htmlFor="dif-1">Easy</label>
                </div>
                <div>
                    <input type="radio" name="radio-difficulty" value="dif-2"
                           onClick={props.sendSurveyResponse.bind(this, 2)}/>
                </div>
                <div>
                    <input type="radio" name="radio-difficulty" value="dif-3"
                           onClick={props.sendSurveyResponse.bind(this, 3)}/>
                </div>
                <div>
                    <input type="radio" name="radio-difficulty" value="dif-4"
                           onClick={props.sendSurveyResponse.bind(this, 4)}/>
                </div>
                <div>
                    <input type="radio" name="radio-difficulty" value="dif-5"
                           onClick={props.sendSurveyResponse.bind(this, 5)}/><br/>
                    <label htmlFor="dif-5">Hard</label>
                </div>
            </div>
        </div>
    );
}

function rulesToString(rules) {
    let rulesString = "";
    let first = true;
    for (const rule of rules) {
        if (first) {
            first = !first;
        } else {
            rulesString += ", "
        }
        rulesString += rule;
    }
    return rulesString;
}

function jsonToTrace(deductionResult) {
    let trace = [];
    for (const line of deductionResult.chart.lines) {
        trace.push([line.id, Task.itemToString(line.item.itemForm),
            rulesToString(line.rules),
            Task.backPointersToString(line.backPointers),
            line.item.displayProbability != null ? line.item.displayProbability : ""])
    }
    return trace;
}

export function replaceEpsilon(grammarString) {
    const t = /([\s\S]*T\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const matcht = t.exec(grammarString);
    if (matcht == null) {
        return grammarString;
    }
    const ts = matcht[2].split(/\s*,\s*/);
    for (let terminal of ts) {
        if (terminal.trim() === 'epsilon') {
            return grammarString;
        }
    }

    let beforeProd = '';
    let prodList = [];
    let afterProd = '';
    const p = /([\s\S]*P\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const match = p.exec(grammarString);
    if (match != null) {
        beforeProd = match[1];
        const prod = match[2];
        if (prod.length > 0) {
            prodList = prod.split(/\s*,\s*/);
        } else {
            prodList = [];
        }
        afterProd = match[3];
    }
    let newRules = [];
    for (let rule of prodList) {
        if (!rule.includes('->')) {
            newRules.push(rule);
            continue;
        }
        const lrSplit = rule.split('->');
        const lhs = lrSplit[0].trim();
        const rhs = lrSplit[1].trim();
        if (rhs === 'epsilon') {
            newRules.push(lhs + ' -> ε');
        } else {
            newRules.push(lhs + ' -> ' + rhs.trim());
        }
    }
    return beforeProd + newRules.join(', ') + afterProd;
}

export function normalizeGrammar(grammarString) {
    let alteredGrammarString = replacePipesInGrammar(grammarString);
    alteredGrammarString = replaceEpsilon(alteredGrammarString);
    alteredGrammarString = tokenizeRhs(alteredGrammarString);
    alteredGrammarString = deduceTokens(alteredGrammarString);
    return alteredGrammarString;
}

/**
 * If T is not given or all T have length 1, tokenize input by inserting spaces.
 */
export function tokenizeInput(inputString, grammarString) {
    const t = /([\s\S]*T\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const matcht = t.exec(grammarString);
    if (matcht != null) {
        const ts = matcht[2].split(/\s*,\s*/);
        for (let terminal of ts) {
            if(terminal.trim().length > 1) {
                return inputString;
            }
        }
    }
    return inputString.split(/\s+|(?!$)/u).join(" ");
}

export function replacePipesInGrammar(grammarString) {
    let beforeProd = '';
    let prodList = [];
    let afterProd = '';
    const p = /([\s\S]*P\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const match = p.exec(grammarString);
    if (match != null) {
        beforeProd = match[1];
        const prod = match[2];
        if (prod.length > 0) {
            prodList = prod.split(/\s*,\s*/);
        } else {
            prodList = [];
        }
        afterProd = match[3];
    } else {
        prodList = grammarString.split(/\s*,\s*/);
    }
    let newRules = [];
    for (let rule of prodList) {
        if (!rule.includes('|')) {
            newRules.push(rule);
        } else {
            const lrSplit = rule.split('->');
            const lhs = lrSplit[0].trim();
            const rSplit = lrSplit[1].split('|');
            for (let rhs of rSplit) {
                newRules.push(lhs + ' -> ' + rhs.trim());
            }
        }
    }
    return beforeProd + newRules.join(', ') + afterProd;
}

/**
 * If all nonterminals and terminals are of length 1 or are not declared,
 * tokenise all rhs by inserting spaces where no one exists.
 */
export function tokenizeRhs(grammarString) {
    let beforeProd = '';
    let prodList = [];
    let afterProd = '';
    const n = /([\s\S]*N\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const t = /([\s\S]*T\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const matchn = n.exec(grammarString);
    if (matchn != null) {
        const nts = matchn[2];
        if (nts.length > 0) {
            const ntList = nts.split(/\s*,\s*/);
            for (const nt of ntList) {
                if (nt.length > 1) {
                    return grammarString;
                }
            }
        }
    }
    const matcht = t.exec(grammarString);
    if (matcht != null) {
        const ts = matcht[2];
        if (ts.length > 0) {
            const tList = ts.split(/\s*,\s*/);
            for (const t of tList) {
                if (t.length > 1) {
                    return grammarString;
                }
            }
        }
    }
    const p = /([\s\S]*P\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const match = p.exec(grammarString);
    if (match != null) {
        beforeProd = match[1];
        const prod = match[2];
        if (prod.length > 0) {
            prodList = prod.split(/\s*,\s*/);
        } else {
            prodList = [];
        }
        afterProd = match[3];
    } else {
        if (!grammarString.includes("->")) {
            return grammarString;
        }
        prodList = grammarString.split(/\s*,\s*/);
    }
    let newRules = [];
    for (let rule of prodList) {
        if (!rule.includes('->')) {
            newRules.push(rule);
            continue;
        }
        const lrSplit = rule.split('->');
        const lhs = lrSplit[0].trim();
        const rSplit = lrSplit[1].trim().split(/\s+|(?!$)/u);
        newRules.push(lhs + ' -> ' + rSplit.join(" "));
    }
    return beforeProd + newRules.join(', ') + afterProd;
}

/**
 * If only production rules are given, fill out the lists of nonterminals and
 * terminals yourself and assume S as start symbol.
 * Expects the rhs to be tokenized.
 */
export function deduceTokens(grammarString) {
    let newGrammarString = '';
    let prodList = [];
    const p = /([\s\S]*P\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const match = p.exec(grammarString);
    if (match != null) {
        const prod = match[2];
        if (prod.length > 0) {
            prodList = prod.split(/\s*,\s*/);
        } else {
            prodList = [];
        }
    } else {
        if (!grammarString.includes("->")) {
            return grammarString;
        }
        prodList = grammarString.split(/\s*,\s*/);
    }
    const s = /([\s\S]*S\s*=\s*)(\S+)(\s*)/m;
    const matchs = s.exec(grammarString);
    if (matchs != null) {
        newGrammarString += "S = " + matchs[2] + "\n";
    } else {
        newGrammarString += "S = S\n";
    }
    const n = /([\s\S]*N\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const matchn = n.exec(grammarString);
    if (matchn != null) {
        newGrammarString += "N = {" + matchn[2] + "}\n";
    } else {
        let nts = []
        let sym = []
        for (let pRule of prodList) {
            const pRuleSplit = pRule.split("->")
            const lhs = pRuleSplit[0].trim();
            if (!nts.includes(lhs)) {
                nts.push(lhs);
            }
            const rhsSplit = pRuleSplit[1].trim().split(" ");
            for (let rhsSym of rhsSplit) {
                if (!sym.includes(rhsSym)) {
                    sym.push(rhsSym);
                }
            }
        }
        newGrammarString += "N = {" + nts.join(", ") + "}\n";
    }
    const t = /([\s\S]*T\s*=\s*{)([^}]*)(}[\s\S]*)/m;
    const matcht = t.exec(grammarString);
    if (matcht != null) {
        newGrammarString += "T = {" + matcht[2] + "}\n";
    } else {
        let nts = []
        let sym = []
        for (let pRule of prodList) {
            const pRuleSplit = pRule.split("->")
            const lhs = pRuleSplit[0].trim();
            if (!nts.includes(lhs)) {
                nts.push(lhs);
            }
            const rhsSplit = pRuleSplit[1].trim().split(" ");
            for (let rhsSym of rhsSplit) {
                if (!sym.includes(rhsSym)) {
                    sym.push(rhsSym);
                }
            }
        }
        for (let nt of nts) {
            if (sym.includes(nt)) {
                sym.splice(sym.indexOf(nt), 1);
            }
        }
        if (sym.includes("ε")) {
            sym.splice(sym.indexOf("ε"), 1);
        }
        newGrammarString += "T = {" + sym.join(", ") + "}\n";
    }
    newGrammarString += "P = {" + prodList.join(", ") + "}";
    return newGrammarString
}

export function getUrlParamDecoded(props, key) {
    const params = new URLSearchParams(props.location.search);
    const value = params.get(key);
    if (value == null) {
        return '';
    } else {
        return value;
    }
}

/**
 * Main entry for the page that lets the user exercise parsing as deduction.
 */
class Task extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.isTerminal = this.isTerminal.bind(this);
        this.isNonterminal = this.isNonterminal.bind(this);
        this.isInputSymbol = this.isInputSymbol.bind(this);
        this.hasInputSymbol = this.hasInputSymbol.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.showGoalTrees = this.showGoalTrees.bind(this);
        this.setUserMessage = this.setUserMessage.bind(this);
        this.clearGoalTrees = this.clearGoalTrees.bind(this);
        this.clearTable = this.clearTable.bind(this);
        this.addAxiomsToTable = this.addAxiomsToTable.bind(this);
        this.solveBackPointers = this.solveBackPointers.bind(this);
        this.solveLines = this.solveLines.bind(this);
        this.solveMessage = this.solveMessage.bind(this);
        this.solveGoalItems = this.solveGoalItems.bind(this);
        this.sendSurveyResponse = this.sendSurveyResponse.bind(this);
        this.closeSurvey = this.closeSurvey.bind(this);
        this.switchDrawing = this.switchDrawing.bind(this);
        this.switchErasing = this.switchErasing.bind(this);
        this.setColor = this.setColor.bind(this);
        this.addLine = this.addLine.bind(this);
        this.addPointToLastLine = this.addPointToLastLine.bind(this);
        this.eraseAtPoint = this.eraseAtPoint.bind(this);
        const params = new URLSearchParams(props.location.search);
        let grammarValue = getUrlParamDecoded(props, 'grammar');
        let algorithmValue = params.get('algorithm');
        let formalismValue = params.get('formalism');
        let inputValue = getUrlParamDecoded(props, 'input');
        let deductionResult = {};
        let traceValue = [];
        let tableContentValue = [];
        let startSymbol = Task.getStartSymbol(grammarValue);
        let errorMessage = '';
        if (grammarValue === '') {
            errorMessage += 'The grammar parameter is null. ';
        }
        if (algorithmValue == null) {
            errorMessage += 'The algorithm parameter is null. ';
        }
        if (formalismValue == null) {
            errorMessage += 'The formalism parameter is null. ';
        }
        this.state = {
            grammar: grammarValue,
            input: inputValue,
            algorithm: algorithmValue,
            formalism: formalismValue,
            deductionResult: deductionResult,
            trace: traceValue,
            tableContent: tableContentValue,
            highlightedRows: {},
            displayGoals: [],
            displayTable: null,
            errorMessage: errorMessage,
            userMessage: '',
            startSymbol: startSymbol,
            grammarEditable: false,
            InputEditable: false,
            loading: false,
            survey: false,
            generated: 0,
            displayTrees: [],
            discoveredItemIds: {},
            idMapOldToNew: new Map(),
            drawingActive: false,
            erasingActive: false,
            currentColor: 'black',
            lines: new Immutable.List(),
            maxItem: '',
            session: 'null'
        };
    }

    addLine(point) {
        const coloredLine = new ColoredLine(this.state.currentColor, new Immutable.List([point]));
        this.setState(prevState => ({
            lines: prevState.lines.push(coloredLine),
            isDrawing: true
        }));
    }

    addPointToLastLine(point) {
        let prevLines = this.state.lines;
        if (prevLines == null || prevLines.size < 1) {
            return;
        }
        let newLine = prevLines.get(prevLines.size -1);
        newLine.line = newLine.line.push(point);
        prevLines.set(prevLines.size -1, newLine);
        this.setState({
            lines: prevLines});

    }

    static getStartSymbol(grammarValue) {
        if (grammarValue !== '') {
            const p = /S\s*=\s*([^\s]+)\s*/m;
            const match = p.exec(grammarValue);
            return match[1];
        }
        return 'S';
    }

    /**
     * Is called by React after initialization of the component and before the
     * page is displayed in the browser. Requests the REST API for the parsing
     * trace of the current parameters.
     */
    componentDidMount() {
        document.title = "CL-Taskbox - Task";
        let inputValue = this.state.input;
        let grammarValue = this.state.grammar;
        let formalismValue = this.state.formalism;
        let algorithmValue = this.state.algorithm;
        const session = makeId();
        let deductionResult = {};
        let tableContentValue = [];
        if (grammarValue !== '' && formalismValue !== '' &&
            algorithmValue !== '' && !grammarValue.includes("N = {}")
            && !grammarValue.includes("P = {}")
            && !grammarValue.includes("I = {}")) {
            this.setLoading(true);
            requestLogRestService(USER_START_TASK(this.props.user + '-' + session , "input", inputValue));
            requestLogRestService(USER_START_TASK(this.props.user + '-' + session, "grammar", grammarValue));
            requestLogRestService(USER_START_TASK(this.props.user + '-' + session, "formalism", formalismValue));
            requestLogRestService(USER_START_TASK(this.props.user + '-' + session, "algorithm", algorithmValue));
            this.requestDeductionRestService(inputValue,
                grammarValue, formalismValue, algorithmValue).then((result) => {
                this.setLoading(false);
                deductionResult = JSON.parse(result);
                if (deductionResult.errorMessage !== undefined) {
                    this.setState({
                        errorMessage: deductionResult.errorMessage
                    });
                } else {
                    let trace = jsonToTrace(deductionResult);
                    tableContentValue = this.addAxiomsToTable(trace);
                    let maxItem = this.getMaxitem(trace);
                    if (algorithmValue === "cfg-topdown") {
                        maxItem += " α"
                    }
                    if (algorithmValue === "cfg-shiftreduce") {
                        maxItem += " Γ"
                    }
                    if (algorithmValue === "cfg-leftcorner") {
                        maxItem += " $ α X2 … Xk"
                    }
                    this.setState({
                        deductionResult: deductionResult,
                        trace: trace,
                        tableContent: tableContentValue,
                        maxItem: maxItem,
                        session: session
                    });
                }
            }).catch(() => {
                this.setLoading(false);
                this.setState({
                    errorMessage: 'An error occured when requesting the deduction service.\n\n'
                      + 'Try again by refreshing the page.\nIf the problem persists, '
                      + 'check your grammar format.\n'
                      + 'If the problem still persists, check /status and send mail to '
                      + 'Samya if the backend is offline.'
                })
            });
        }
    }

    getMaxitem(trace) {
        let maxItem = "";
        for (let i = 0; i < trace.length; i++) {
            const item = trace[i][1];
            if (item.length > maxItem.length) {
                maxItem = item;
            }
        }
        return maxItem;
    }

    /**
     * Get all Goal trees from the chart and set them in the state so the are
     * rendered.
     */
    showGoalTrees() {
        if (this.state.deductionResult.chart == null){
            this.setErrorMessage("There is no task to show goal trees for.", true);
            return;
        }
        let trees = [];
        for (const line of this.state.deductionResult.chart.lines) {
            if (line.item.goal === true) {
                for (const tree of line.item.trees) {
                    trees.push(tree);
                }
            }
        }
        if (trees.length === 0) {
            this.setState({userMessage: "There are no goal trees."})
        } else {
            this.setState({displayTrees: trees})
        }
    }

    /**
     * Shows the CYK table with items ordered by their two indices.
     */
    showTable() {
        if (this.state.deductionResult.chart == null){
            this.setErrorMessage("There is no task to show the table for.", true);
            return;
        }
        const trace = this.state.trace;
        let rawTable = [];
        let biggestFirstIndex = 0;
        let biggestSecondIndex = 0;
        for (let i = 0; i < trace.length; i++) {
            const item = trace[i][1];
            const firstComma = item.indexOf(",");
            const secondComma = item.indexOf(",", firstComma + 1);
            const nt = item.substring(1, firstComma);
            const p = trace[i][4];
            const firstIndex = item.substring(firstComma + 1, secondComma);
            if (firstIndex > biggestFirstIndex) {
                biggestFirstIndex = firstIndex;
            }
            const secondIndex = item.substring(secondComma + 1, item.length - 1);
            if (secondIndex > biggestSecondIndex) {
                biggestSecondIndex = secondIndex;
            }
            if (rawTable[firstIndex] == null) {
                rawTable[firstIndex] = [];
            }
            if (rawTable[firstIndex][secondIndex] == null) {
                rawTable[firstIndex][secondIndex] = [];
            }
            if (p === '') {
                rawTable[firstIndex][secondIndex].push(nt);
            } else {
                const formattedP = parseFloat(p).toFixed(2);
                rawTable[firstIndex][secondIndex].push(formattedP + ' : ' + nt);
            }
        }
        const table = this.generateTable(biggestFirstIndex, biggestSecondIndex, rawTable);
        this.setState({displayTable: table});
    }

    generateTable(biggestFirstIndex, biggestSecondIndex, rawTable) {
        let firstIndexLoop = [];
        for (let i = 0; i <= biggestFirstIndex; i++) {
            firstIndexLoop.push(i);
        }
        let secondIndexLoop = [];
        for (let i = biggestSecondIndex; i >= 0; i--) {
            secondIndexLoop.push(i);
        }
        return (
            <table className={"panel"}>
                <tbody>
                {secondIndexLoop.map(f => this.generateTableRow(f, firstIndexLoop, rawTable))}
                </tbody>
                <tfoot><tr>
                    <td/>
                    {firstIndexLoop.map(g => {
                        return <td key={g}>{g}</td>
                    })}</tr></tfoot>
            </table>);
    }

    generateTableRow(f, firstIndexLoop, rawTable) {
        return (
            <tr key={f}><td>{f}</td>
                {firstIndexLoop.map(g => this.generateTableCell(f, g, rawTable))}
            </tr>);
    }

    generateTableCell(f, g, rawTable) {
        if (rawTable[g] == null) {
            return (
                <td key={g}/>);
        }
      return (
        <td key={g}>
          {rawTable[g][f] == null ? "" : rawTable[g][f].map((h, index) => {
            return (
              <React.Fragment key={index}>
                {h}
                {index !== rawTable[g][f].length - 1 && ", "}
              </React.Fragment>
            );
          })}
        </td>
      );
    }

    /**
     * Removes displayed table.
     */
    clearTable() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'close-table'));
        this.setState({displayTable: null})
    }

    /**
     * Removes displayed goal trees.
     */
    clearGoalTrees() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'close-goal-trees'));
        this.setState({displayTrees: []})
    }

    /**
     * Switches the drawable area on or off.
     */
    switchDrawing() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'switch-drawing '
            + !this.state.drawingActive));
        this.setState({drawingActive: !this.state.drawingActive})
    }

    /**
     * Switches the drawable area on or off.
     */
    switchErasing() {
        this.setState({erasingActive: !this.state.erasingActive})
    }

    /**
     * Simple erasing method
     */
    eraseAtPoint(point) {
        let colorLines = this.state.lines;
        for (const i of [...Array(colorLines.size).keys()].reverse()) {
            let colorLine = colorLines.get(i);
            for (const linePoint of colorLine.line) {
                if (Math.abs(linePoint.get('x') - point.get('x')) < 2
                    && Math.abs(linePoint.get('y') - point.get('y')) < 2) {
                    colorLines = colorLines.remove(i);
                }
            }
        }
        this.setState({lines: colorLines})
    }

    /**
     * Switches both input and grammar to editable or not editable, whatever is
     * the opposite of the current state.
     * If grammar is off, switch both on.
     * Is grammar is on, switch both off.
     */
    switchEditableInputOrGrammar() {
        if (this.state.grammarEditable === true) {
            const input = document.getElementById('wInput');
            let inputValue = input === null ? this.state.input : input.value;
            const grammar = document.getElementById('tGrammar');
            let grammarValue = grammar === null ? this.state.grammar : grammar.value;
            inputValue = tokenizeInput(inputValue, grammarValue);
            if (this.state.formalism === 'cfg' || this.state.formalism === 'pcfg') {
                grammarValue = normalizeGrammar(grammarValue);
            }
            Task.gotoLocation(grammarValue, inputValue, this.state.formalism,
                this.state.algorithm);
            this.setState({grammarEditable: false, inputEditable: false});
        } else {
            this.setState({grammarEditable: true, inputEditable: true});
        }
    }

    /**
     * Switches the input to editable or not editable, whatever is
     * the opposite of the current state. If it becomes editable it focuses the
     * text field.
     */
    switchEditableInput() {
        if (this.state.inputEditable === true) {
            const input = document.getElementById('wInput');
            let inputValue = input === null ? this.state.input : input.value;
            const grammar = document.getElementById('tGrammar');
            let grammarValue = grammar === null ? this.state.grammar : grammar.value;
            inputValue = tokenizeInput(inputValue, grammarValue);
            if (this.state.formalism === 'cfg' || this.state.formalism === 'pcfg') {
                grammarValue = normalizeGrammar(grammarValue);
            }
            Task.gotoLocation(grammarValue, inputValue, this.state.formalism,
                this.state.algorithm);
            this.setState({inputEditable: false, grammarEditable: false});
        } else {
            this.setState({inputEditable: true});
        }
    }

    /**
     * Performs a redirect to the given location that consists of URL
     * parameters.
     */
    static performRedirect(url) {
        document.location.search = url;
    }

    /**
     * Returns true if a is in the set of terminals in the grammar.
     */
    isTerminal(a) {
        const p = new RegExp('[\\s\\S]*T\\s*=\\s*{(?:[^}]* )?' + a + '(?:, [^}]*)?}[\\s\\S]*', 'm');
        const match = this.state.grammar.match(p);
        return match !== null;
    }

    /**
     * Returns true if a is the input symbol at position i.
     */
    isInputSymbol(i, a) {
        const inputSplit = this.state.input.split(' ');
        return a.length > 0 && inputSplit[i] === a;
    }

    /**
     * Returns true if an input symbol at that position exist, hence if the
     * input has at most i symbols.
     */
    hasInputSymbol(i) {
        if (this.state.input.length === 0) {
            return i <= 0;
        }
        const inputSplit = this.state.input.split(' ');
        return i <= inputSplit.length;
    }

    /**
     * Returns the input symbol at position i-1.
     */
    getInputSymbol(i) {
        const inputSplit = this.state.input.split(' ');
        if (i <= inputSplit.length) {
            return inputSplit[i - 1];
        }
        return null;
    }

    /**
     * Returns true if a is in the set of terminals in the grammar.
     */
    isNonterminal(nt) {
        const p = new RegExp('[\\s\\S]*N\\s*=\\s*{(?:[^}]* )?' + nt + '(?:, [^}]*)?}[\\s\\S]*', 'm');
        const match = this.state.grammar.match(p);
        return match !== null;
    }

    /**
     * Performs a redirect.
     */
    static gotoLocation(grammar, input, formalism, algorithm) {
        document.location.search = '?input=' + encodeURIComponent(input) +
            '&grammar=' + encodeURIComponent(grammar) + '&formalism=' + formalism +
            '&algorithm=' + algorithm;
    }

    /**
     * Finds the first entry from trace that is not in tableContent and adds it.
     */
    solveNext() {
        if (this.state.deductionResult.chart == null){
            this.setErrorMessage("There is no task to solve steps for.", true);
            return;
        }
        let somethingNew = this.solveBackPointers(true);
        if (!somethingNew) {
            somethingNew = this.solveLines(true);
        }
        if (!somethingNew) {
            somethingNew = this.solveGoalItems(true);
        }
        this.solveMessage(somethingNew);
    }

    /**
     * Adds all missing entries to tableContent.
     */
    solveAll() {
        if (this.state.deductionResult.chart == null) {
            this.setErrorMessage("There is no task to solve steps for.", true);
            return;
        }

        let combinedHighlightedRows = {};

        combinedHighlightedRows = { ...combinedHighlightedRows, ...this.solveLines(false) };

        // Custom merging to ensure solveLines entries aren't overwritten.
        const backPointerRows = this.solveBackPointers(false);
        for (let key in backPointerRows) {
            if (!combinedHighlightedRows.hasOwnProperty(key)) {
                combinedHighlightedRows[key] = backPointerRows[key];
            }
        }

        const newLineAdded = Boolean(Object.keys(this.solveLines(false)).length);
        const newBackPointersAdded = Boolean(Object.keys(this.solveBackPointers(false)).length);
        const newGoalsMarked = this.solveGoalItems(false);

        this.solveMessage(newLineAdded || newBackPointersAdded || newGoalsMarked);

        this.setState({ highlightedRows: combinedHighlightedRows });
    }


    /**
     * Adds missing back pointers to the table, only one if one = true.
     */
    solveBackPointers(one) {
        let trace = this.state.trace;
        let tableContent = this.state.tableContent;
        let discoveredItemIds = this.state.discoveredItemIds;
        let somethingNew = false;
        let idMapOldToNew = this.state.idMapOldToNew;
        let highlightedRows = {};

        for (let i = 0; i < trace.length; i++) {
            const ruleSplit = trace[i][2].split(', ');
            const pointerSplit = trace[i][3].substring(1, trace[i][3].length - 1).split('}, {');
            let finalPointerSplit = [];
            for (let pointerList of pointerSplit) {
                finalPointerSplit.push(pointerList.split(', '));
            }
            finalPointerSplit = Task.mapBackPointers(finalPointerSplit, idMapOldToNew);
            for (let j = 0; j < tableContent.length; j++) {
                if (trace[i][1] === tableContent[j][1]) {
                    for (let k = 0; k < finalPointerSplit.length; k++) {
                        let backPointerSetExists = false;
                        for (let l = 0; l < tableContent[j][2].length; l++) {
                            if (ruleSplit[k] === tableContent[j][2][l]
                                && (tableContent[j][3].length === 0
                                    || finalPointerSplit[k].toString() === tableContent[j][3][l].toString())) {
                                backPointerSetExists = true;
                                break;
                            }
                        }
                        if (!backPointerSetExists) {
                            let backPointersDiscovered = true;
                            for (let m = 0; m < finalPointerSplit[k].length; m++) {
                                if (!discoveredItemIds[finalPointerSplit[k][m]]) {
                                    backPointersDiscovered = false;
                                    break;
                                }
                            }
                            if (backPointersDiscovered) {
                                tableContent[j][2].push(ruleSplit[k]);
                                tableContent[j][3].push(finalPointerSplit[k]);
                                let bpLength = new Set();
                                bpLength.add(tableContent[j][3].length - 1);
                                highlightedRows[j + 1] = bpLength;
                                this.setState({
                                    tableContent: tableContent,
                                    highlightedRows: highlightedRows});
                                somethingNew = true;
                                if (one) {
                                    return somethingNew;
                                }
                            }
                        }
                    }
                }
            }
        }
        return one? somethingNew : highlightedRows;
    }

    /**
     * Adds a missing line with one back pointer set to the table, only one if
     * one = true;
     */
    solveLines(one) {
        let trace = this.state.trace;
        let tableContent = this.state.tableContent;
        let discoveredItemIds = this.state.discoveredItemIds;
        let somethingNew = false;
        let idMapOldToNew = this.state.idMapOldToNew;
        let highlightedRows = {};

        for (let i = 0; i < trace.length; i++) {
            const ruleSplit = trace[i][2].split(', ');
            const pointerSplit = trace[i][3].substring(1, trace[i][3].length - 1).split('}, {');
            let finalPointerSplit = [];
            for (let pointerList of pointerSplit) {
                finalPointerSplit.push(pointerList.split(', '));
            }
            finalPointerSplit = Task.mapBackPointers(finalPointerSplit, idMapOldToNew);
            let lineExists = false;
            for (let j = 0; j < tableContent.length; j++) {
                if (trace[i][1] === tableContent[j][1]) {
                    lineExists = true;
                }
            }
            if (!lineExists) {
                const newId = tableContent.length + 1;
                idMapOldToNew.set(trace[i][0], newId);
                tableContent.push([newId, trace[i][1], [ruleSplit[0]],
                    [finalPointerSplit[0]], trace[i][4]]);
                somethingNew = true;
                discoveredItemIds[trace[i][0]] = true;
                highlightedRows[newId] = new Set();
                this.setState({
                    discoveredItemIds: discoveredItemIds,
                    tableContent: tableContent,
                    idMapOldToNew: idMapOldToNew,
                    highlightedRows: highlightedRows
                });
                if (one) {
                    return somethingNew;
                }
            }
        }
        return one? somethingNew : highlightedRows;
    }

    /**
     * Marks all goal items, only one if one = true;
     */
    solveGoalItems(one) {
        let stateDisplayGoals = this.state.displayGoals;
        const deductionResult = this.state.deductionResult;
        const idMapOldToNew = this.state.idMapOldToNew;
        let somethingNew = false;
        for (const line of deductionResult.chart.lines) {
            if (line.item.goal === true) {
                const id = idMapOldToNew.get(line.id);
                if (this.state.displayGoals.indexOf(id) === -1) {
                    stateDisplayGoals.push(id);
                    this.setState({
                        displayGoals: stateDisplayGoals});
                    somethingNew = true;
                    if (one) {
                        this.setState({ highlightedRows: {}});
                        return somethingNew;
                    }
                }
            }
        }
        return somethingNew;
    }

    solveMessage(somethingNew) {
        if (!somethingNew) {
            this.setUserMessage('All items are already in the table and all '
                + 'goal items are marked.');
        }
    }

    static mapBackPointers(backPointerLists, idMapOldToNew) {
        let mappedBackPointerLists = [];
        for (let backPointerList of backPointerLists) {
            let mappedBackPointerList = [];
            for (let backPointer of backPointerList) {
                if (backPointer === '') {
                    mappedBackPointerList.push(backPointer);
                } else {
                    mappedBackPointerList.push(idMapOldToNew.get(Number(backPointer)));
                }
            }
            mappedBackPointerList.sort();
            mappedBackPointerLists.push(mappedBackPointerList)
        }
        return mappedBackPointerLists;
    }

    /**
     * Displays a hint about how to use the user interface
     */
    showInstructions() {
        this.setUserMessage('Perform parsing-as-deduction. Drag and Drop items ' +
            'from the table and production rules from the grammar onto the ' +
            'deduction rules to create new items. \n Specify an input string ' +
            'and a grammar yourself or click New in the menu to generate a task.');
    }

    addAxiomsToTable(trace) {
        let tableContentValue = [];
        let discoveredItemIds = this.state.discoveredItemIds;
        let idMapOldToNew = this.state.idMapOldToNew;
        if (trace != null) {
            for (const line of trace) {
                if (line[3].startsWith('{}')) {
                    const l2Split = line[2].split(', ');
                    tableContentValue.push([line[0], line[1], [l2Split[0]],
                        [[]], line[4]]);
                    discoveredItemIds[line[0]] = true;
                    idMapOldToNew.set(line[0], line[0]);
                }
            }
        }
        this.setState({
            discoveredItemIds: discoveredItemIds,
            idMapOldToNew: idMapOldToNew
        });
        return tableContentValue;
    };

    static backPointersToString(backPointers) {
        let backPointerString = "";
        let first1 = true;
        for (const backPointerSet of backPointers) {
            if (first1) {
                first1 = !first1;
            } else {
                backPointerString += ", "
            }
            backPointerString += "{";
            let first2 = true;
            for (const backPointer of backPointerSet) {
                if (first2) {
                    first2 = !first2;
                } else {
                    backPointerString += ", "
                }
                backPointerString += backPointer;
            }
            backPointerString += "}";
        }
        backPointerString += "";
        return backPointerString;
    }

    static itemToString(itemForm) {
        let itemString = "[";
        let first = true;
        for (const element of itemForm) {
            if (first) {
                first = !first;
            } else {
                itemString += ","
            }
            if (element === "") {
                itemString += "ε";
            } else {
                itemString += element;
            }
        }
        itemString += "]";
        return itemString;
    }

    checkSolution() {
        let goalCount = 0;
        let backPointerSets = 0;
        let backPointerSetsFound = 0;
        const deductionResult = this.state.deductionResult;
        for (const line of deductionResult.chart.lines) {
            if (line.item.goal === true) {
                goalCount++;
            }
            if (line['backPointers'][0].length > 0) {
                backPointerSets += line['backPointers'].length;
            }
        }
        for (const line of this.state.tableContent) {
            if (line[3][0].length > 0) {
                backPointerSetsFound += line[3].length;
            }
        }
        this.setUserMessage('You found ' + this.state.tableContent.length
            + ' of ' + this.state.trace.length + ' parsing trace items and '
            + this.state.displayGoals.length + ' of ' + goalCount
            + ' goal items. You found ' + backPointerSetsFound + ' out of '
            + backPointerSets + ' ways to generate items.');
    };

    sendSurveyResponse(difficulty) {
        this.setUserMessage('Thank you!');
        this.setState({survey: false});
        requestLogRestService(USER_DIFFICULTY(this.props.userSession, difficulty));
    }

    requestDeductionRestService(input, grammar, formalism, algorithm) {
        const url = BACKEND_PROTOCOL + '//'
            + BACKEND_HOST + ':'
            + BACKEND_PORT + '/'
            + BACKEND_PATH + '/rest/deduction?input=' +
            encodeURIComponent(input) +
            '&grammar=' + encodeURIComponent(grammar) +
            '&formalism=' + formalism +
            '&algorithm=' + algorithm;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(this.responseText);
            };
            xhr.onerror = reject;
            xhr.open('GET', url);
            xhr.send();
        });
    }

    /**
     * Deduction rules call this function to send new items to the deduction
     * table displayed to the user. Returns true if the item is new, false if
     * not.
     */
    receiveNewItem(item, deductionRuleName, backPointerList) {
        let stateTableContent = this.state.tableContent;
        let isNewItem = true;
        let newItemFoundInTable = false;
        let idMapOldToNew = this.state.idMapOldToNew;
        let somethingWasAdded = false;
        let highlightedRows = {};
        for (let i = 0; i < stateTableContent.length; i++) {
            if (stateTableContent[i][1] === item) {
                for (let j = 0; j < stateTableContent[i][2].length; j++) {
                    if (stateTableContent[i][2][j] === deductionRuleName
                        && stateTableContent[i][3][j].toString()
                        === backPointerList.toString()) {
                        isNewItem = false;
                        newItemFoundInTable = true;
                        const highlightedRow = i + 1;
                        let jota = new Set();
                        jota.add(j);
                        highlightedRows[highlightedRow] = jota;
                        break;
                    }
                }
                if (!newItemFoundInTable) {
                    stateTableContent[i][2].push(deductionRuleName);
                    stateTableContent[i][3].push(backPointerList);
                    newItemFoundInTable = true;
                    isNewItem = false;
                    somethingWasAdded = true;
                    const highlightedRow = i + 1;
                    let bpLength = new Set();
                    bpLength.add(stateTableContent[i][3].length);
                    highlightedRows[highlightedRow] = bpLength;
                    this.setState({
                        tableContent: stateTableContent
                    });
                }
            }
        }
        if (isNewItem) {
            this.state.trace.forEach(function (line) {
                if (line[1] === item) {
                    const newId = stateTableContent.length + 1;
                    stateTableContent.push([newId, item, [deductionRuleName],
                        [backPointerList]]);
                    newItemFoundInTable = true;
                    idMapOldToNew.set(line[0], newId)
                    highlightedRows[newId] = new Set();
                }
            });
            somethingWasAdded = true;
            this.setState({
                tableContent: stateTableContent,
                idMapOldToNew: idMapOldToNew,
                generated: this.state.generated + 1
            });
            if (this.state.generated === 2) {
                this.setState({
                    survey: true
                });
            }
        }
        this.setState({
            highlightedRows: highlightedRows
        });
        if (somethingWasAdded) {
            requestLogRestService(USER_GENERATED_ITEM_ADDED(this.getUserSession(),
                item, deductionRuleName, backPointerList));
        } else {
            requestLogRestService(USER_GENERATED_ITEM_DISMISSED(this.getUserSession(),
                item, deductionRuleName, backPointerList));
        }
        return newItemFoundInTable;
    }

    getUserSession() {
        return this.props.user + '-' + this.state.session;
    }

    receiveNewGoal(item) {
        let stateTableContent = this.state.tableContent;
        let isNewGoal = true;
        let stateDisplayGoals = this.state.displayGoals;
        for (let i = 0; i < stateTableContent.length; i++) {
            if (stateTableContent[i][1] === item) {
                if (this.state.displayGoals.indexOf(stateTableContent[i][0]) >= 0) {
                    isNewGoal = false;
                    requestLogRestService(USER_GENERATED_GOALITEM_DISMISSED(this.getUserSession(), item));
                } else {
                    stateDisplayGoals.push(stateTableContent[i][0]);
                    this.setState({
                        displayGoals: stateDisplayGoals,
                        highlightedRows: {}});
                    requestLogRestService(USER_GENERATED_GOALITEM_ADDED(this.getUserSession(), item));
                }
                break;
            }
        }
        return isNewGoal
    }

    setLoading(bool) {
        this.setState({loading: bool});
    }

    setErrorMessage(message, append = false) {
        const existingErrorMessage = this.state.errorMessage;
        if (append && existingErrorMessage.length > 0) {
            this.setState({
                errorMessage: existingErrorMessage + '\n' + message});
        } else {
            this.setState({errorMessage: message});
        }
    }

    setUserMessage(message) {
        this.setState({userMessage: message});
    }

    setColor(color) {
        this.setState({currentColor: color,
            erasingActive: false});
    }

    closeSurvey(survey) {
        this.setState({survey: survey});
        requestLogRestService(USER_REQUEST(this.getUserSession(), 'close-survey'));
    }

    render() {
        let displayTrees = [];
        let i = 0;
        const input = this.state.input;
        const inputLength = input === '' ? 0 : input.split(' ').length;
        for (const tree of this.state.displayTrees) {
            displayTrees.push(<Tree key={i} treeString={tree}/>);
            i++;
        }
        let displayProbabilities = this.state.formalism === "pcfg";
        const displayTable = this.state.displayTable;

        return (<span>
            <Breadcrumb pathList={["Task"]}/>
            <div className='content-body-task'>
                <div className='task-left'>
                    <p className='task panel'>Perform {this.state.algorithm} parsing
                        with the given
                        input
                        and grammar.</p>
                    {this.state.drawingActive ?
                        <DrawArea
                            lines={this.state.lines}
                            addLine={this.addLine.bind(this)}
                            addPointToLastLine={this.addPointToLastLine.bind(this)}
                            erasingActive={this.state.erasingActive}
                            eraseAtPoint={this.eraseAtPoint.bind(this)}/>
                        : ''}
                    <DeductionTable tableContent={this.state.tableContent}
                                    highlightedRows={this.state.highlightedRows}
                                    userSession={this.getUserSession()}
                                    displayGoals={this.state.displayGoals}
                                    displayProbabilities={displayProbabilities}/>
                    <div id={"parsing-table-area"}>
                        {displayTable != null ?
                            <button className={'clickable-icon closeable-icon'}
                                    onClick={this.clearTable.bind(this)}>
                                <FontAwesomeIcon icon={faTimes}/></button> : ''}
                        {displayTable}</div>
                    <div id={"tree-area"}>
                        {displayTrees.length > 0 ?
                            <button className={'clickable-icon closeable-icon'}
                                    onClick={this.clearGoalTrees.bind(this)}>
                                <FontAwesomeIcon icon={faTimes}/></button> : ''}
                        {displayTrees}</div>
                </div>
                <div className='task-right'>
                    <CookiesProvider>
                        <DeductionMenu
                            handleExerciseRequest={Task.performRedirect.bind(this)}
                            algorithm={this.state.algorithm}
                            formalism={this.state.formalism}
                            solveNext={this.solveNext.bind(this)}
                            solveAll={this.solveAll.bind(this)}
                            showInstructions={this.showInstructions.bind(this)}
                            checkSolution={this.checkSolution.bind(this)}
                            setLoading={this.setLoading.bind(this)}
                            setErrorMessage={this.setErrorMessage.bind(this)}
                            showGoalTrees={this.showGoalTrees.bind(this)}
                            showTable={this.showTable.bind(this)}
                            userSession={this.getUserSession()}/>
                    </CookiesProvider>
                    <ErrorMessage message={this.state.errorMessage}/>
                    <UserMessage message={this.state.userMessage}
                                 setUserMessage={this.setUserMessage.bind(this)}
                                 userSession={this.getUserSession()}/>
                    <Loader visible={this.state.loading}/>
                    <Survey visible={this.state.survey}
                            sendSurveyResponse={this.sendSurveyResponse.bind(this)}
                            closeSurvey={this.closeSurvey.bind(this)}/>
                     <div className={'drawingPanel'}>
                    {this.state.drawingActive ? <div className={'colorPalette'}>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'black'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(255,115,115)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(236,115,255)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(196,115,255)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(149,115,255)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(115,148,255)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(115,215,255)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(87,211,209)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(92,232,148)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(177,232,92)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(232, 221, 92)'}/>
                            <ColorButton setColor={this.setColor.bind(this)}
                                         color={'rgb(232, 174, 92)'}/>
                            <button onClick={this.switchErasing.bind(this)}>
                                <FontAwesomeIcon className={'clickable-icon'}
                                                 icon={faEraser}/>
                            </button>

                        </div>
                    : ''}
                    <DrawButton handleSwitchDrawing={this.switchDrawing.bind(this)}/><br/>
                     </div>
                     <div id={'floating-div'}>
                    <Input input={this.state.input}
                           handleChangeInput={this.switchEditableInput.bind(this)}
                           editable={this.state.inputEditable}/>
                    {(this.state.formalism === 'cfg' || this.state.formalism === 'pcfg') ?
                        <CfgGrammar grammar={this.state.grammar}
                                    handleChangeGrammar={this.switchEditableInputOrGrammar.bind(this)}
                                    editable={this.state.grammarEditable}
                                    userSession={this.getUserSession()}/> : ''
                    }
                               {(this.state.formalism === 'tag') ?
                                   <TagGrammar grammar={this.state.grammar}
                                               handleChangeGrammar={this.switchEditableInputOrGrammar.bind(this)}
                                               editable={this.state.grammarEditable}
                                               userSession={this.getUserSession()}/> : ''
                               }
                               {(this.state.formalism === 'srcg') ?
                                   <SrcgGrammar grammar={this.state.grammar}
                                                handleChangeGrammar={this.switchEditableInputOrGrammar.bind(this)}
                                                editable={this.state.grammarEditable}
                                                userSession={this.getUserSession()}/> : ''
                               }
                         <DeductionRules algorithm={this.state.algorithm}
                                         formalism={this.state.formalism}
                                         sendNewItemToTask={this.receiveNewItem.bind(this)}
                                         sendNewGoalToTask={this.receiveNewGoal.bind(this)}
                                         startSymbol={this.state.startSymbol}
                                         isInputSymbol={this.isInputSymbol.bind(this)}
                                         isNonterminal={this.isNonterminal.bind(this)}
                                         hasInputSymbol={this.hasInputSymbol.bind(this)}
                                         getInputSymbol={this.getInputSymbol.bind(this)}
                                         inputLength={inputLength}
                                         userSession={this.getUserSession()}
                                         maxItem={this.state.maxItem}
                         />
                           </div>
                </div>
            </div>
                    <DragLayer key="__preview"/></span>
        );
    }
}

export default withCookies(DragDropContext(TouchBackend({enableMouseEvents: true}))(Task))
