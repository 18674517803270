import Breadcrumb from "./Breadcrumb";
import React from "react";


/**
 * Contact information and impressum
 */
function Contact() {
    document.title = "CL-Taskbox - Contact";
    return (<span>
                <Breadcrumb pathList={["Contact"]}/>
        <h1>Contact</h1>
       <h2>E-mail</h2>
        <div>If you have feedback, ideas for improvements or just want to say
            thanks you can send me (Samya Daleh) a mail:<br/>
            <a href="mailto:darkninjaaliengoatmother@gmail.com">
            darkninjaaliengoatmother@gmail.com</a><br/><br/>
            Samya Daleh is Anika Westburg
        </div>
        <h2>Information According to <a
            href="https://www.gesetze-im-internet.de/tmg/__5.html">TMG § 5</a>
        </h2>
       <div>Responsible for web site:<br/>
       Anika Westburg<br/>
       Aachener Straße 71<br/>
       40223 Düsseldorf (Germany)</div>
   </span>);
}

export default Contact;
