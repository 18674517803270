import {
    faDice, faDiceD20,
    faDiceD6,
    faSquareFull
} from "@fortawesome/free-solid-svg-icons";

export const featureData = {
    cfg_topdown: {
        difficulties: {Easy: 3, Medium: 15, Hard: 40},
        pool: ["?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20S%20b%2C%20S%20-%3E%20a%20b%7D&formalism=cfg&algorithm=cfg-topdown",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%2C%20T%20-%3E%20S%20b%7D&formalism=cfg&algorithm=cfg-topdown",
            "?input=a%20b%20b%20b%20a%20a&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%20T%2C%20T%20-%3E%20b%20T%20a%2C%20T%20-%3E%20%CE%B5%7D&formalism=cfg&algorithm=cfg-topdown"],
        tutorial: {en: "https://www.youtube.com/watch?v=rOXSE_Kabuc", de: "https://www.youtube.com/watch?v=jsC1_jDoTZY"}
    },
    cfg_shiftreduce: {
        difficulties: {Easy: 3, Medium: 15, Hard: 40},
        pool: ["?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20S%20b%2C%20S%20-%3E%20a%20b%7D&formalism=cfg&algorithm=cfg-shiftreduce",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20A%2C%20B%2C%20X%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20A%20B%2C%20S%20-%3E%20A%20X%2C%20X%20-%3E%20S%20B%2C%20A%20-%3E%20a%2C%20B%20-%3E%20b%7D&formalism=cfg&algorithm=cfg-shiftreduce",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%2C%20T%20-%3E%20S%20b%7D&formalism=cfg&algorithm=cfg-shiftreduce"],
        tutorial: {en: "https://www.youtube.com/watch?v=hzyu6z_MfGA", de: "https://www.youtube.com/watch?v=aH4Lksd1iT0"}
    },
    cfg_cyk: {
        difficulties: {Easy: 3, Medium: 15, Hard: 40},
        pool: ["?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20A%2C%20B%2C%20X%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20A%20B%2C%20S%20-%3E%20A%20X%2C%20X%20-%3E%20S%20B%2C%20A%20-%3E%20a%2C%20B%20-%3E%20b%7D&formalism=cfg&algorithm=cfg-cyk",
            "?input=a%20a%20a%20a&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20S%20S%2C%20S%20-%3E%20a%7D&formalism=cfg&algorithm=cfg-cyk"],
        parseTable: true,
        tutorial: {en: "https://www.youtube.com/watch?v=ZSk1JFCUuy8", de: "https://www.youtube.com/watch?v=xGj2yKEliyU"}
    },
    cfg_leftcorner: {
        difficulties: {Easy: 3, Medium: 15, Hard: 40},
        pool: ["?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20S%20b%2C%20S%20-%3E%20a%20b%7D&formalism=cfg&algorithm=cfg-leftcorner",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%2C%20T%20-%3E%20S%20b%7D&formalism=cfg&algorithm=cfg-leftcorner",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20A%2C%20B%2C%20X%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20A%20B%2C%20S%20-%3E%20A%20X%2C%20X%20-%3E%20S%20B%2C%20A%20-%3E%20a%2C%20B%20-%3E%20b%7D&formalism=cfg&algorithm=cfg-leftcorner"],
        tutorial: {en: "https://youtu.be/EjBDLarOlN8", de: "https://youtu.be/nLQwu9HVAtY"}
    },
    cfg_earley: {
        difficulties: {Easy: 3, Medium: 15, Hard: 40},
        pool: ["?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20S%20b%2C%20S%20-%3E%20a%20b%7D&formalism=cfg&algorithm=cfg-earley",
            "?input=a%20a%20b%20b&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%2C%20T%20-%3E%20S%20b%7D&formalism=cfg&algorithm=cfg-earley",
            "?input=a%20a%20a%20a&grammar=N%20%3D%20%7BS%7D%0AT%20%3D%20%7Ba%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20S%20S%2C%20S%20-%3E%20a%7D&formalism=cfg&algorithm=cfg-earley",
            "?input=a%20b%20b%20b%20a%20a&grammar=N%20%3D%20%7BS%2C%20T%7D%0AT%20%3D%20%7Ba%2C%20b%7D%0AS%20%3D%20S%0AP%20%3D%20%7BS%20-%3E%20a%20T%2C%20T%20-%3E%20b%20T%2C%20T%20-%3E%20b%20T%20a%2C%20T%20-%3E%20%CE%B5%7D&formalism=cfg&algorithm=cfg-earley"],
        parseTable: true,
        tutorial: {en: "https://www.youtube.com/watch?v=S_WaAeqBBwE", de: "https://www.youtube.com/watch?v=_wW2D_xCnns"}
    },
    cfg_earley_passive: {difficulties: {Easy: 3, Medium: 15, Hard: 40}},
    cfg_leftcorner_chart: {difficulties: {Easy: 3, Medium: 15, Hard: 40},
        parseTable: true},
    cfg_cyk_extended: {difficulties: {Easy: 3, Medium: 15, Hard: 40},
        parseTable: true},
    cfg_cyk_general: {difficulties: {Easy: 3, Medium: 15, Hard: 40},
        parseTable: true},
    cfg_earley_bottomup: {difficulties: {Easy: 3, Medium: 15, Hard: 40}},
    cfg_unger: {difficulties: {Easy: 3, Medium: 15},
        parseTable: true},
    pcfg_astar: {difficulties: {Easy: 3, Medium: 15, Hard: 40},
        parseTable: true},
    pcfg_cyk: {difficulties: {Easy: 3, Medium: 15, Hard: 40},
        parseTable: true},
    tag_cyk_extended: {difficulties: {Random: -1}},
    tag_cyk_general: {difficulties: {Random: -1}},
    tag_earley: {difficulties: {Random: -1}},
    tag_earley_prefixvalid: {difficulties: {Random: -1}},
    srcg_cyk_extended: {difficulties: {Random: -1}},
    srcg_cyk_general: {difficulties: {Random: -1}},
    srcg_earley: {difficulties: {Random: -1}}
};

export const iconData = {
    Random: faDice,
    Easy: faSquareFull,
    Medium: faDiceD6,
    Hard: faDiceD20
};
