import Breadcrumb from "./Breadcrumb";
import React from "react";


/**
 * Online documentation that explains what this project is about
 */
function About() {
    document.title = "CL-Taskbox - About";
    return (<span>
                <Breadcrumb pathList={["About"]}/>
                <h1>About This Project</h1>
       <h2>Purpose</h2>
       <div>For science, learning and teaching.</div>
       <h2>Data Collected</h2>
       <ul>
           <li>I'm logging usage data when you interact with a task: like when
               you start to drag an item, where you drop it, when you ask for hints etc.</li>
           <li>A cookie with a random string associates a user with their
               interaction. It's anonymous.</li>
           <li>I use the collected usage data for statistical analysis = for science.</li>
       </ul>
        With &quot;science&quot; I mean including but not limited to:
        <ul>
            <li>I will store the usage log data for at least 10 years</li>
            <li>I will perform statistical analysis on them and publish the
                results</li>
            <li>I will hand in the usage logs on a data medium along with the
                written copies of my dissertation</li>
            <li>I might give the usage logs to other scientists or reasonable
                persons who want to reproduce my statistical analysis or who
                want to perform their own analysis and publish their results</li>
            <li>I will not sell the data and I will not hand out the data
                without a good, science-related reason</li>
        </ul>
        <h2 id="changelog">What's New in Each Version</h2>
        <h3>Continuous Delivery</h3>
        <div><ul>
            <li>Earley Bottom-up moved to tasks 2024-05-03</li>
            <li>Inputs randomly not in grammar 2024-04-07</li>
            <li>Line breaks in display grammar 2023-09-24</li>
            <li>Highlight new and existing items on re-adding in yellow 2023-09-17</li>
            <li>Add keyboard support for deduction menu 2023-05-06</li>
            <li>Automatically tokenize input and deduce terminals and nonterminals if not given 2023-02-03</li>
            <li>Links to tutorial videos 2022-04-23</li>
            <li>Edit input button only switches edit mode of input and sets focus 2022-04-17</li>
            <li>Make CFG Earley Passive solveable - 2022-04-10</li>
            <li>Remove Google Analytics - 2022-03-18</li>
            <li>Improve annoying auto-sizing - 2022-03-11</li>
            <li>Deduction rules stay always visible - 2022-03-02</li>
            <li>On-screen drawing - 2022-02-22</li>
            <li>Handle production rules with pipes - 2022-02-17</li>
            <li>Difficulty survey - 2021-09-24</li>
            <li>Use alphabet letters as symbols and order production rules alphabetically - 2021-09-10</li>
            <li>Task generation with difficulty levels (for CFG TD, SR, CYK, LC and Earley) - 2021-07-23</li>
            <li>Tasks from pool - 2020-06-07</li>
            <li>Display PCFGs - 2020-05-23</li>
            <li>Improve tree drawing including crossing edges - 2020-05-16</li>
            <li>Add parsing algorithms for looking: TAG CYK, TAG CYK General,
                TAG Earley, TAG Earley Prefix Valid, sRCG CYK,
                sRCG CYK General, sRCG Earley - 2019-12-26</li>
            <li>Display Parsing Table for all applying algorithms - 2019-12-26</li>
            <li>Add parsing algorithm for looking: CFG CYK Extended, CFG CYK
                General, CFG Unger, CFG Earley Bottom-up, CFG Earley Passive,
                CFG Left-Corner Chart, PCFG A*, PCFG CYK - 2019-12-24</li>
            <li>Change versioning - 2019-11-17</li>
            <li>Generate random input - 2019-11-17</li>
        </ul>
        </div>
        <h3>0.4 - Make it Right - 2019-04-29</h3>
        <div>
            <ul>
                <li>UX/UI improvements</li>
                <li>pre-filled empty grammars</li>
                <li>drag hover fine tuning</li>
                <li>basic keyboard support</li>
                <li>trees and messages can be removed with x</li>
                <li>goal items can be marked</li>
                <li>backpointers are now smallest units</li>
            </ul>
        </div>
        <h3>0.3 - Data Analysis - 2019-03-02</h3>
        <div>
            <ul>
                <li>Backend returns data in Json format</li>
                <li>UX/UI improvements</li>
                <li>breadcrumb</li>
                <li>hint button displays goal trees</li>
                <li>touch support</li>
            </ul>
        </div>
        <h3>0.2 - Hosted Prototype - 2018-12-28</h3>
        <div>
            <ul>
                <li>load indicator when requesting backend</li>
                <li>cookie identifies returning user</li>
                <li>Google Analytics included</li>
            </ul>
        </div>
        <h3>0.1 - Running Prototype - 2018-11-09</h3>
        <div>
            <ul>
                <li>basic parsing tasks interactive solveable:</li>
                <li>CFG Top-Down, Shift-Reduce, CYK, Earley, Left-Corner</li>
                <li>simple random task generation</li>
                <li>simple task solution check</li>
                <li>editable grammar and input -> custom parsing tasks</li>
                <li>task represented in URL, can be shared and bookmarked</li>
                <li>user interaction logging</li>
                </ul>
        </div>
        <h2>Publications</h2>
        <ul>
            <li>Westburg, A., (2019). An E-Learning Tool for Parsing-as-Deduction. In: Pinkwart, N. & Konert, J. (Hrsg.), DELFI 2019. Bonn: Gesellschaft für Informatik e.V.. (S. 283-284). DOI: 10.18420/delfi2019_186</li>
            <li>Westburg, A., (2022). An Improved E-Learning Tool for Parsing-as-Deduction. In: Henning, P. A., Striewe, M. & Wölfel, M. (Hrsg.), 20. Fachtagung Bildungstechnologien (DELFI). Bonn: Gesellschaft für Informatik e.V.. (S. 255-256). DOI: 10.18420/delfi2022-057</li>
        </ul>
        <h2>Known Bugs</h2>
      <ul>
        <li>See <a href={"https://github.com/SamyaDaleh/CL-Toolbox/issues"}>https://github.com/SamyaDaleh/CL-Toolbox/issues</a></li>
      </ul>
   </span>);
}

export default About;
