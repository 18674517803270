import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './index.css';
import Task from './taskcomponents/Task.js';
import {Cookies, CookiesProvider, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import About from "./About";
import Contact from "./Contact";
import Status from "./Status";
import Home from "./Home";
import TreePage from "./TreePage";
import TreeTestPage from "./TreeTestPage";
import RedirectToJson from "./redirect-to-json";
import CfgLrkPage from "./CfgLrkPage";
import LogAndRedirect from "./LogAndRedirect";


/**
 * A clickable logo linking to Home.
 */
function Logo() {
    return (
        <a href='/'>
            <img className='logo' src='./wzk_Avatar.png' width='50px'
                 alt='logo'/>
        </a>
    );
}

/**
 * Creates a random string used to identify a recurring user.
 */
export function makeId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 9; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

/**
 * Entry point for all React generated website content.
 */
class Main extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        const {cookies} = props;
        let user = cookies.get('user');
        if (user === undefined) {
            user = makeId();
            cookies.set('user', user, {path: '/'});
        }
        this.state = {
            user: user
        }
    }

    render() {
        const user = this.state.user;
        return (
            <div className='content'>
                <div className='header'>
                    <Logo/><span className={'title'}>CL-Taskbox</span><br/>
                    <span className='version'>Last Update: 2024-05-03 -
                        <a href='/about'>What's new?</a></span>
                </div>
                <Router>
                    <div className='content-body'>
                        <Route exact path='/' component={Home}/>
                        <Route path='/task' render={(props) =>
                            <CookiesProvider>
                                <Task user={user} {...props} />
                            </CookiesProvider>
                        }/>
                        <Route path='/about' component={About}/>
                        <Route path='/contact' component={Contact}/>
                        <Route path='/cfg-lrk' render={(props) => <CfgLrkPage
                            {...props} />}/>
                        <Route path='/status' component={Status}/>
                        <Route path='/tree' render={(props) => <TreePage
                            {...props} />}/>
                        <Route path='/treetest' component={TreeTestPage}/>
                        <Route path='/plain' component={RedirectToJson}/>
                        <Route path='/log' render={(props) =>
                            <CookiesProvider>
                                <LogAndRedirect user={user} {...props} />
                            </CookiesProvider>
                        }/>
                    </div>
                </Router>
            </div>
        );
    }
}

export default withCookies(Main);
