import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgLeftCornerChartRemove() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Remove:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[A -> α •X β, i, l<sub>2</sub>] [X, j, l<sub>2</sub>]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A -> α X •β, i, l<sub>1</sub> + l<sub>2</sub></span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>j = i + l<sub>1</sub></span>
        </div>
    </div>)
}
