import React from 'react'
import PropTypes from 'prop-types'
import {DragSource} from 'react-dnd'
import {requestLogRestService} from './Task.js'
import {USER_DRAG_START_ITEM, USER_DRAG_STOP_ITEM} from "../constants";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * Implements the drag source contract.
 */
const itemSource = {
    beginDrag(props) {
        requestLogRestService(USER_DRAG_START_ITEM(props.userSession, props.itemForm));
        return {
            itemForm: props.itemForm, id: props.id
        };
    },
    endDrag(props) {
        requestLogRestService(USER_DRAG_STOP_ITEM(props.userSession, props.itemForm));
        return {
            itemForm: props.itemForm, id: props.id
        };
    }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging()
    };
}

const propTypes = {
    itemForm: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    displayProbabilities: PropTypes.bool,
    probability: PropTypes.string,

    // Injected by React DnD:
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired
};

/**
 * The item in the deduction table that can be used by the user to derive more
 * items.
 */
class Item extends React.Component {
    render() {
        const {isDragging, connectDragSource, itemForm, displayProbabilities, probability} = this.props;
        // might also be weight depending on the algorithm
        const formattedP = parseFloat(probability).toFixed(2);
        return connectDragSource(<button className='draggable'
                                         style={{opacity: isDragging ? 0.5 : 1}}>
            <FontAwesomeIcon className={'drag-indicator'}
                             icon={faEllipsisV}/>
            <FontAwesomeIcon className={'drag-indicator'}
                             icon={faEllipsisV}/>
            <span>{displayProbabilities ? formattedP + " : " : ""}{itemForm}</span>
        </button>);
    };
}

Item.propTypes = propTypes;
export default DragSource('item', itemSource, collect)(Item);
