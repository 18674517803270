import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykLexScan() {
    return (
        <div className='deduction-rule'>
            <div className='rule-name'>Lex-Scan:</div>
            <div className='middle-rule-part'>
                <hr/>
                <div
                    className='consequence'>[ɣ, ρ<sub>⊤</sub>, i, -, -, i + 1]
                </div>
            </div>
            <div className='side-condition'><span className={'unbreakable'}>l(ɣ, ρ) = w<sub>i + 1</sub></span>
            </div>
        </div>)
}
