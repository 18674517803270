import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyInitialize() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Initialize:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[S(φ-vec) → Φ-vec, 0, &lt;1, 0>, ρ<sub>init</sub>-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>S(φ-vec) → Φ-vec ∈ P</span>
        </div>
    </div>)
}
