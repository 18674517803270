import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykAdjoin() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Adjoin:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[β, ε<sub>⊤</sub>, i, f<sub>1</sub>, f<sub>2</sub>, j],</span>&nbsp;
                <span className={'unbreakable'}>[γ, p<sub>⊥</sub>, f<sub>1</sub>, f'<sub>1</sub>, f'<sub>2</sub>, f<sub>2</sub>]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p<sub>⊤</sub>, i, f'<sub>1</sub>, f'<sub>2</sub>, j]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>β ∈ f<sub>SA</sub>(γ, p)</span></div>
    </div>)
}
