import React from 'react'
import Antecedence from "../../taskcomponents/Antecedence";
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export class CfgEarleyPassiveConvert extends React.Component {
    initial_values = {
        antecedence: '',
        b: '',
        displayB: 'B',
        gamma: '',
        displayGamma: 'γ',
        j: '',
        displayJ: 'j',
        k: '',
        displayK: 'k',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    displayJ: 'j',
                    displayK: 'k',
                    displayB: 'B',
                    displayGamma: 'γ'
                });
            } else {
                this.extractAdditionalItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     * Passive items have no -> and cannot drop.
     */
    canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const rule = item.itemForm.substring(1, firstComma);
        if (rule.indexOf('->') === -1) {
            return false;
        }
        const dotPos = rule.indexOf('•');
        return dotPos === rule.length - 1;
    }

    extractAdditionalItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const j = item.itemForm.substring(firstComma + 1, secondComma);
        const k = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const b = ruleSplit[0].trim();
        const gamma = ruleSplit[1].trim();
        this.setState({
            j: j,
            k: k,
            b: b,
            gamma: gamma,
            canDropItem: 0
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const j = item.itemForm.substring(firstComma + 1, secondComma);
        const k = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        if (ruleStack.indexOf('->') === -1) {
            this.setState({
                displayB: ruleStack,
                displayGamma: '',
                displayJ: j,
                displayK: k
            });
            return;
        }
        const ruleSplit = ruleStack.split('->');
        const b = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const gamma = rhs.substring(0, dotPos - 1);
        this.setState({
            displayB: b,
            displayGamma: gamma,
            displayJ: j,
            displayK: k
        });
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[' + this.state.b;
            newItem = newItem + ',' + this.state.j + ','
                + this.state.k  + ']';
            this.props.sendNewItemToTask(newItem, 'convert ' + this.state.b,
                [this.state.antecedence.id]);
            this.clear();

        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const b = this.state.displayB;
        const gamma = this.state.displayGamma;
        const j = this.state.displayJ;
        const k = this.state.displayK;
        const canDropItem = this.state.canDropItem;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Convert:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-earley-scan'
                                 elementLists={[[b, '->', gamma, '•' ], [j], [k]]}
                                 markings={[[itemHovering, canDropItem, canDropItem, canDropItem],
                                     [itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[b], [j], [k]]}
                        markings={[[itemHovering],
                            [itemHovering], [itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
        </div>
    }
}
