import React from "react";
import {Input} from "./taskcomponents/Input";
import {CfgGrammar} from "./taskcomponents/CfgGrammar";
import {
    getUrlParamDecoded,
    replacePipesInGrammar
} from "./taskcomponents/Task";
import {
    BACKEND_HOST,
    BACKEND_PATH,
    BACKEND_PORT, BACKEND_PROTOCOL
} from "./constants";

/**
 * A page with a text input field that renders trees from tree strings in
 * bracket notation.
 */
class CfgLrkPage extends React.Component {
    constructor(props) {
        super(props);
        const grammar = getUrlParamDecoded(props,"grammar");
        const params = new URLSearchParams(props.location.search);
        const k = params.get('k');
        this.state = {
            grammar: grammar,
            k: k,
            editable: false,
            lrkData : {}
        };
    }

    static gotoLocation(grammar, k) {
        document.location.search =  '?k=' + k
            + '&grammar=' + encodeURIComponent(grammar);
    }

    /**
     * Is called by React after initialization of the component and before the
     * page is displayed in the browser. Requests the REST API for the parsing
     * trace of the current parameters.
     */
    componentDidMount() {
        document.title = "CL-Taskbox - CFG LR(k) Test Page";
        let k = this.state.k;
        let grammarValue = this.state.grammar;
        let lrkData = {};
        if (k !== '' && grammarValue !== '') {
            this.requestLrKRestService(k, grammarValue).then((result) => {
                lrkData = JSON.parse(result);
                this.setState({
                    lrkData: lrkData
                });
            }).catch(() => {
                console.error("an error occurred when requesting the LR(k) service.")
            });
        }
    }

    requestLrKRestService(k, grammar) {
        const url = BACKEND_PROTOCOL + '//'
            + BACKEND_HOST + ':'
            + BACKEND_PORT + '/'
            + BACKEND_PATH + '/rest/lrk?k=' + k
            +'&grammar=' + encodeURIComponent(grammar);
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(this.responseText);
            };
            xhr.onerror = reject;
            xhr.open('GET', url);
            xhr.send();
        });
    }

    switchEditable(){
        if (this.state.editable === true) {
            const k = document.getElementById('wInput');
            const kValue = k === null ? this.state.k : k.value;
            const grammar = document.getElementById('tGrammar');
            let grammarValue = grammar === null ? this.state.grammar : grammar.value;
            grammarValue = replacePipesInGrammar(grammarValue);
            CfgLrkPage.gotoLocation(kValue, grammarValue);
        }
        this.setState({editable: !this.state.editable});
    }

    render() {
        const dotGraph = this.state.lrkData;
        return (<span>
            <Input input={this.state.k}
                   handleChangeInput={this.switchEditable.bind(this)}
                   editable={this.state.editable}/><br/>
             <CfgGrammar
                 grammar={this.state.grammar}
                 handleChangeGrammar={this.switchEditable.bind(this)}
                 editable={this.state.editable}/>
            <p>{JSON.stringify(dotGraph)}</p>
        </span>)
    }
}

export default CfgLrkPage;
