import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleySubstitute() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Substitute:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[α, ε, ra, i, −, −, j, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p, rb, i, −, −, j, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>γ(p) a substitution node,</span>&nbsp;
            <span className={'unbreakable'}>α ∈ I</span>&nbsp;
            <span className={'unbreakable'}>l(γ, p) = l(α, ε)</span></div>
    </div>)
}
