import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgCykCompleteGeneral() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Complete General:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[A<sub>1</sub>, i<sub>1</sub>, l<sub>1</sub>]</span> ...
                <span className={'unbreakable'}>[A<sub>k</sub>, i<sub>k</sub>, l<sub>k</sub>]</span>
            </div>
            <hr/>
            <div
                className='consequence'>[A, i<sub>1</sub>, l]
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>A -> A<sub>1</sub> ... A<sub>k</sub> ∈ P, </span>
            <span className={'unbreakable'}>l = l<sub>1</sub> + ... + l<sub>k</sub>, </span>
            <span className={'unbreakable'}>i<sub>j</sub> i<sub>j-1</sub> + l<sub>j-1</sub></span></div>
    </div>)
}
