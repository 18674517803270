import React, {useRef, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faEdit} from '@fortawesome/free-solid-svg-icons';

/**
 * The input field for string to parse in the exercise. Might be editable or
 * not.
 */
export function Input(props) {

    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(props.input);
    const spanRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current && props.editable === true) {
            inputRef.current.focus();
        }
        if (spanRef.current && inputRef.current) {
            inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
        }
    }, [inputValue, props.editable]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    return (
        <div className='panel'>
            <button id='inputEditIcon' onClick={props.handleChangeInput}>
                {props.editable
                    ? <FontAwesomeIcon className={'clickable-icon'} icon={faCheck}/>
                    : <FontAwesomeIcon className={'clickable-icon'} icon={faEdit}/>
                }
                {props.editable
                    ? <span className = {'accessibility-label'} > edit input done</span>
                    : <span className = {'accessibility-label'} > edit input</span>}
            </button>
            w =
            <label className={'accessibility-label'} htmlFor="wInput">Input</label>
            <input ref={inputRef}
                   type='text'
                   name='wInput'
                   value={inputValue}
                   id='wInput'
                   onChange={handleInputChange}
                   disabled={!props.editable}/>
            <br/>
            <span ref={spanRef} id='wInputMirror'>{inputValue}</span>
        </div>
    );
}
