import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgCykBinary() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Binary:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[B, ρ<sub>B</sub>-vec]</span>,&nbsp;
                <span className={'unbreakable'}>[C, ρ<sub>C</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A, ρ<sub>A</sub>-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>A(ρ<sub>A</sub>-vec) → B(ρ<sub>B</sub>-vec) C(ρ<sub>C</sub>-vec)</span>&nbsp;is a range instantiated rule
        </div>
    </div>)
}
