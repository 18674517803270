import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyCompleteNode() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>CompleteNode:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[γ, dot, la, f, g, h, i, 0]</span>,&nbsp;
                <span className={'unbreakable'}>[γ, dot, rb, i, j, k, l, sat?]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, dot, ra, f, g ⊕ j, h ⊕ k, l, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>l(β, dot) ∈ N</span></div>
    </div>)
}
