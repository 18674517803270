import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgLeftCornerGoal extends React.Component {
    initial_values = {
        item: '',
        stack1: 'ε',
        stack2: 'ε',
        stack3: 'ε',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        CfgLeftCornerGoal.canDropItem = CfgLeftCornerGoal.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.item === '') {
                this.setState({
                    stack1: 'ε',
                    stack2: 'ε',
                    stack3: 'ε',
                });
            } else {
                this.extractAdditionalItemValues(this.state.item);
            }
        } else {
            this.extractAdditionalItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    static canDropItem(item) {
        const stack1 = item.itemForm.substring(1, item.itemForm.indexOf(','));
        const stack2 = item.itemForm.substring(item.itemForm.indexOf(',') + 1,
            item.itemForm.lastIndexOf(','));
        const stack3 = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        return stack1 === 'ε' && stack2 === 'ε' && stack3 === 'ε';
    }

    extractAdditionalItemValues(item) {
        const stack1 = item.itemForm.substring(1, item.itemForm.indexOf(','));
        const stack2 = item.itemForm.substring(item.itemForm.indexOf(',') + 1,
            item.itemForm.lastIndexOf(','));
        const stack3 = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        this.setState({stack1: stack1, stack2: stack2, stack3: stack3});
    }

    setAntecedence(item) {
        this.setState({item: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.item !== '') {
            const goal = '[' + this.state.stack1 + ',' + this.state.stack2 + ','
                + this.state.stack3 + ']';
            this.props.sendNewGoalToTask(goal);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const stack1 = this.state.stack1;
        const stack2 = this.state.stack2;
        const stack3 = this.state.stack3;
        let canDropStack1 = 0;
        let canDropStack2 = 0;
        let canDropStack3 = 0;
        if (this.state.displayItem !== '') {
            canDropStack1 = stack1 === 'ε' ? 1 : -1;
            canDropStack2 = stack2 === 'ε' ? 1 : -1;
            canDropStack3 = stack3 === 'ε' ? 1 : -1;
        }
        return <div className='deduction-rule'>
            <div className='rule-name'>Goal:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-leftcorner-goal'
                                 elementLists={[[stack1], [stack2], [stack3]]}
                                 markings={[[canDropStack1], [canDropStack2], [canDropStack3]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={CfgLeftCornerGoal.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
            </div>
        </div>
    }
}

export default CfgLeftCornerGoal;
