import React from "react";
import {requestLogRestService} from "./taskcomponents/Task";
import {USER_REQUEST} from "./constants";
import {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";


/**
 * Logs Message and Redirects to URL.
 */
class LogAndRedirect extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        let url = params.get('url');
        let message = params.get('message');
        this.state = {
            url: url,
            message: message
        }
    }

    log_and_redirect() {
        const url = this.state.url;
        const message = this.state.message;
        requestLogRestService(USER_REQUEST(this.props.userSession, message));
        document.location = url;
    }

    render() {
        return (<script>{this.log_and_redirect()}
        </script>);
    }
}

export default LogAndRedirect;
