import React from "react";


export function pushWithBreaks(stringWithNewLines, displayGrammar, suffix) {
    let stringNonBreaking = stringWithNewLines.replaceAll(" = ", "\u00A0=\u00A0");
    let lines = stringNonBreaking.split('\n');
    for (let j = 0; j < lines.length; j++) {
        displayGrammar.push(<span key={j * 2 + suffix}>{lines[j]}</span>);
        if (j !== lines.length - 1) {
            displayGrammar.push(<br key={j * 2 + 1 + suffix}/>);
        }
    }
}
