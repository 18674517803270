import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgLeftCornerChartGoal() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Goal:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[S, 0, n]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>|w| = n</span></div>
    </div>)
}
