import {CfgTopDownAxiom} from '../deductionrules/cfg/dedCfgTopDownAxiom';
import CfgTopDownScan from '../deductionrules/cfg/dedCfgTopDownScan';
import CfgTopDownPredict from '../deductionrules/cfg/dedCfgTopDownPredict';
import React from 'react';
import {CfgShiftReduceAxiom} from '../deductionrules/cfg/dedCfgShiftReduceAxiom';
import CfgShiftReduceShift from '../deductionrules/cfg/dedCfgShiftReduceShift';
import CfgShiftReduceReduce from '../deductionrules/cfg/dedCfgShiftReduceReduce';
import {CfgLeftCornerAxiom} from '../deductionrules/cfg/dedCfgLeftCornerAxiom';
import {CfgLeftCornerChartScan} from '../deductionrules/cfg/dedCfgLeftCornerChartScan';
import {CfgCykScan} from '../deductionrules/cfg/dedCfgCykScan';
import {CfgEarleyAxiom} from '../deductionrules/cfg/dedCfgEarleyAxiom';
import CfgCykComplete from '../deductionrules/cfg/dedCfgCykComplete';
import CfgLeftCornerReduce from '../deductionrules/cfg/dedCfgLeftCornerReduce';
import CfgLeftCornerMove from '../deductionrules/cfg/dedCfgLeftCornerMove';
import CfgLeftCornerRemove from '../deductionrules/cfg/dedCfgLeftCornerRemove';
import CfgEarleyPredict from '../deductionrules/cfg/dedCfgEarleyPredict';
import CfgEarleyScan from '../deductionrules/cfg/dedCfgEarleyScan';
import CfgEarleyComplete from '../deductionrules/cfg/dedCfgEarleyComplete';
import CfgTopDownGoal from "../deductionrules/cfg/dedCfgTopDownGoal";
import CfgShiftReduceGoal from "../deductionrules/cfg/dedCfgShiftReduceGoal";
import CfgEarleyGoal from "../deductionrules/cfg/dedCfgEarleyGoal";
import CfgLeftCornerGoal from "../deductionrules/cfg/dedCfgLeftCornerGoal";
import CfgCykGoal from "../deductionrules/cfg/dedCfgCykGoal";
import {CfgUngerAxiom} from "../deductionrules/cfg/dedCfgUngerAxiom";
import {CfgUngerGoal} from "../deductionrules/cfg/dedCfgUngerGoal";
import {CfgUngerScan} from "../deductionrules/cfg/dedCfgUngerScan";
import {CfgUngerPredict} from "../deductionrules/cfg/dedCfgUngerPredict";
import {CfgUngerComplete} from "../deductionrules/cfg/dedCfgUngerComplete";
import {CfgCykCompleteUnary} from "../deductionrules/cfg/dedCfgCykCompleteUnary";
import {CfgCykScanEpsilon} from "../deductionrules/cfg/dedCfgCykScanEpsilon";
import {CfgCykCompleteGeneral} from "../deductionrules/cfg/dedCfgCykCompleteGeneral";
import {CfgEarleyInitialize} from "../deductionrules/cfg/dedCfgEarleyInitialize";
import {CfgEarleyPassiveConvert} from "../deductionrules/cfg/dedCfgEarleyPassiveConvert";
import {CfgEarleyPassiveComplete} from "../deductionrules/cfg/dedCfgEarleyPassiveComplete";
import {CfgEarleyPassiveGoal} from "../deductionrules/cfg/dedCfgEarleyPassiveGoal";
import {CfgLeftCornerChartGoal} from "../deductionrules/cfg/dedCfgLeftCornerChartGoal";
import {CfgLeftCornerChartMove} from "../deductionrules/cfg/dedCfgLeftCornerChartMove";
import {CfgLeftCornerChartReduce} from "../deductionrules/cfg/dedCfgLeftCornerChartReduce";
import {CfgLeftCornerChartRemove} from "../deductionrules/cfg/dedCfgLeftCornerChartRemove";
import {PcfgAstarScan} from "../deductionrules/pcfg/dedPcfgAstarScan";
import {PcfgCykGoal} from "../deductionrules/pcfg/dedPcfgCykGoal";
import {PcfgAstarComplete} from "../deductionrules/pcfg/dedPcfgAstarComplete";
import {PcfgCykScan} from "../deductionrules/pcfg/dedPcfgCykScan";
import {PcfgCykComplete} from "../deductionrules/pcfg/dedPcfgCykComplete";
import {CfgLeftCornerChartScanEpsilon} from "../deductionrules/cfg/dedCfgLeftCornerChartScanEpsilon";
import {TagCykLexScan} from "../deductionrules/tag/dedTagCykLexScan";
import {TagCykEpsScan} from "../deductionrules/tag/dedTagCykEpsScan";
import {TagCykFootPredict} from "../deductionrules/tag/dedTagCykFootPredict";
import TagCykMoveBinary from "../deductionrules/tag/dedTagCykMoveBinary";
import {TagCykMoveUnary} from "../deductionrules/tag/dedTagCykMoveUnary";
import {TagCykNullAdjoin} from "../deductionrules/tag/dedTagCykNullAdjoin";
import {TagCykSubstitute} from "../deductionrules/tag/dedTagCykSubstitute";
import {TagCykAdjoin} from "../deductionrules/tag/dedTagCykAdjoin";
import {TagCykGoal} from "../deductionrules/tag/dedTagCykGoal";
import {TagCykMoveGeneral} from "../deductionrules/tag/dedTagCykMoveGeneral";
import {TagEarleyInitialize} from "../deductionrules/tag/dedTagEarleyInitialize";
import {TagEarleyScanTerm} from "../deductionrules/tag/dedTagEarleyScanEps";
import {TagEarleyScanEps} from "../deductionrules/tag/dedTagEarleyScanTerm";
import {TagEarleyPredictAdjoinable} from "../deductionrules/tag/dedTagEarleyPredictAdjoinable";
import {TagEarleyPredictNoAdj} from "../deductionrules/tag/dedTagEarleyPredictNoAdj";
import {TagEarleyPredictAdjoined} from "../deductionrules/tag/dedTagEarleyPredictAdjoined";
import {TagEarleyCompleteFoot} from "../deductionrules/tag/dedTagEarleyCompleteFoot";
import {TagEarleyCompleteNode} from "../deductionrules/tag/dedTagEarleyCompleteNode";
import {TagEarleyAdjoin} from "../deductionrules/tag/dedTagEarleyAdjoin";
import {TagEarleyMoveDown} from "../deductionrules/tag/dedTagEarleyMoveDown";
import {TagEarleyMoveRight} from "../deductionrules/tag/dedTagEarleyMoveRight";
import {TagEarleyMoveUp} from "../deductionrules/tag/dedTagEarleyMoveUp";
import {TagEarleyPredictSubst} from "../deductionrules/tag/dedTagEarleyPredictSubst";
import {TagEarleySubstitute} from "../deductionrules/tag/dedTagEarleySubstitute";
import {TagEarleyGoal} from "../deductionrules/tag/dedTagEarleyGoal";
import {TagEarleyPrefixValidInitialize} from "../deductionrules/tag/dedTagEarleyPrefixValidInitialize";
import {TagEarleyPrefixValidScanTerm} from "../deductionrules/tag/dedTagEarleyPrefixValidScanTerm";
import {TagEarleyPrefixValidScanEps} from "../deductionrules/tag/dedTagEarleyPrefixValidScanEps";
import {TagEarleyPrefixValidConvertRb} from "../deductionrules/tag/dedTagEarleyPrefixValidConvertRb";
import {TagEarleyPrefixValidConvertLaOne} from "../deductionrules/tag/dedTagEarleyPrefixValidConvertLaOne";
import {TagEarleyPrefixValidConvertLaTwo} from "../deductionrules/tag/dedTagEarleyPrefixValidConvertLaTwo";
import {TagEarleyPrefixValidPredictNoAdj} from "../deductionrules/tag/dedTagEarleyPrefixValidPredictNoAdj";
import {TagEarleyPrefixValidPredictAdjoinable} from "../deductionrules/tag/dedTagEarleyPrefixValidPredictAdjoinable";
import {TagEarleyPrefixValidCompleteFoot} from "../deductionrules/tag/dedTagEarleyPrefixValidCompleteFoot";
import {TagEarleyPrefixValidAdjoin} from "../deductionrules/tag/dedTagEarleyPrefixValidAdjoin";
import {TagEarleyPrefixValidCompleteNode} from "../deductionrules/tag/dedTagEarleyPrefixValidCompleteNode";
import {TagEarleyPrefixValidPredictAdjoined} from "../deductionrules/tag/dedTagEarleyPrefixValidPredictAdjoined";
import {TagEarleyPrefixValidMoveRight} from "../deductionrules/tag/dedTagEarleyPrefixValidMoveRight";
import {TagEarleyPrefixValidMoveDown} from "../deductionrules/tag/dedTagEarleyPrefixValidMoveDown";
import {TagEarleyPrefixValidPredictSubstituted} from "../deductionrules/tag/dedTagEarleyPrefixValidPredictSubstituted";
import {TagEarleyPrefixValidMoveUp} from "../deductionrules/tag/dedTagEarleyPrefixValidMoveUp";
import {TagEarleyPrefixValidSubstitute} from "../deductionrules/tag/dedTagEarleyPrefixValidSubstitute";
import {TagEarleyPrefixValidGoal} from "../deductionrules/tag/dedTagEarleyPrefixValidGoal";
import {SrcgCykScan} from "../deductionrules/srcg/dedSrcgCykScan";
import {SrcgCykUnary} from "../deductionrules/srcg/dedSrcgCykUnary";
import {SrcgCykBinary} from "../deductionrules/srcg/dedSrcgCykBinary";
import {SrcgCykGoal} from "../deductionrules/srcg/dedSrcgCykGoal";
import {SrcgCykGeneralScan} from "../deductionrules/srcg/dedSrcgCykGeneralScan";
import {SrcgCykGeneralComplete} from "../deductionrules/srcg/dedSrcgCykGeneralComplete";
import {SrcgEarleyInitialize} from "../deductionrules/srcg/dedSrcgEarleyInitialize";
import {SrcgEarleyScan} from "../deductionrules/srcg/dedSrcgEarleyScan";
import {SrcgEarleyPredict} from "../deductionrules/srcg/dedSrcgEarleyPredict";
import {SrcgEarleySuspend} from "../deductionrules/srcg/dedSrcgEarleySuspend";
import {SrcgEarleyConvert} from "../deductionrules/srcg/dedSrcgEarleyConvert";
import {SrcgEarleyResume} from "../deductionrules/srcg/dedSrcgEarleyResume";
import {SrcgEarleyGoal} from "../deductionrules/srcg/dedSrcgEarleyGoal";

/**
 * Displays the deduction rules for the current task.
 */
export function DeductionRules(props) {
    switch (props.algorithm) {
        case 'cfg-topdown':
            return (
                <div className='panel' id='rules-container'>
                    <CfgTopDownAxiom startSymbol={props.startSymbol}/><br/>
                    <CfgTopDownScan
                        sendNewItemToTask={props.sendNewItemToTask}
                        isInputSymbol={props.isInputSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgTopDownPredict
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgTopDownGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-shiftreduce':
            return (
                <div className='panel' id='rules-container'>
                    <CfgShiftReduceAxiom/><br/>
                    <CfgShiftReduceShift
                        sendNewItemToTask={props.sendNewItemToTask}
                        hasInputSymbol={props.hasInputSymbol}
                        getInputSymbol={props.getInputSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgShiftReduceReduce
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgShiftReduceGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-leftcorner':
            return (
                <div className='panel' id='rules-container'>
                    <CfgLeftCornerAxiom startSymbol={props.startSymbol}/><br/>
                    <CfgLeftCornerReduce
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgLeftCornerMove
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgLeftCornerRemove
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgLeftCornerGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-leftcorner-chart':
            return (
                <div className='panel' id='rules-container'>
                    <CfgLeftCornerChartScan/><br/>
                    <CfgLeftCornerChartScanEpsilon/><br/>
                    <CfgLeftCornerChartReduce/><br/>
                    <CfgLeftCornerChartRemove/><br/>
                    <CfgLeftCornerChartMove/><br/>
                    <CfgLeftCornerChartGoal/><br/>
                </div>
            );
        case 'cfg-cyk':
            return (
                <div className='panel' id='rules-container'>
                    <CfgCykScan/><br/>
                    <CfgCykComplete
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgCykGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-cyk-extended':
            return (
                <div className='panel' id='rules-container'>
                    <CfgCykScan/><br/>
                    <CfgCykComplete
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgCykCompleteUnary/><br/>
                    <CfgCykGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-cyk-general':
            return (
                <div className='panel' id='rules-container'>
                    <CfgCykScan/><br/>
                    <CfgCykScanEpsilon/><br/>
                    <CfgCykCompleteGeneral/><br/>
                    <CfgCykGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-earley':
            return (
                <div className='panel' id='rules-container'>
                    <CfgEarleyAxiom startSymbol={props.startSymbol}/><br/>
                    <CfgEarleyPredict
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyScan
                        sendNewItemToTask={props.sendNewItemToTask}
                        isInputSymbol={props.isInputSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyComplete
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-earley-bottomup':
            return (
                <div className='panel' id='rules-container'>
                    <CfgEarleyInitialize/><br/>
                    <CfgEarleyScan
                        sendNewItemToTask={props.sendNewItemToTask}
                        isInputSymbol={props.isInputSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyComplete
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/>
                </div>
            );
        case 'cfg-earley-passive':
            // the same as Earley plus:
            return (
                <div className='panel' id='rules-container'>
                    <CfgEarleyAxiom startSymbol={props.startSymbol}/><br/>
                    <CfgEarleyPredict
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyScan
                        sendNewItemToTask={props.sendNewItemToTask}
                        isInputSymbol={props.isInputSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyPassiveConvert
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyPassiveComplete
                        sendNewItemToTask={props.sendNewItemToTask}
                        isNonterminal={props.isNonterminal}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <CfgEarleyPassiveGoal
                        sendNewGoalToTask={props.sendNewGoalToTask}
                        inputLength={props.inputLength}
                        startSymbol={props.startSymbol}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                </div>
            );
        case 'cfg-unger':
            return (
                <div className='panel' id='rules-container'>
                    <CfgUngerAxiom /><br/>
                    <CfgUngerScan /><br/>
                    <CfgUngerPredict /><br/>
                    <CfgUngerComplete /><br/>
                    <CfgUngerGoal /><br/>
                </div>
            );
        case 'pcfg-astar':
            return (
                <div className='panel' id='rules-container'>
                    <PcfgAstarScan/><br/>
                    <PcfgAstarComplete/><br/>
                    <PcfgCykGoal/><br/>
                </div>
            );
        case 'pcfg-cyk':
            return (
                <div className='panel' id='rules-container'>
                    <PcfgCykScan/><br/>
                    <PcfgCykComplete/><br/>
                    <PcfgCykGoal/><br/>
                </div>
            );
        case 'tag-cyk-extended':
            return (
                <div className='panel' id='rules-container'>
                    <TagCykLexScan/><br/>
                    <TagCykEpsScan/><br/>
                    <TagCykFootPredict/><br/>
                    <TagCykMoveUnary/><br/>
                    <TagCykMoveBinary
                        sendNewItemToTask={props.sendNewItemToTask}
                        userSession={props.userSession}
                        maxItem={props.maxItem}/><br/>
                    <TagCykNullAdjoin/><br/>
                    <TagCykSubstitute/><br/>
                    <TagCykAdjoin/><br/>
                    <TagCykGoal/><br/>
                </div>
            );
        case 'tag-cyk-general':
            return (
                <div className='panel' id='rules-container'>
                    <TagCykLexScan/><br/>
                    <TagCykEpsScan/><br/>
                    <TagCykFootPredict/><br/>
                    <TagCykMoveGeneral/><br/>
                    <TagCykNullAdjoin/><br/>
                    <TagCykSubstitute/><br/>
                    <TagCykAdjoin/><br/>
                    <TagCykGoal/><br/>
                </div>
            );
        case 'tag-earley':
            return (
                <div className='panel' id='rules-container'>
                    <TagEarleyInitialize/><br/>
                    <TagEarleyScanTerm/><br/>
                    <TagEarleyScanEps/><br/>
                    <TagEarleyPredictAdjoinable/><br/>
                    <TagEarleyPredictNoAdj/><br/>
                    <TagEarleyPredictAdjoined/><br/>
                    <TagEarleyCompleteFoot/><br/>
                    <TagEarleyCompleteNode/><br/>
                    <TagEarleyAdjoin/><br/>
                    <TagEarleyMoveDown/><br/>
                    <TagEarleyMoveRight/><br/>
                    <TagEarleyMoveUp/><br/>
                    <TagEarleyPredictSubst/><br/>
                    <TagEarleySubstitute/><br/>
                    <TagEarleyGoal/><br/>
                </div>
            );
        case 'tag-earley-prefixvalid':
            return (
                <div className='panel' id='rules-container'>
                    <TagEarleyPrefixValidInitialize/><br/>
                    <TagEarleyPrefixValidScanTerm/><br/>
                    <TagEarleyPrefixValidScanEps/><br/>
                    <TagEarleyPrefixValidConvertRb/><br/>
                    <TagEarleyPrefixValidConvertLaOne/><br/>
                    <TagEarleyPrefixValidConvertLaTwo/><br/>
                    <TagEarleyPrefixValidPredictNoAdj/><br/>
                    <TagEarleyPrefixValidPredictAdjoinable/><br/>
                    <TagEarleyPrefixValidPredictAdjoined/><br/>
                    <TagEarleyPrefixValidCompleteFoot/><br/>
                    <TagEarleyPrefixValidAdjoin/><br/>
                    <TagEarleyPrefixValidCompleteNode/><br/>
                    <TagEarleyPrefixValidMoveDown/><br/>
                    <TagEarleyPrefixValidMoveRight/><br/>
                    <TagEarleyPrefixValidMoveUp/><br/>
                    <TagEarleyPrefixValidPredictSubstituted/><br/>
                    <TagEarleyPrefixValidSubstitute/><br/>
                    <TagEarleyPrefixValidGoal/><br/>
                </div>
            );
        case 'srcg-cyk-extended':
            return (
                <div className='panel' id='rules-container'>
                    <SrcgCykScan/><br/>
                    <SrcgCykUnary/><br/>
                    <SrcgCykBinary/><br/>
                    <SrcgCykGoal/><br/>
                </div>
            );
        case 'srcg-cyk-general':
            return (
                <div className='panel' id='rules-container'>
                    <SrcgCykGeneralScan/><br/>
                    <SrcgCykGeneralComplete/><br/>
                    <SrcgCykGoal/><br/>
                </div>
            );
        case 'srcg-earley':
            return (
                <div className='panel' id='rules-container'>
                    <SrcgEarleyInitialize/><br/>
                    <SrcgEarleyScan/><br/>
                    <SrcgEarleyPredict/><br/>
                    <SrcgEarleySuspend/><br/>
                    <SrcgEarleyConvert/><br/>
                    <SrcgEarleyResume/><br/>
                    <SrcgEarleyGoal/><br/>
                </div>
            );
        default:
            return (
                <div className='error-message panel'>Unknown algorithm. Cannot
                    display deduction rules.</div>);
    }
}
