import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown,
    faChalkboardTeacher,
    faCheck,
    faFastForward,
    faQuestionCircle,
    faSadTear,
    faStepForward,
    faSwimmingPool,
    faTable,
    faTree, faVideo
} from '@fortawesome/free-solid-svg-icons';
import {requestLogRestService, shuffle} from './Task';
import {
    BACKEND_HOST,
    BACKEND_PATH,
    BACKEND_PORT, BACKEND_PROTOCOL,
    USER_REQUEST
} from "../constants";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import {featureData, iconData} from "./feature-map";

class DifficultyEntry extends Component {
    requestTaskByLevel = () =>  {
        const algorithmValue = this.props.algorithm;
        const formalismValue = this.props.formalism;
        const difficultyValue = this.props.difficultyValue;

        this.props.requestTask(formalismValue, algorithmValue, difficultyValue);
    };

    render() {
        return (
            <button onClick={this.requestTaskByLevel}
                    className='menu-sub'
                    onKeyDown={this.props.onKeyDown}
                    tabIndex="0">
                <FontAwesomeIcon icon={iconData[this.props.difficultyLabel]}/> {this.props.difficultyLabel}
            </button>
        );
    }
}

class VideoTutorial extends Component {
    render() {
        const link_en = featureData[this.props.algorithm]['tutorial']['en'];
        const link_de = featureData[this.props.algorithm]['tutorial']['de'];
        const log_url_en = '/log?message=tutorial-en&url=' + link_en;
        const log_url_de = '/log?message=tutorial-de&url=' + link_de;
        return (
            <div className='menu-sub'>
                <a href={log_url_en} target={'_blank'} rel={'noopener noreferrer'}
                   onKeyDown={this.props.onKeyDown}
                   tabIndex="0">
                    <FontAwesomeIcon icon={faVideo}/> Tutorial&nbsp;
                    <img src='./uk_flag.png' width='15px' alt='uk flag'/></a>&nbsp;
                <a href={log_url_de} target={'_blank'} rel={'noopener noreferrer'}
                   onKeyDown={this.props.onKeyDown}
                   tabIndex="0">
                    <img src='./de_flag.png' width='15px' alt='de flag'/></a>
            </div>
        );
    }
}

/**
 * Displays the menu where to request new exercises and where to get help.
 */
class DeductionMenu extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.requestTask = this.requestTask.bind(this);
        this.requestPoolTask = this.requestPoolTask.bind(this);
        this.solveNext = this.solveNext.bind(this);
        this.solveAll = this.solveAll.bind(this);
        this.showInstructions = this.showInstructions.bind(this);
    }

    showInstructions() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'instructions'));
        this.props.showInstructions();
    }

    solveNext() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'solve-next'));
        this.props.solveNext();
    }

    solveAll() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'solve-all'));
        this.props.solveAll();
    }

    checkSolution() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'check-solution'));
        this.props.checkSolution();
    }

    showGoalTrees() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'goal-trees'));
        this.props.showGoalTrees();
    }

    showTable() {
        requestLogRestService(USER_REQUEST(this.props.userSession, 'table'));
        this.props.showTable();
    }

    requestTask(formalismValue, algorithmValue, difficultyValue) {
        requestLogRestService(USER_REQUEST(this.props.userSession,
            'task-difficulty-' + difficultyValue));
        this.props.setLoading(true);
        DeductionMenu.requestTaskGeneratorRestService(formalismValue, algorithmValue,
            difficultyValue).then((result) => {
            this.props.handleExerciseRequest(result);
        }).catch(() => {
            this.props.setLoading(false);
            this.props.setErrorMessage('An error occured when requesting the '
              + 'task generator service.\n\n'
              + 'Try again by refreshing the page.\nIf the problem persists, '
              + 'check your grammar format.\n'
              + 'If the problem still persists, check /status and send mail to '
              + 'Samya if the backend is offline.');
        });
    }

    requestPoolTask() {
        const taskKey = this.props.algorithm;
        requestLogRestService(USER_REQUEST(this.props.userSession, 'pool-task'));
        this.props.setLoading(true);
        const urls = this.getTasksForAlgorithm(taskKey);
        const {cookies} = this.props;
        let randomList = cookies.get('task-' + taskKey);
        let taskIndex = cookies.get('index-' + taskKey);
        if (randomList == null || randomList.length <= taskIndex) {
            randomList = [];
            for (let i = 0; i < urls.length; i++) {
                randomList.push(i);
            }
            randomList = shuffle(randomList);
            taskIndex = 0;
            cookies.set('task-' + taskKey, randomList, {path: '/'});
            cookies.set('index-' + taskKey, taskIndex, {path: '/'});
        }
        const url = urls[randomList[taskIndex]];
        taskIndex++;
        cookies.set('index-' + taskKey, taskIndex, {path: '/'});
        this.props.handleExerciseRequest(url);
    }

    getTasksForAlgorithm(algorithm) {
        const algorithmUnderscore = algorithm.replace(/-/g, "_");
        if (featureData[algorithmUnderscore]['pool'] != null) {
            return featureData[algorithmUnderscore]['pool'];
        }
        return [];
    }

    static requestTaskGeneratorRestService(formalism, algorithm, difficulty) {
        const random_boolean = Math.random() < 0.8;
        const url = BACKEND_PROTOCOL + '//'
            + BACKEND_HOST + ':'
            + BACKEND_PORT + '/'
            + BACKEND_PATH + '/rest/generator?formalism=' +
            formalism +
            '&algorithm=' + algorithm + '&difficulty=' + difficulty + '&wordInLanguage=' + random_boolean;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(this.responseText);
            };
            xhr.onerror = reject;
            xhr.open('GET', url);
            xhr.send();
        });
    }

    handleKeyDown(event) {
        const key = event.key;
        const currentFocus = document.activeElement;
        const focusableElements = Array.from(document.querySelectorAll("button[tabindex='0'], a[tabindex='0']"));

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            event.preventDefault();
            const currentIndex = focusableElements.indexOf(currentFocus);
            const direction = key === 'ArrowDown' ? 1 : -1;
            const newIndex = (currentIndex + direction + focusableElements.length) % focusableElements.length;
            focusableElements[newIndex].focus();
        } else if (key === 'ArrowRight' || key === 'ArrowLeft') {
            event.preventDefault();
            const mainMenuButtons = Array.from(document.querySelectorAll('.menu-main'));

            let currentMainMenuButton;
            if (currentFocus.classList.contains('menu-main')) {
                currentMainMenuButton = currentFocus;
            } else {
                currentMainMenuButton = currentFocus.closest('.menu').querySelector('.menu-main');
            }

            const currentIndex = mainMenuButtons.indexOf(currentMainMenuButton);
            const direction = key === 'ArrowRight' ? 1 : -1;
            const newIndex = (currentIndex + direction + mainMenuButtons.length) % mainMenuButtons.length;
            mainMenuButtons[newIndex].focus();
        }
    }

    render() {
        const algorithmValue = this.props.algorithm;
        const algorithmUnderscore = algorithmValue.replace(/-/g, "_");
        const formalismValue = this.props.formalism;
        const self = this;
        return (
            <div className='menu-root panel'>
                <div className='menu'>
                    <button className='menu-main'
                            onKeyDown={this.handleKeyDown.bind(this)}
                            tabIndex="0">
                        <FontAwesomeIcon icon={faFile}/> New
                        <FontAwesomeIcon icon={faAngleDown}/>
                    </button>
                    <ul>
                        {featureData[algorithmUnderscore] != null &&
                        featureData[algorithmUnderscore]['pool']  ?
                            <li>
                                <button onClick={this.requestPoolTask}
                                        className='menu-sub'
                                        onKeyDown={this.handleKeyDown.bind(this)}
                                        tabIndex="0">
                                    <FontAwesomeIcon icon={faSwimmingPool}/> Pool
                                </button>
                            </li> : ''}
                        {featureData[algorithmUnderscore] != null ?
                            Object.keys(featureData[algorithmUnderscore]['difficulties']).map(function (key, index) {
                            return <li key={index}>
                                <DifficultyEntry
                                    formalism={formalismValue}
                                    algorithm={algorithmValue}
                                    difficultyLabel={key}
                                    difficultyValue={featureData[algorithmUnderscore]['difficulties'][key]}
                                    requestTask={self.requestTask}
                                    onKeyDown={self.handleKeyDown.bind(self)}/>
                            </li>
                        } )
                            : <li>
                                <button className='menu-sub'
                                        onKeyDown={this.handleKeyDown.bind(this)}
                                        tabIndex="0">
                                    <FontAwesomeIcon icon={faSadTear}/> Sorry
                                </button>
                            </li>
                        }
                    </ul>
                </div>
                <div className='menu'>
                    <button className='menu-main'
                            onKeyDown={this.handleKeyDown.bind(this)}
                            tabIndex="0">
                        <FontAwesomeIcon icon={faQuestionCircle}/> Help
                        <FontAwesomeIcon icon={faAngleDown}/>
                    </button>
                    <ul>
                        <li>
                            <button className='menu-sub'
                                    onClick={this.showInstructions.bind(this)}
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                    tabIndex="0">
                                <FontAwesomeIcon
                                    icon={faChalkboardTeacher}/> Instructions
                            </button>
                        </li>
                        <li>
                            <button className='menu-sub'
                                    onClick={this.solveNext.bind(this)}
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                    tabIndex="0">
                                <FontAwesomeIcon icon={faStepForward}/> Solve Step
                            </button>
                        </li>
                        <li>
                            <button className='menu-sub'
                                    onClick={this.solveAll.bind(this)}
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                    tabIndex="0">
                                <FontAwesomeIcon icon={faFastForward}/> Solve Task
                            </button>
                        </li>
                        <li>
                            <button className='menu-sub'
                                    onClick={this.showGoalTrees.bind(this)}
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                    tabIndex="0">
                                <FontAwesomeIcon icon={faTree}/> Show Tree
                            </button>
                        </li>
                        {featureData[algorithmUnderscore] != null &&
                        featureData[algorithmUnderscore]['parseTable']  ?
                            <li>
                                <button className='menu-sub'
                                        onClick={this.showTable.bind(this)}
                                        onKeyDown={this.handleKeyDown.bind(this)}
                                        tabIndex="0">
                                    <FontAwesomeIcon icon={faTable}/> Show Table
                                </button>
                            </li>
                            : ''
                        }
                        {featureData[algorithmUnderscore] != null &&
                        featureData[algorithmUnderscore]['tutorial']  ?
                            <li>
                                <VideoTutorial algorithm={algorithmUnderscore}
                                               userSession={this.props.userSession}
                                               onKeyDown={this.handleKeyDown.bind(this)}/>
                            </li>
                            : ''
                        }
                    </ul>
                </div>
                <button className='menu-main'
                        onClick={this.checkSolution.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        tabIndex="0">
                    <FontAwesomeIcon icon={faCheck}/>Check
                </button>
            </div>
        );
    }
}
export default withCookies(DeductionMenu);
