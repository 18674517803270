import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgTopDownScan extends React.Component {
    initial_values = {
        antecedence: '',
        i: 'i',
        alpha: 'α',
        a: 'a',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    i: 'i',
                    alpha: 'α',
                    a: 'a'
                });
            } else {
                this.extractAdditionalItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const space = item.itemForm.indexOf(' ');
        const stackHeadEnd = space > 0 ? space : item.itemForm.lastIndexOf(',');
        const a = item.itemForm.substring(1, stackHeadEnd);
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        return this.props.isInputSymbol(i, a);
    }

    extractAdditionalItemValues(item) {
        const space = item.itemForm.indexOf(' ');
        const stackHeadEnd = space > 0 ? space : item.itemForm.lastIndexOf(',');
        const a = item.itemForm.substring(1, stackHeadEnd);
        const alpha = stackHeadEnd === item.itemForm.lastIndexOf(',') ? 'ε'
            : item.itemForm.substring(stackHeadEnd + 1, item.itemForm.lastIndexOf(','));
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        this.setState({a: a, alpha: alpha, i: i});
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[';
            newItem = newItem + this.state.alpha + ','
                + (parseInt(this.state.i) + 1) + ']';
            this.props.sendNewItemToTask(newItem, 'scan ' + this.state.a,
                [this.state.antecedence.id]);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const alpha = this.state.alpha;
        const i = this.state.i;
        const a = this.state.a;
        const canDropItem = this.state.canDropItem;
        const hovering = this.state.displayItem === '' ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Scan:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-topdown-scan'
                                 elementLists={[[a, alpha], [i]]}
                                 markings={[[canDropItem, hovering], [hovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[alpha], [i, "+ 1"]]}
                        markings={[[hovering], [hovering, 0]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <span className={'unbreakable'}>w<sub>{i}+1</sub> =&nbsp;
                    <span
                        className={canDropItem === 1 ? 'can-drop' : canDropItem === -1 ? 'cannot-drop' : ''}>{a}</span>
                </span>
            </div>
        </div>
    }
}

export default CfgTopDownScan;
