import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidCompleteNode() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>CompleteNode:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[γ, p, la, i<sub>γ</sub>, f, g, h, i, 0]</span>,nbsp;
                <span className={'unbreakable'}>[γ, p, rb, ~, i, j, k, l, adj]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p, ra, i<sub>γ</sub>, f, g ⊕ j, h ⊕ k, l, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>l(β, p) ∈ N</span></div>
    </div>)
}
