import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyResume() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Resume:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[A(φ-vec) → ... B(ξ-vec) ... , pos, &lt;i, j>, ρ<sub>A</sub>-vec]</span>,&nbsp;
                <span className={'unbreakable'}>[B(ψ-vec) → Ψ-vec, pos', &lt;k − 1, l>, ρ<sub>B</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[B(ψ-vec) → Ψ-vec, pos, &lt;k, 0>, ρ<sub>B</sub>-vec]</span>
            </div>
        </div>
    </div>)
}
