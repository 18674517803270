import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleySuspend() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Suspend:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[B(ψ-vec) → Ψ-vec, pos', &lt;i, j>, ρ<sub>B</sub>-vec]</span>,&nbsp;
                <span className={'unbreakable'}>[A(φ-vec) → ... B(ξ-vec) ... , pos, &lt;k, l>, ρ<sub>A</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A(φ-vec) → ... B(ξ-vec) ... , pos', &lt;k, l + 1>, ρ-vec]</span>
            </div>
        </div>
    </div>)
}
