import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgCykScanEpsilon() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Scan ε:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A, i, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>A -> ε ∈ P, </span>
            <span className={'unbreakable'}>i ∈ [0...n]]</span></div>
    </div>)
}
