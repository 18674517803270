import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidScanTerm() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>ScanTerm:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[γ, p, la, i<sub>γ</sub>, i, j, k, l, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p, ra, i<sub>0</sub>, i, j, k, l + 1, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>l(γ, p<sub>γ</sub> · p) = w<sub>l+1</sub></span></div>
    </div>)
}
