import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyPredict() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Predict:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[A(φ-vec) → ... B(X, ...) ... , pos, &lt;i, j>, ρ<sub>A</sub>-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[B(ψ-vec) → Ψ-vec, pos, &lt;1, 0>, ρ<sub>init</sub>-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>where φ-vec(i, j + 1) = X</span>,&nbsp;
            <span className={'unbreakable'}>B(ψ-vec) → Ψ-vec ∈ P</span>
        </div>
    </div>)
}
