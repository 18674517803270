import * as React from "react";
import * as Immutable from "immutable";

export class ColoredLine {
    constructor(color, line) {
        this.color = color;
        this.line = line
    }
}

class DrawArea extends React.Component {
    constructor() {
        super();

        this.state = {
            isDrawing: false
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseDown(mouseEvent) {
        if (mouseEvent.button !== 0) {
            return;
        }
        this.setState({
            isDrawing: true
        });
        if (this.props.erasingActive) {
            return;
        }
        const point = this.relativeCoordinatesForEvent(mouseEvent);
        this.props.addLine(point);
    }

    handleMouseMove(mouseEvent) {
        if (!this.state.isDrawing) {
            return;
        }

        const point = this.relativeCoordinatesForEvent(mouseEvent);
        if (this.props.erasingActive) {
            this.props.eraseAtPoint(point);
        } else {
            this.props.addPointToLastLine(point);
        }
    }

    handleMouseUp() {
        this.setState({ isDrawing: false });
    }

    relativeCoordinatesForEvent(mouseEvent) {
        const boundingRect = this.refs.drawArea.getBoundingClientRect();
        return new Immutable.Map({
            x: mouseEvent.clientX - boundingRect.left,
            y: mouseEvent.clientY - boundingRect.top,
        });
    }

    render() {
        return (
            <div
                className="drawArea"
                ref="drawArea"
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}>
                <Drawing lines={this.props.lines} />
            </div>
        );
    }
}

function Drawing({ lines }) {
    return (
        <svg className="drawing">
            <defs>
                <pattern id="smallGrid" width="18" height="18" patternUnits="userSpaceOnUse">
                    <path d="M 0 8.5 L 18 8.5 M 8.5 0 L 8.5 18" strokeDasharray="1, 1" fill="none" stroke="#999" opacity="0.5" strokeWidth="1"/>
                </pattern>
                <pattern id="grid" width="18" height="18" patternUnits="userSpaceOnUse">
                    <rect width="18" height="18" fill="url(#smallGrid)"/>
                </pattern>
            </defs>
            {lines.map((line, index) => (
                <DrawingLine key={index} line={line} />
            ))}
            <rect width="100%" height="100%" fill="url(#grid)"/>
        </svg>
    );
}

function DrawingLine({ line }) {
    const drawLine = line.line == null ? [] : line.line;
    const pathData = "M " +
        drawLine
            .map(p => {
                return `${p.get('x')} ${p.get('y')}`;
            })
            .join(" L ");

    const divStyle = {
        stroke: line.color
    };

    return <path className="path" d={pathData} style={divStyle} />;
}

export default DrawArea;
