import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidConvertLaTwo() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Convert-la II:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[γ, p, la, i<sub>γ</sub>, i, j, k, l, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p, la, ~, ~, ~, ~, l, 0]</span>
            </div>
        </div>
    </div>)
}
