import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykFootPredict() {
    return (
        <div className='deduction-rule'>
            <div className='rule-name'>Foot-Predict:</div>
            <div className='middle-rule-part'>
                <hr/>
                <div
                    className='consequence'>[β, ρ<sub>⊤</sub>, i, i, j, j]
                </div>
            </div>
            <div className='side-condition'><span className={'unbreakable'}>β ∈ A,</span>&nbsp;
                <span className={'unbreakable'}>ρ foot node address in β,</span>&nbsp;
                <span className={'unbreakable'}>i ≤ j</span>
            </div>
        </div>)
}
