import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidPredictAdjoinable() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>PredictAdjoinable:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[γ, p, la, ~, ~, ~, ~, l, 0]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[β, ε, la, l, l, -, -, l, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>β ∈ f<sub>SA</sub>(γ, p)</span></div>
    </div>)
}
