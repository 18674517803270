import Item from './Item';
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlagCheckered} from "@fortawesome/free-solid-svg-icons";

/**
 * Displays the parsing trace table with dragable items.
 */
export class DeductionTable extends Component<{}> {

    render() {
        function makeUnbreakable(fElementList) {
            if (fElementList == null) {
                return [];
            }
            let newList = [];
            let i = 0;
            for (let element of fElementList) {
                if (newList.length > 0) newList.push(', ');
                newList.push(<span key={i} className="unbreakable">{element}</span>);
                i++;
            }
            return newList;
        }

        function displayBackPointerSet(elementList) {
            if (elementList == null) {
                return [];
            }
            let newList = [];
            let i = 0;
            for (let element of elementList) {
                if (newList.length > 0) newList.push(', ');
                newList.push(<span key={i}>{element}</span>);
                i++;
            }
            return newList;
        }

        function displayBackPointerSets(fElement, rowHighlight) {
            if (fElement == null) {
                return [];
            }
            let newList = [];
            let i = 0;
            for (let elementList of fElement) {
                const bpHighlight = rowHighlight && rowHighlight.has(i);
                if (newList.length > 0) newList.push(', ');
                newList.push(
                    <span key={i} className={bpHighlight ? 'highlight' : ''}>
                        &#123;{displayBackPointerSet(elementList)}&#125;
                    </span>);
                i++;
            }
            return newList;
        }

        return (
            <table className='panel' id='deduction-table'>
                <tbody>
                <tr>
                    <th>Id</th>
                    <th>Item</th>
                    <th>Rule</th>
                    <th>Backpointers</th>
                </tr>
                {this.props.tableContent.map(f => {
                    const rowHighlight = this.props.highlightedRows[f[0]];
                    return (
                        <tr key={f[0]} className={rowHighlight && rowHighlight.size === 0 ? 'highlight' : ''}>
                            <td>{f[0]}</td>
                            <td><Item id={f[0]} itemForm={f[1]} userSession={this.props.userSession}
                                      displayProbabilities={this.props.displayProbabilities}
                                      probability={f[4]}/>
                                {this.props.displayGoals.indexOf(f[0]) >= 0 ?
                                    <FontAwesomeIcon icon={faFlagCheckered}/> : ''}
                            </td>
                            <td>{makeUnbreakable(f[2])}</td>
                            <td>{displayBackPointerSets(f[3], rowHighlight)}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
}
