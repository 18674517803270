import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgCykUnary() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Unary:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[B, ρ-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A, ρ-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>A(α-vec) → B(α-vec) ∈ P</span>
        </div>
    </div>)
}
