import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import SideConditionRule from '../../taskcomponents/SideConditionRule.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from "../../taskcomponents/Task";
import Consequence from "../../taskcomponents/Consequence";
import {USER_CLEAR} from "../../constants";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgEarleyPredict extends React.Component {
    initial_values = {
        antecedence: '',
        i: '',
        displayI: 'i',
        j: '',
        displayJ: 'j',
        alpha: '',
        displayAlpha: 'α',
        b: '',
        displayB: 'B',
        beta: '',
        displayBeta: 'β',
        a: '',
        displayA: 'A',
        gamma: '',
        displayGamma: 'γ',
        prodRule: '',
        canDropItem: 0,
        canDropRule: 0,
        displayItem: '',
        displayRule: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.extractAdditionalRuleValues = this.extractAdditionalRuleValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence(item) {
        this.setState({
            antecedence: item,
            eraseDisabled: false
        });
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    extractAdditionalItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const a = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            a: a,
            alpha: alpha,
            i: i,
            j: j,
            b: b,
            beta: beta,
            canDropItem: 0,
            canDropRule: 0,
            displayItem: ''
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        if (ruleStack.indexOf('->') === -1) {
            this.setState({
                displayA: ruleStack,
                displayAlpha: '',
                displayI: i,
                displayJ: j,
                displayB: '',
                displayBeta: ''
            });
            return;
        }
        const ruleSplit = ruleStack.split('->');
        const a = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            displayA: a,
            displayAlpha: alpha,
            displayI: i,
            displayJ: j,
            displayB: b,
            displayBeta: beta
        });
    }

    /**
     * Set rule as production rule in state and extract different other values
     * from it to be set in state.
     */
    setProdRule(rule) {
        this.setState({prodRule: rule, eraseDisabled: false});
        this.extractAdditionalRuleValues(rule);
        this.checkNewItem();
    }

    extractAdditionalRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const b = ruleSplit[0].trim();
        const gamma = ruleSplit[1].trim();
        this.setState({b: b, gamma: gamma});
    }

    extractAdditionalDisplayRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const b = ruleSplit[0].trim();
        const gamma = ruleSplit[1].trim();
        this.setState({displayB: b, displayGamma: gamma});
    }

    setDisplayRule(rule) {
        this.setState({displayRule: rule});
        if (rule === '') {
            if (this.state.prodRule === '') {
                if (this.state.antecedence === '') {
                    this.setState({
                        displayA: 'A',
                        displayGamma: 'γ',
                        displayB: 'B'
                    });
                } else {
                    this.setState({displayGamma: 'γ'});
                    this.extractAdditionalDisplayItemValues(this.state.antecedence);
                }
            } else {
                this.extractAdditionalDisplayRuleValues(this.state.prodRule);
            }
        } else {
            this.extractAdditionalDisplayRuleValues(rule);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const rule = item.itemForm.substring(1, firstComma);
        if (rule.indexOf('->') === -1) {
            return false;
        }
        const ruleSplit = rule.split('->');
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const spacePos = rhs.indexOf(' ', dotPos + 1);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        return dotPos !== rhs.length - 1 && (this.state.prodRule === '' || this.state.b === b) &&
            this.props.isNonterminal(b);
    }

    /**
     * Returns true if rule can be dropped on the side condition component.
     */
    canDropRule(rule) {
        if (this.state.antecedence === '') {
            return true;
        }
        const ruleSplit = rule.rule.split('->');
        const b = ruleSplit[0].trim();
        return b === this.state.b;
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                if (this.state.prodRule === '') {
                    this.setState({
                        displayI: 'i',
                        displayJ: 'j',
                        displayAlpha: 'α',
                        displayB: 'B',
                        displayBeta: 'β',
                        displayA: 'A'
                    });
                } else {
                    this.setState({
                        displayI: 'i',
                        displayJ: 'j',
                        displayAlpha: 'α',
                        displayBeta: 'β',
                        displayA: 'A'
                    });
                    this.extractAdditionalDisplayRuleValues(this.state.prodRule);
                }
            } else {
                this.extractAdditionalDisplayItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropRule(value) {
        this.setState({
            canDropRule: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence !== '' && this.state.prodRule !== '') {
            const newItem = '[' + this.state.b + ' -> •'
                + (this.state.gamma === 'ε' ? '' : this.state.gamma) + ','
                + this.state.j + ',' + this.state.j + ']';
            if (this.props.sendNewItemToTask(newItem, 'predict '
                + this.state.prodRule.rule, [this.state.antecedence.id])) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession, 'cfg-earley-predict'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const a = this.state.displayA;
        const alpha = this.state.displayAlpha;
        const b = this.state.displayB;
        const beta = this.state.displayBeta;
        const i = this.state.displayI;
        const j = this.state.displayJ;
        const gamma = this.state.displayGamma;
        const canDropItem = this.state.canDropItem;
        const canDropRule = this.state.canDropRule;
        const canDropRuleOrItem = canDropItem !== 0 ? canDropItem : canDropRule;
        const canDropB = canDropRule !== 0 ? canDropRule : canDropItem;
        const ruleHovering = this.state.displayRule === '' ? 0 : 1;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                        onClick={this.clearWithLog.bind(this)}>
                    <FontAwesomeIcon className={"clickable-icon"}
                                     icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear earley predict</span>
                </button>
                Predict:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-earley-predict'
                                 elementLists={[[a, '->', alpha, '•' + b,
                                     beta], [i], [j]]}
                                 markings={[[itemHovering, 0, itemHovering,
                                     canDropB, itemHovering],
                                     [itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[b, "->", "•" + gamma], [i], [j]]}
                        markings={[[canDropRuleOrItem, 0, ruleHovering], [itemHovering], [itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <SideConditionRule
                    displayLhs={b} displayRhs={gamma}
                    setProdRule={this.setProdRule.bind(this)}
                    setDisplayRule={this.setDisplayRule.bind(this)}
                    canDropRule={this.canDropRule.bind(this)}
                    setCanDropRule={this.setCanDropRule.bind(this)}
                    canDropLhs={canDropItem}
                    canDropRhs={ruleHovering}
                    userSession={this.props.userSession}
                    ruleName='cfg-earley-predict'/> <span className={'unbreakable'}>∈ P</span>
            </div>
        </div>
    }
}

export default CfgEarleyPredict;
