import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykNullAdjoin() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Null-Adjoin:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[γ, p<sub>⊥</sub>, i, f<sub>1</sub>, f<sub>2</sub>, j]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p<sub>⊤</sub>, i, f<sub>1</sub>, f<sub>2</sub>, j]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>f<sub>OA</sub>(γ, p) = 0</span></div>
    </div>)
}
