import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgEarleyScan extends React.Component {
    initial_values = {
        antecedence: '',
        i: '',
        displayI: 'i',
        j: '',
        displayJ: 'i',
        alpha: '',
        displayAlpha: 'α',
        a: '',
        displayA: 'a',
        beta: '',
        displayBeta: 'β',
        lhs: '',
        displayLhs: 'A',
        canDropItem: 0,
        displayItem: ''
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    displayI: 'i',
                    displayJ: 'j',
                    displayAlpha: 'α',
                    displayA: 'a',
                    displayBeta: 'β',
                    displayLhs: 'A'
                });
            } else {
                this.extractAdditionalItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const rule = item.itemForm.substring(1, firstComma);
        if (rule.indexOf('->') === -1) {
            return false;
        }
        const ruleSplit = rule.split('->');
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const spacePos = rhs.indexOf(' ', dotPos + 1);
        const b = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        return this.props.isInputSymbol(j, b);
    }

    extractAdditionalItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? '' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const a = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            lhs: lhs,
            alpha: alpha,
            i: i,
            j: j,
            a: a,
            beta: beta,
            canDropItem: 0,
            displayItem: ''
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const secondComma = item.itemForm.lastIndexOf(',');
        const ruleStack = item.itemForm.substring(1, firstComma);
        const i = item.itemForm.substring(firstComma + 1, secondComma);
        const j = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        if (ruleStack.indexOf('->') === -1) {
            this.setState({
                displayLhs: ruleStack,
                displayAlpha: '',
                displayI: i,
                displayJ: j,
                displayA: '',
                displayBeta: ''
            });
            return;
        }
        const ruleSplit = ruleStack.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const dotPos = rhs.indexOf('•');
        const alpha = dotPos === 0 ? 'ε' : rhs.substring(0, dotPos - 1);
        const spacePos = rhs.indexOf(' ', dotPos);
        const a = spacePos === -1 ? rhs.substring(dotPos + 1) : rhs.substring(dotPos + 1, spacePos);
        const beta = spacePos === -1 ? '' : rhs.substring(spacePos + 1);
        this.setState({
            displayLhs: lhs,
            displayAlpha: alpha,
            displayI: i,
            displayJ: j,
            displayA: a,
            displayBeta: beta
        });
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[' + this.state.lhs + ' -> ';
            if (this.state.alpha !== '') {
                newItem = newItem + this.state.alpha + ' ';
            }
            newItem = newItem + this.state.a + ' •';
            if (this.state.beta !== '') {
                newItem = newItem + this.state.beta;
            }
            newItem = newItem + ',' + this.state.i + ','
                + (parseInt(this.state.j) + 1) + ']';
            this.props.sendNewItemToTask(newItem, 'scan ' + this.state.a,
                [this.state.antecedence.id]);
            this.clear();

        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const lhs = this.state.displayLhs;
        const alpha = this.state.displayAlpha;
        const a = this.state.displayA;
        const beta = this.state.displayBeta;
        const i = this.state.displayI;
        const j = this.state.displayJ;
        const canDropItem = this.state.canDropItem;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Scan:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-earley-scan'
                                 elementLists={[[lhs, '->', alpha, '•' + a, beta], [i], [j]]}
                                 markings={[[itemHovering, 0, itemHovering, canDropItem, itemHovering],
                                     [itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[lhs, "->", alpha, a, "•" + beta], [i], [j, "+ 1"]]}
                        markings={[[itemHovering, 0, itemHovering, canDropItem, itemHovering],
                            [itemHovering], [itemHovering, 0]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'><span
                className={'unbreakable'}>w<sub>{j}+1</sub> =&nbsp;
                <span
                    className={canDropItem === 1 ? 'can-drop' : canDropItem === -1 ? 'cannot-drop' : ''}>{a}</span></span>
            </div>
        </div>
    }
}

export default CfgEarleyScan;
