import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykMoveGeneral() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Move-General:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'>
                <span className={'unbreakable'}>[γ,(p · 1)<sub>⊤</sub>, i<sub>1</sub>, f<sub>1</sub>, f<sub>2</sub>, k<sub>1</sub>],</span>&nbsp;...,&nbsp;
                <span className={'unbreakable'}>[γ,(p · j)<sub>⊤</sub>, i<sub>j</sub>, f'<sup>j</sup><sub>1</sub>, f'<sup>j</sup><sub>2</sub>, k<sub>j</sub>]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p⊥, i, f1 ⊕ ... ⊕ f'<sup>j</sup><sub>1</sub>, f2 ⊕ ... ⊕ f'<sup>j</sup><sub>2</sub>, j]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>i<sub>l</sub> = i<sub>l-1</sub> + k<sub>l-1</sub></span></div>
    </div>)
}
