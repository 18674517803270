import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyScan() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Scan:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[A(φ-vec) → Φ-vec, pos, &lt;i, j>, ρ-vec]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[A(φ-vec) → Φ-vec, pos + 1, &lt;i, j + 1>, ρ'-vec]</span>
            </div>
        </div>
        <div className='side-condition'>
            <span className={'unbreakable'}>φ-vec(i, j + 1) = w<sub>pos+1</sub></span>
        </div>
    </div>)
}
