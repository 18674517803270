import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main.js';
import {CookiesProvider} from "react-cookie";

ReactDOM.render(
    <CookiesProvider>
        <Main/>
    </CookiesProvider>,
    document.getElementById('root')
);
