import React from "react";
import {BACKEND_HOST, BACKEND_PATH, BACKEND_PORT, BACKEND_PROTOCOL} from "./constants";


function requestRestService(url) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            resolve(this.responseText);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.send();
    });
}

/**
 * Status page just checking if the backend responds.
 */
class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0
        };
    }

    componentDidMount() {
        document.title = "CL-Taskbox - Status";
        requestRestService(BACKEND_PROTOCOL + '//'
            + BACKEND_HOST + ':'
            + BACKEND_PORT + '/'
            + BACKEND_PATH + '/rest/status').then((result) => {
            if (result === "I am awake.") {
                this.setState({
                    status: 1
                });
            } else {

                this.setState({
                    status: 2
                });
            }
        }).catch(() => {
            this.setState({
                status: -1
            });
        });
    }

    render() {
        const status = this.state.status;
        switch (status) {
            case -1:
                return <div>
                    <svg height="10" width="10">
                        <circle cx="5" cy="5" r="5"
                                stroke-width="0" fill="crimson"/>
                    </svg>
                    Backend is not available.</div>;
            case 0:
                return <div><img alt='loadIndicator'
                                 src='./Spinner-1s-200px.gif'
                                 id={'spinner'}/></div>;
            case 1:
                return <div>
                    <svg height="10" width="10">
                        <circle cx="5" cy="5" r="5"
                                stroke-width="0" fill="limegreen"/>
                    </svg>
                    Backend is available.</div>;
            default:
                return <div>
                    <svg height="10" width="10">
                        <circle cx="5" cy="5" r="5"
                                stroke-width="0" fill="gold"/>
                    </svg>
                    Backend returned an unexpected response.</div>;
        }
    }
}

export default Status;
