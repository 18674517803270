import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from "../../taskcomponents/Task";
import Consequence from "../../taskcomponents/Consequence";
import {USER_CLEAR} from "../../constants";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class TagCykMoveBinary extends React.Component {
    initial_values = {
        antecedence1: '',
        antecedence2: '',
        gamma: '',
        displayGamma: 'ɣ',
        rho: '',
        displayRho: 'ρ',
        displayRho1: 'ρ.1',
        displayPos1: '⊤',
        i1: '',
        displayI1: 'i',
        j1: '',
        displayJ1: 'f1',
        k1: '',
        displayK1: 'f2',
        l1: '',
        displayL1: 'k',
        displayRho2: 'ρ.2',
        displayPos2: '⊤',
        j2: '',
        displayJ2: 'f1\'',
        k2: '',
        displayK2: 'f2\'',
        l2: '',
        displayL2: 'j',
        canDropItem1: 0,
        canDropItem2: 0,
        displayItem1: '',
        displayItem2: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItem1Values = this.extractAdditionalItem1Values.bind(this);
        this.extractAdditionalItem2Values = this.extractAdditionalItem2Values.bind(this);
        this.setDisplayItem1 = this.setDisplayItem1.bind(this);
        this.setDisplayItem2 = this.setDisplayItem2.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence1(item) {
        this.setState({
            antecedence1: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem1Values(item);
        this.checkNewItem();
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence2(item) {
        this.setState({
            antecedence2: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem2Values(item);
        this.checkNewItem();
    }

    extractAdditionalItem1Values(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const i = itemParts[2];
        const j = itemParts[3];
        const k = itemParts[4];
        const l = itemParts[5].substring(0, itemParts[5].length - 1);
        this.setState({
            gamma: gamma,
            rho: rho,
            i1: i,
            j1: j,
            k1: k,
            l1: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem1: ''
        });
    }

    extractAdditionalItem2Values(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const i = itemParts[2];
        const j = itemParts[3];
        const k = itemParts[4];
        const l = itemParts[5].substring(0, itemParts[5].length - 1);
        this.setState({
            gamma: gamma,
            rho: rho,
            l1: i,
            j2: j,
            k2: k,
            l2: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem2: ''
        });
    }

    extractAdditionalDisplayItem1Values(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho1 = itemParts[1].substring(0, itemParts[1].length - 1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const pos = itemParts[1].charAt(itemParts[1].length - 1);
        const i = itemParts[2];
        const j = itemParts[3];
        const k = itemParts[4];
        const l = itemParts[5].substring(0, itemParts[5].length - 1);
        this.setState({
            displayGamma: gamma,
            displayRho1: rho1,
            displayRho: rho,
            displayPos1: pos,
            displayI1: i,
            displayJ1: j,
            displayK1: k,
            displayL1: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem1: ''
        });
    }

    extractAdditionalDisplayItem2Values(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho2 = itemParts[1].substring(0, itemParts[1].length - 1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const pos = itemParts[1].charAt(itemParts[1].length - 1);
        const i = itemParts[2];
        const j = itemParts[3];
        const k = itemParts[4];
        const l = itemParts[5].substring(0, itemParts[5].length - 1);
        this.setState({
            displayGamma: gamma,
            displayRho2: rho2,
            displayRho: rho,
            displayPos2: pos,
            displayL1: i,
            displayJ2: j,
            displayK2: k,
            displayL2: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem2: ''
        });
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem1(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho1 = itemParts[1].substring(0, itemParts[1].length - 1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const pos = itemParts[1].charAt(itemParts[1].length - 1);
        const l = itemParts[5].substring(0, itemParts[5].length - 1);
        return ((gamma === this.state.gamma || this.state.antecedence2 === '')
            && (rho === this.state.rho || this.state.antecedence2 === '')
            && pos === '⊤' && (l === this.state.l1 || this.state.antecedence2 === '')
            && rho1.charAt(itemParts[1].length - 2) === '1');
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem2(item) {
        const itemParts = item.itemForm.split(',');
        const gamma = itemParts[0].substring(1);
        const rho = itemParts[1].substring(0, itemParts[1].length - 3);
        const rho2 = itemParts[1].substring(0, itemParts[1].length - 1);
        const pos = itemParts[1].charAt(itemParts[1].length - 1);
        const i = itemParts[2];
        return ((gamma === this.state.gamma || this.state.antecedence1 === '')
            && (rho === this.state.rho || this.state.antecedence1 === '')
            && pos === '⊤' && (i === this.state.l1 || this.state.antecedence1 === '')
            && rho2.charAt(itemParts[1].length - 2) === '2');
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem1(item) {
        this.setState({
            displayItem1: item
        });
        if (item === '') {
            if (this.state.antecedence1 === '') {
                this.setState({
                    displayGamma: 'γ',
                    displayRho1: 'p.1',
                    displayPos1: '⊤',
                    displayI1: 'i',
                    displayJ1: 'f1',
                    displayK1: 'f2',
                    displayL1: 'k'
                });
            } else {
                this.extractAdditionalDisplayItem1Values(this.state.antecedence1);
            }
        } else {
            this.extractAdditionalDisplayItem1Values(item);
        }
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem2(item) {
        this.setState({
            displayItem2: item
        });
        if (item === '') {
            if (this.state.antecedence2 === '') {
                this.setState({
                    displayGamma: 'γ',
                    displayRho2: 'p.2',
                    displayPos2: '⊤',
                    displayL1: 'k',
                    displayJ2: 'f1\'',
                    displayK2: 'f2\'',
                    displayL2: 'j'
                });
            } else {
                this.extractAdditionalDisplayItem2Values(this.state.antecedence2);
            }
        } else {
            this.extractAdditionalDisplayItem2Values(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem1(value) {
        this.setState({
            canDropItem1: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem2(value) {
        this.setState({
            canDropItem2: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropRule(value) {
        this.setState({
            canDropRule: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence1 !== '' && this.state.antecedence2 !== '') {
            let newItem = '[' + this.state.gamma + ',' + this.state.rho + '⊥,'
                + this.state.i1 + ','
                + (this.state.j1 === '-' ? this.state.j2 : this.state.j1) + ','
                + (this.state.k1 === '-' ? this.state.k2 : this.state.k1) + ','
                + this.state.l2 + ']';
            if (this.props.sendNewItemToTask(newItem, 'move-binary',
                [this.state.antecedence1.id,
                this.state.antecedence2.id].sort())) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession,
            'tag-cyk-move-binary'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const gamma = this.state.displayGamma;
        const rho1 = this.state.displayRho1;
        const pos1 = this.state.displayPos1;
        const i1 = this.state.displayI1;
        const j1 = this.state.displayJ1;
        const k1 = this.state.displayK1;
        const l1 = this.state.displayL1;
        const rho2 = this.state.displayRho2;
        const pos2 = this.state.displayPos2;
        const j2 = this.state.displayJ2;
        const k2 = this.state.displayK2;
        const l2 = this.state.displayL2;
        const rho = this.state.displayRho;
        const canDropItem1 = this.state.canDropItem1;
        const canDropItem2 = this.state.canDropItem2;
        const item1Hovering = canDropItem1 === 0 ? 0 : 1;
        const item2Hovering = canDropItem2 === 0 ? 0 : 1;
        let canDropGamma = 0;
        let canDropRho1 = 0;
        let canDropRho2 = 0;
        let canDropPos1 = 0;
        let canDropPos2 = 0;
        let canDropL1 = 0;
        if (canDropItem1 !== 0) {
            if (this.state.antecedence2 === ''
                || this.state.gamma === gamma) {
                canDropGamma = 1;
            } else {
                canDropGamma = -1;
            }
            if (this.state.antecedence2 === ''
                || this.state.l1 === l1) {
                canDropL1 = 1;
            } else {
                canDropL1 = -1;
            }
            if (pos1 === '⊤') {
                canDropPos1 = 1;
            } else {
                canDropPos1 = -1;
            }
            if (rho1.charAt(rho1.length - 1) === '1'
                && (this.state.antecedence2 === '' || this.state.rho
                    === rho1.substring(0, rho1.length - 2))) {
                canDropRho1 = 1;
            } else {
                canDropRho1 = -1;
            }
        }
        if (canDropItem2 !== 0) {
            if (this.state.antecedence1 === ''
                || this.state.gamma === gamma) {
                canDropGamma = 1;
            } else {
                canDropGamma = -1;
            }
            if (this.state.antecedence1 === ''
                || this.state.l1 === l1) {
                canDropL1 = 1;
            } else {
                canDropL1 = -1;
            }
            if (pos2 === '⊤') {
                canDropPos2 = 1;
            } else {
                canDropPos2 = -1;
            }
            if (rho2.charAt(rho2.length - 1) === '2'
                && (this.state.antecedence1 === '' || this.state.rho
                    === rho2.substring(0, rho2.length - 2))) {
                canDropRho2 = 1;
            } else {
                canDropRho2 = -1;
            }
        }
        const canDropRho = canDropRho1 === 0 ? canDropRho2 : canDropRho1;
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                      onClick={this.clearWithLog.bind(this)}>
                <FontAwesomeIcon className={"clickable-icon"}
                                 icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear <abbr>tag</abbr> move-binary</span>
                </button>
                Move-Binary:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='tag-cyk-move-binary1'
                                 elementLists={[[gamma], ['(', rho1, ')', pos1], [i1], [j1], [k1], [l1]]}
                                 markings={[[canDropGamma], [0, canDropRho1, 0, canDropPos1],
                                     [item1Hovering], [item1Hovering], [item1Hovering], [canDropL1]]}
                                 setAntecedence={this.setAntecedence1.bind(this)}
                                 canDropItem={this.canDropItem1.bind(this)}
                                 setDisplayItem={this.setDisplayItem1.bind(this)}
                                 setCanDropItem={this.setCanDropItem1.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>&nbsp;
                    <Antecedence antecedenceName='tag-cyk-move-binary2'
                                 elementLists={[[gamma], ['(', rho2, ')', pos2], [l1], [j2], [k2], [l2]]}
                                 markings={[[canDropGamma], [0, canDropRho2, 0, canDropPos2],
                                     [canDropL1], [item2Hovering], [item2Hovering], [item2Hovering]]}
                                 setAntecedence={this.setAntecedence2.bind(this)}
                                 canDropItem={this.canDropItem2.bind(this)}
                                 setDisplayItem={this.setDisplayItem2.bind(this)}
                                 setCanDropItem={this.setCanDropItem2.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div className='consequence'>
                    <Consequence
                        elementLists={[[gamma], [rho, '⊥'], [i1], [j1, '⊕', j2], [k1, '⊕', k2], [l2]]}
                        markings={[[canDropGamma], [canDropRho, 0], [item1Hovering],
                            [item1Hovering, 0, item2Hovering], [item1Hovering, 0, item2Hovering], [item2Hovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
            </div>
        </div>
    }
}

export default TagCykMoveBinary;
