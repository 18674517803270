import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'
import {requestLogRestService} from "../../taskcomponents/Task";
import Consequence from "../../taskcomponents/Consequence";
import {USER_CLEAR} from "../../constants";
import SideConditionRuleArray
    from "../../taskcomponents/SideConditionRuleArray";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgCykComplete extends React.Component {
    initial_values = {
        antecedence1: '',
        antecedence2: '',
        nt1: '',
        displayNt1: 'B',
        nt2: '',
        displayNt2: 'C',
        i1: '',
        displayI1: 'i',
        i2: '',
        displayI2: 'i + l1',
        l1: '',
        displayL1: 'l1',
        l2: '',
        displayL2: 'l2',
        lhs: '',
        displayLhs: 'A',
        rhs: '',
        prodRule: '',
        canDropItem1: 0,
        canDropItem2: 0,
        canDropRule: 0,
        displayItem: '',
        displayRule: '',
        eraseDisabled: true
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItem1Values = this.extractAdditionalItem1Values.bind(this);
        this.extractAdditionalItem2Values = this.extractAdditionalItem2Values.bind(this);
        this.extractAdditionalRuleValues = this.extractAdditionalRuleValues.bind(this);
        this.setDisplayItem1 = this.setDisplayItem1.bind(this);
        this.setDisplayItem2 = this.setDisplayItem2.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence1(item) {
        this.setState({
            antecedence1: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem1Values(item);
        this.checkNewItem();
    }

    /**
     * Set item as antecedence in state and extract different other values from
     * it to set them in state.
     */
    setAntecedence2(item) {
        this.setState({
            antecedence2: item,
            eraseDisabled: false
        });
        this.extractAdditionalItem2Values(item);
        this.checkNewItem();
    }

    extractAdditionalItem1Values(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const l = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        this.setState({
            nt1: nt,
            i1: i,
            l1: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem1: ''
        });
    }

    extractAdditionalItem2Values(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const l = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        this.setState({
            nt2: nt,
            i2: i,
            l2: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem2: ''
        });
    }

    extractAdditionalDisplayItem1Values(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const l = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        this.setState({
            displayNt1: nt,
            displayI1: i,
            displayL1: l,
            displayI2: Number(1) + Number(1),
            canDropItem: 0,
            canDropRule: 0,
            displayItem1: ''
        });
    }

    extractAdditionalDisplayItem2Values(item) {
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const l = item.itemForm.substring(comma2 + 1, item.itemForm.length - 1);
        this.setState({
            displayNt2: nt,
            displayI2: i,
            displayL2: l,
            canDropItem: 0,
            canDropRule: 0,
            displayItem2: ''
        });
    }

    /**
     * Set rule as production rule in state and extract different other values
     * from it to be set in state.
     */
    setProdRule(rule) {
        this.setState({prodRule: rule, eraseDisabled: false});
        this.extractAdditionalRuleValues(rule);
        this.checkNewItem();
    }

    extractAdditionalRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const rhsSplit = rhs.split(' ');
        this.setState({lhs: lhs, rhs: rhs, nt1: rhsSplit[0], nt2: rhsSplit[1]});
    }

    extractAdditionalDisplayRuleValues(rule) {
        const ruleSplit = rule.rule.split('->');
        const lhs = ruleSplit[0].trim();
        const rhs = ruleSplit[1].trim();
        const rhsSplit = rhs.split(' ');
        this.setState({
            displayLhs: lhs,
            displayNt1: rhsSplit[0],
            displayNt2: rhsSplit[1] !== undefined ? rhsSplit[1] : 'ε'
        });
    }

    setDisplayRule(rule) {
        this.setState({displayRule: rule});
        if (rule === '') {
            if (this.state.prodRule === '') {
                if (this.state.antecedence1 === '' && this.state.antecedence2 === '') {
                    this.setState({
                        displayLhs: 'A',
                        displayNt1: 'B',
                        displayNt2: 'C'
                    });
                } else {
                    if (this.state.antecedence1 !== '') {
                        this.extractAdditionalDisplayItem1Values(this.state.antecedence1);
                    }
                    if (this.state.antecedence2 !== '') {
                        this.extractAdditionalDisplayItem2Values(this.state.antecedence2);
                    }
                }
            } else {
                this.extractAdditionalDisplayRuleValues(this.state.prodRule);
            }
        } else {
            this.extractAdditionalDisplayRuleValues(rule);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem1(item) {
        if (this.state.antecedence2 === '' && this.state.prodRule === '') {
            return true;
        }
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const l = item.itemForm.substring(comma2 + 1, item.itemForm.length);
        const rhsSplit = this.state.rhs.split(' ');
        return (this.state.antecedence2 === ''
            || parseInt(this.state.i2) === parseInt(i) + parseInt(l)) &&
            (this.state.prodRule === '' || nt === rhsSplit[0]);
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem2(item) {
        if (this.state.antecedence1 === '' && this.state.prodRule === '') {
            return true;
        }
        const comma1 = item.itemForm.indexOf(',');
        const comma2 = item.itemForm.lastIndexOf(',');
        const nt = item.itemForm.substring(1, comma1);
        const i = item.itemForm.substring(comma1 + 1, comma2);
        const rhsSplit = this.state.rhs.split(' ');
        return (this.state.antecedence1 === ''
            || parseInt(i) === parseInt(this.state.i1) + parseInt(this.state.l1))
            && (this.state.prodRule === '' || nt === rhsSplit[1]);
    }

    /**
     * Returns true if rule can be dropped on the side condition component.
     */
    canDropRule(rule) {
        const ruleSplit = rule.rule.split('->');
        const rhs = ruleSplit[1].trim();
        const rhsSplit = rhs.split(' ');
        if (rhsSplit.length !== 2) {
            return false;
        }
        if (this.state.antecedence1 === '' && this.state.antecedence2 === '') {
            return true;
        }
        return (this.state.antecedence1 === '' || this.state.nt1 === rhsSplit[0])
            && (this.state.antecedence2 === '' || this.state.nt2 === rhsSplit[1]);
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem1(item) {
        this.setState({
            displayItem1: item
        });
        if (item === '') {
            if (this.state.antecedence1 === '') {
                if (this.state.prodRule === '') {
                    this.setState({
                        displayNt1: 'B',
                        displayI1: 'i',
                        displayL1: 'l1',
                        displayI2: 'i + l1'
                    });
                } else {
                    this.setState({
                        displayI1: 'i',
                        displayL1: 'l1'
                    });
                    this.extractAdditionalDisplayRuleValues(this.state.prodRule);
                }
            } else {
                this.extractAdditionalDisplayItem1Values(this.state.antecedence1);
            }
        } else {
            this.extractAdditionalDisplayItem1Values(item);
        }
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem2(item) {
        this.setState({
            displayItem2: item
        });
        if (item === '') {
            if (this.state.antecedence2 === '') {
                if (this.state.prodRule === '') {
                    this.setState({
                        displayNt2: 'C',
                        displayI2: 'i + l1',
                        displayL2: 'l2'
                    });
                } else {
                    this.setState({
                        displayI2: 'i + l1',
                        displayL2: 'l2'
                    });
                    this.extractAdditionalDisplayRuleValues(this.state.prodRule);
                }
            } else {
                this.extractAdditionalDisplayItem2Values(this.state.antecedence2);
            }
        } else {
            this.extractAdditionalDisplayItem2Values(item);
        }
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem1(value) {
        this.setState({
            canDropItem1: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem2(value) {
        this.setState({
            canDropItem2: value
        });
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropRule(value) {
        this.setState({
            canDropRule: value
        });
    }

    /**
     * If both antecedence and production rule are specified a new item is
     * generated from them and send to the task to be added to the table.
     */
    checkNewItem() {
        if (this.state.antecedence1 !== '' && this.state.antecedence2 !== ''
            && this.state.prodRule !== '') {
            let newItem = '[' + this.state.lhs + ',' + this.state.i1 + ','
                + (parseInt(this.state.l1) + parseInt(this.state.l2)) + ']';
            if (this.props.sendNewItemToTask(newItem, 'complete '
                + this.state.prodRule.rule, [this.state.antecedence1.id,
                this.state.antecedence2.id].sort())) {
                this.clear();
            }
        }
    }

    /**
     * Resets the initial values and sends log request.
     */
    clearWithLog() {
        requestLogRestService(USER_CLEAR(this.props.userSession,
            'cfg-cyk-complete'));
        this.clear();
    }

    /**
     * Resets the initial values of the state.
     */
    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const nt1 = this.state.displayNt1;
        const nt2 = this.state.displayNt2;
        const i1 = this.state.displayI1;
        const i2 = this.state.displayI2;
        const l1 = this.state.displayL1;
        const l2 = this.state.displayL2;
        const lhs = this.state.displayLhs;
        const rhsSplit = [nt1, nt2];
        const canDropItem1 = this.state.canDropItem1;
        const canDropItem2 = this.state.canDropItem2;
        const canDropRule = this.state.canDropRule;
        const ruleHovering = this.state.displayRule === '' ? 0 : 1;
        const item1Hovering = canDropItem1 === 0 ? 0 : 1;
        const item2Hovering = canDropItem2 === 0 ? 0 : 1;
        let canDropI1l1 = 0;
        let canDropNt1 = 0;
        let canDropNt2 = 0;
        if (canDropItem1 !== 0) {
            if (this.state.antecedence2 === ''
                || Number(i1) + Number(l1) === Number(this.state.i2)) {
                canDropI1l1 = 1;
            } else {
                canDropI1l1 = -1;
            }
            if (this.state.prodRule === '' || rhsSplit[0] === this.state.nt1) {
                canDropNt1 = 1;
            } else {
                canDropNt1 = -1;
            }
        }
        if (canDropItem2 !== 0) {
            if (this.state.antecedence1 === ''
                || Number(i1) + Number(l1) === Number(i2)) {
                canDropI1l1 = 1;
            } else {
                canDropI1l1 = -1;
            }
            if (this.state.prodRule === '' || rhsSplit[1] === this.state.nt2) {
                canDropNt2 = 1;
            } else {
                canDropNt2 = -1;
            }
        }
        if (canDropRule !== 0) {
            if (this.state.antecedence1 === '' || rhsSplit[0] === this.state.nt1) {
                canDropNt1 = 1;
            } else {
                canDropNt1 = -1;
            }
            if (this.state.antecedence2 === '' || rhsSplit[1] === this.state.nt2) {
                canDropNt2 = 1;
            } else {
                canDropNt2 = -1;
            }
            if (rhsSplit[1] === 'ε') {
                canDropNt1 = -1;
                canDropNt2 = -1;
            }
        }
        let canDropRhs = [canDropNt1, canDropNt2];
        return <div className='deduction-rule'>
            <div className='rule-name'>
                <button id='ded-rule-erase' disabled={this.state.eraseDisabled}
                      onClick={this.clearWithLog.bind(this)}>
                <FontAwesomeIcon className={"clickable-icon"}
                                 icon={faEraser}/>
                    <span
                        className={'accessibility-label'}>clear <abbr>cyk</abbr> complete</span>
                </button>
                Complete:
            </div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-cyk-complete1'
                                 elementLists={[[nt1], [i1], [l1]]}
                                 markings={[[canDropNt1], [item1Hovering],
                                     [canDropI1l1]]}
                                 setAntecedence={this.setAntecedence1.bind(this)}
                                 canDropItem={this.canDropItem1.bind(this)}
                                 setDisplayItem={this.setDisplayItem1.bind(this)}
                                 setCanDropItem={this.setCanDropItem1.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>&nbsp;
                    <Antecedence antecedenceName='cfg-cyk-complete2'
                                 elementLists={[[nt2], [i2], [l2]]}
                                 markings={[[canDropNt2], [canDropI1l1],
                                     [item2Hovering]]}
                                 setAntecedence={this.setAntecedence2.bind(this)}
                                 canDropItem={this.canDropItem2.bind(this)}
                                 setDisplayItem={this.setDisplayItem2.bind(this)}
                                 setCanDropItem={this.setCanDropItem2.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div className='consequence'>
                    <Consequence
                        elementLists={[[lhs], [i1], [l1, "+", l2]]}
                        markings={[[ruleHovering], [item1Hovering], [canDropI1l1,
                            0, item2Hovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <SideConditionRuleArray
                    displayLhs={lhs} displayRhs={rhsSplit}
                    setProdRule={this.setProdRule.bind(this)}
                    setDisplayRule={this.setDisplayRule.bind(this)}
                    canDropRule={this.canDropRule.bind(this)}
                    setCanDropRule={this.setCanDropRule.bind(this)}
                    canDropLhs={ruleHovering}
                    canDropRhs={canDropRhs}
                    userSession={this.props.userSession}
                    ruleName='cfg-cyk-complete'/> <span className={'unbreakable'}>∈ P</span>
            </div>
        </div>
    }
}

export default CfgCykComplete;
