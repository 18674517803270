import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagEarleyPrefixValidGoal() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Goal:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[α, ε, ra, 0, 0, -, -, n, 0] </span>
            </div>
        </div>
    </div>)
}
