import React from 'react';
import {DropTarget} from 'react-dnd';
import {requestLogRestService} from './Task';
import PropTypes from "prop-types";
import {
    USER_DRAG_ENTER_SIDECONDITION,
    USER_DRAG_LEAVE_SIDECONDITION, USER_DROP_RULE
} from "../constants";

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    };
}

const spec = {
    canDrop(props, monitor) {
        return props.canDropRule(monitor.getItem());
    },

    hover(props, monitor) {
        const canDrop = monitor.canDrop();
        const rule = monitor.getItem();
        props.setDisplayRule(rule);
        if (canDrop) {
            props.setCanDropRule(1);
        } else {
            props.setCanDropRule(-1);
        }
    },

    drop(props, monitor, component) {
        const rule = monitor.getItem();
        requestLogRestService(USER_DROP_RULE(props.userSession, rule.rule, props.ruleName));
        if (monitor.didDrop()) {
            return;
        }
        const canDrop = props.canDropRule(rule);
        if (canDrop) {
            props.setCanDropRule(0);
            component.setDisplayRule(rule);
        }
    }
};

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class SideConditionRule extends React.Component {
    constructor(props) {
        super(props);
        this.setDisplayRule = this.setDisplayRule.bind(this);
    }

    setDisplayRule(item) {
        this.props.setProdRule(item);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.isOver && nextProps.isOver) {
            requestLogRestService(USER_DRAG_ENTER_SIDECONDITION(this.props.userSession
                , this.props.ruleName));
        }

        if (this.props.isOver && !nextProps.isOver) {
            requestLogRestService(USER_DRAG_LEAVE_SIDECONDITION(this.props.userSession
                , this.props.ruleName));
            this.props.setCanDropRule(0);
            this.props.setDisplayRule('');
        }
    }

    render() {
        const {isOver, canDrop, connectDropTarget} = this.props;
        const displayRhs = this.props.displayRhs;
        const displayLhs = this.props.displayLhs;
        const canDropLhs = this.props.canDropLhs;
        const canDropRhs = this.props.canDropRhs;
        return connectDropTarget(<span
            className={"side-condition-rule droppable unbreakable"}>
            <span
                className={canDropLhs === 1 || (isOver && canDrop) ? 'can-drop'
                    : canDropLhs === -1 || (isOver && !canDrop) ? 'cannot-drop' : ''}>{displayLhs}</span>&nbsp;→&nbsp;
            <span
                className={canDropRhs === 1 || (isOver && canDrop) ? 'can-drop'
                    : canDropRhs === -1 || (isOver && !canDrop) ? 'cannot-drop' : ''}>{displayRhs}</span></span>)
    }
}

SideConditionRule.propTypes = {
    displayRhs: PropTypes.string.isRequired,
    displayLhs: PropTypes.string.isRequired,
    canDropLhs: PropTypes.number.isRequired,
    canDropRhs: PropTypes.number.isRequired,
    ruleName: PropTypes.string.isRequired,
    userSession: PropTypes.string.isRequired
};

export default DropTarget('prodRule', spec, collect)(SideConditionRule);
