import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function SrcgEarleyGoal() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Goal:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[S(φ-vec) → Φ-vec, n, &lt;1, j>, ψ]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>with |φ-vec(1)| = j</span></div>
    </div>)
}
