import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function PcfgCykComplete() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Complete:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>x1 : [B, i, j],</span>&nbsp;
                <span className={'unbreakable'}>x2 : [C, j, k]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>x1 + x2 + |log(p)| : [A, i, k]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>p : A → B C</span></div>
    </div>)
}
