import React from "react";
import Breadcrumb from "./Breadcrumb";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faHandPaper} from "@fortawesome/free-solid-svg-icons";
import {faComment, faEye} from "@fortawesome/free-regular-svg-icons";

/**
 * A link to the view that provides parsing exercises, basically only containing
 * algorithm and formalism.
 */
function TaskLink(props) {
    return (
        <a className='task-link'
           href={'task?grammar=' + props.grammar + '&formalism='
           + props.formalism + '&algorithm=' + props.algorithm}>{props.label}</a>
    );
}

/**
 * A link to the page saying what this site is about.
 */
function AboutLink() {
    return (
        <a href={'about'}>
            <span className='main-link'><FontAwesomeIcon icon={faInfoCircle}/> About</span>
        </a>
    );
}

/**
 * A link to the page with the feedback form
 */
function FeedbackLink() {
    return (
        <a href={'contact'}>
            <span className='main-link'><FontAwesomeIcon icon={faComment}/> Contact</span>
        </a>
    );
}

/**
 * Displays the main part of the entry page with links leading to different
 * pages.
 */
class Home extends React.Component {
    componentDidMount() {
        document.title = "CL-Taskbox - Home"
    }

    render() {
        const emptyCfg = 'G%20%3D%20<N%2C%20T%2C%20S%2C%20P>%0AN%20%3D%20'
            + '%7B%7D%0AT%20%3D%20%7B%7D%0AS%20%3D%20S%0AP%20%3D%20%7B%7D%0A';
        const emptyTag = 'G%20%3D%20<N%2C%20T%2C%20S%2C%20I%2C%20A>'
            + '%0AN%20%3D%20%7B%7D%0AT%20%3D%20%7B%7D%0AS%20%3D%20S'
            + '%0AI%20%3D%20%7B%7D%0AA%20%3D%20%7B%7D';
        const emptySrcg = 'G%20%3D%20<N%2C%20T%2C%20V%2C%20P%2C%20S>'
            + '%0AN%20%3D%20%7B%7D%0AT%20%3D%20%7B%7D%0AV%20%3D%20%7B%7D%0AP'
            + '%20%3D%20%7B%7D%0AS%20%3D%20S%0A';
        return (<span>
                <Breadcrumb pathList={[]}/>
            <div className='content-body'>
                <div className='navigation'>
                    <AboutLink/><FeedbackLink/>
                </div>
                <div id='introduction'>Train <b>parsing</b> as
                    deduction with automatically generated
                exercises and <b>formal grammars</b>.
                For students of computational linguistics, computer science and
                    similar.</div>
                <h2>Exercises <FontAwesomeIcon icon={faHandPaper}/></h2>
                <h3>Context-Free Languages</h3>
                <ul>
                    <li><TaskLink formalism='cfg' algorithm='cfg-topdown'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Top-Down</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-shiftreduce'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Shift-Reduce</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-leftcorner'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Left-Corner</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-cyk'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr><abbr>CYK</abbr></span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-earley'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Earley</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-earley-bottomup'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Earley Bottom-up</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-earley-passive'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Earley Passive</span>}
                                  grammar={emptyCfg}/></li>
                </ul>
                <h2>No Exercises, Just Parsing <FontAwesomeIcon icon={faEye}/></h2>
                <h3>Context-Free Languages</h3>
                <ul>
                    <li><TaskLink formalism='cfg' algorithm='cfg-cyk-extended'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>CYK Extended</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-cyk-general'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>CYK General</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-leftcorner-chart'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Left-Corner Chart</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='cfg' algorithm='cfg-unger'
                                  label={<span><abbr
                                      className={'accessibility-label'}>CFG</abbr>Unger</span>}
                                  grammar={emptyCfg}/></li>
                </ul>
                <h3>Probabilistic Context-Free Languages</h3>
                <ul>
                    <li><TaskLink formalism='pcfg' algorithm='pcfg-astar'
                                  label={<span><abbr
                                      className={'accessibility-label'}>PCFG</abbr>A*</span>}
                                  grammar={emptyCfg}/></li>
                    <li><TaskLink formalism='pcfg' algorithm='pcfg-cyk'
                                  label={<span><abbr
                                      className={'accessibility-label'}>PCFG</abbr>CYK</span>}
                                  grammar={emptyCfg}/></li>
                </ul>
                <h3>Tree-Adjoining Languages</h3>
                <ul>
                    <li><TaskLink formalism='tag' algorithm='tag-cyk-extended'
                                  label={<span><abbr
                                      className={'accessibility-label'}>TAG</abbr>CYK</span>}
                                  grammar={emptyTag}/></li>
                    <li><TaskLink formalism='tag' algorithm='tag-cyk-general'
                                  label={<span><abbr
                                      className={'accessibility-label'}>TAG</abbr>CYK General</span>}
                                  grammar={emptyTag}/></li>
                    <li><TaskLink formalism='tag' algorithm='tag-earley'
                                  label={<span><abbr
                                      className={'accessibility-label'}>TAG</abbr>Earley</span>}
                                  grammar={emptyTag}/></li>
                    <li><TaskLink formalism='tag' algorithm='tag-earley-prefixvalid'
                                  label={<span><abbr
                                      className={'accessibility-label'}>TAG</abbr>Earley Prefix Valid</span>}
                                  grammar={emptyTag}/></li>
                </ul>
                <h3>Linear Context-Free Rewriting Languages</h3>
                <ul>
                    <li><TaskLink formalism='srcg' algorithm='srcg-cyk-extended'
                                  label={<span><abbr
                                      className={'accessibility-label'}>sRCG</abbr>CYK</span>}
                                  grammar={emptySrcg}/></li>
                    <li><TaskLink formalism='srcg' algorithm='srcg-cyk-general'
                                  label={<span><abbr
                                      className={'accessibility-label'}>sRCG</abbr>CYK General</span>}
                                  grammar={emptySrcg}/></li>
                    <li><TaskLink formalism='srcg' algorithm='srcg-earley'
                                  label={<span><abbr
                                      className={'accessibility-label'}>sRCG</abbr>Earley</span>}
                                  grammar={emptySrcg}/></li>
                </ul>
            </div></span>
        );
    }
}

export default Home;
