import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function TagCykSubstitute() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Substitute:</div>
        <div className='middle-rule-part'>
            <div
                className='antecedence'><span className={'unbreakable'}>[α, ε⊤, i, −, −, j]</span>
            </div>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[γ, p⊤, i, −, −, j]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>l(α, ε) = l(γ, p)</span></div>
    </div>)
}
