import React from "react";
import {BACKEND_PATH, BACKEND_PORT} from "./constants";


/**
 * redirect to json output for convenience
 */
function RedirectToJson() {
    function redirectToLogin() {
        document.location = document.location.protocol + '//'
          + document.location.hostname + ':' + BACKEND_PORT + '/'
          + BACKEND_PATH + '/rest/deduction' + document.location.search;
    }

    return (<script>{redirectToLogin()}
    </script>);
}

export default RedirectToJson;
