import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgShiftReduceShift extends React.Component {
    initial_values = {
        antecedence: '',
        i: 'i',
        a: 'a',
        gamma: 'Γ',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        this.canDropItem = this.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    i: 'i',
                    a: 'a',
                    gamma: 'Γ'
                });
            } else {
                this.extractAdditionalItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    canDropItem(item) {
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        return this.props.hasInputSymbol(parseInt(i) + 1);
    }

    extractAdditionalItemValues(item) {
        const stackHeadEnd = item.itemForm.lastIndexOf(',');
        const gamma = item.itemForm.substring(1, stackHeadEnd);
        const i = item.itemForm.substring(item.itemForm.lastIndexOf(',') + 1,
            item.itemForm.length - 1);
        const newI = (parseInt(i) + 1);
        let a = this.props.getInputSymbol(newI);
        if (a == null | a === '') {
            a = "ε";
        }
        this.setState({gamma: gamma, i: i, a: a});
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[';
            if (this.state.gamma !== '' && this.state.gamma !== 'ε') {
                newItem = newItem + this.state.gamma + ' ';
            }
            const a = this.state.a;
            newItem = newItem + a + ',' + (parseInt(this.state.i) + 1) + ']';
            this.props.sendNewItemToTask(newItem, 'shift ' + a,
                [this.state.antecedence.id]);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const gamma = this.state.gamma;
        const i = this.state.i;
        const a = this.state.a;
        const canDropItem = this.state.canDropItem;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Shift:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-shiftreduce-shift'
                                 elementLists={[[gamma], [i]]}
                                 markings={[[itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={this.canDropItem.bind(this)}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[gamma, a], [i, "+ 1"]]}
                        markings={[[itemHovering, canDropItem], [itemHovering, 0]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
            <div className='side-condition'>
                <span className={'unbreakable'}>w<sub>{i}+1</sub> =&nbsp;
                    <span
                        className={canDropItem === 1 ? 'can-drop' : canDropItem === -1 ? 'cannot-drop' : ''}>{a}</span>
                </span>
            </div>
        </div>
    }
}

export default CfgShiftReduceShift;
