import React from 'react';
import PropTypes from 'prop-types';
import {DragSource} from 'react-dnd';
import {requestLogRestService} from './Task.js';
import {
    USER_DRAG_START_RULE,
    USER_DRAG_STOP_RULE
} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";

/**
 * Implements the drag source contract.
 */
const productionRuleSource = {
    beginDrag(props) {
        requestLogRestService(USER_DRAG_START_RULE(props.userSession, props.rule));
        return {
            rule: props.rule
        };
    },
    endDrag(props) {
        requestLogRestService(USER_DRAG_STOP_RULE(props.userSession, props.rule));
        return {
            rule: props.rule
        };
    }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

const propTypes = {
    rule: PropTypes.string.isRequired,
    userSession: PropTypes.string.isRequired,

    // Injected by React DnD:
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired
};

/**
 * The item in the deduction table that can be used by the user to derive more
 * items.
 */
class ProductionRule extends React.Component {
    render() {
        const {isDragging, connectDragSource, rule} = this.props;
        return connectDragSource(<button className='draggable'
                                       style={{opacity: isDragging ? 0.5 : 1}}>
            <FontAwesomeIcon className={'drag-indicator'}
                             icon={faEllipsisV}/>
            <FontAwesomeIcon className={'drag-indicator'}
                             icon={faEllipsisV}/>
            <span>{rule}</span>
        </button>);
    };
}

ProductionRule.propTypes = propTypes;
export default DragSource('prodRule', productionRuleSource, collect)(ProductionRule);
