import React from 'react'

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
export function CfgLeftCornerChartScanEpsilon() {
    return (<div className='deduction-rule'>
        <div className='rule-name'>Scan-ε:</div>
        <div className='middle-rule-part'>
            <hr/>
            <div
                className='consequence'><span className={'unbreakable'}>[ε, i, 0]</span>
            </div>
        </div>
        <div className='side-condition'><span className={'unbreakable'}>0 &le; i &le; n</span></div>
    </div>)
}
