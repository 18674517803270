import React from 'react'
import Antecedence from '../../taskcomponents/Antecedence.js'
import Consequence from "../../taskcomponents/Consequence";

/**
 * Displays the corresponding deduction rule where items and production rules
 * can be dropped.
 */
class CfgLeftCornerRemove extends React.Component {
    initial_values = {
        antecedence: '',
        x: '',
        displayX: 'X',
        alpha: '',
        displayAlpha: 'α',
        b: '',
        displayB: 'X',
        beta: '',
        displayBeta: 'β',
        gamma: '',
        displayGamma: 'γ',
        displayItem: '',
        canDropItem: 0
    };

    constructor(props) {
        super(props);
        this.checkNewItem = this.checkNewItem.bind(this);
        this.clear = this.clear.bind(this);
        this.extractAdditionalItemValues = this.extractAdditionalItemValues.bind(this);
        this.setDisplayItem = this.setDisplayItem.bind(this);
        CfgLeftCornerRemove.canDropItem = CfgLeftCornerRemove.canDropItem.bind(this);
        this.state = this.initial_values;
    }

    /**
     * Sets the canDropItem value in the state. 0 - no coloring, 1 - positive
     * highlighting of some parts, -1 negative highlighting.
     */
    setCanDropItem(value) {
        this.setState({
            canDropItem: value
        });
    }

    /**
     * Sets item as displayItem in state and extracts different values from it
     * to be set in state.
     */
    setDisplayItem(item) {
        this.setState({
            displayItem: item
        });
        if (item === '') {
            if (this.state.antecedence === '') {
                this.setState({
                    displayX: 'X',
                    displayAlpha: 'α',
                    displayB: 'X',
                    displayBeta: 'β',
                    displayGamma: 'γ'
                });
            } else {
                this.extractAdditionalDisplayItemValues(this.state.antecedence);
            }
        } else {
            this.extractAdditionalDisplayItemValues(item);
        }
    }

    /**
     * Returns true if item can be dropped on the antecedence component.
     */
    static canDropItem(item) {
        const firstComma = item.itemForm.indexOf(',');
        const firstStack = item.itemForm.substring(1, firstComma);
        const secondComma = item.itemForm.lastIndexOf(',');
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const firstSpace = firstStack.indexOf(' ');
        const secondSpace = secondStack.indexOf(' ');
        const x1 = firstSpace === -1 ? firstStack : firstStack.substring(0, firstSpace);
        const x2 = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        return x1 === x2 && x1.length > 0;
    }

    extractAdditionalItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const firstStack = item.itemForm.substring(1, firstComma);
        const secondComma = item.itemForm.lastIndexOf(',');
        const firstSpace = firstStack.indexOf(' ');
        const x = firstSpace === -1 ? firstStack : firstStack.substring(0, firstSpace);
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const gamma = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const secondSpace = secondStack.indexOf(' ');
        const b = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        const alpha = firstSpace === -1 ? 'ε' : firstStack.substring(firstSpace + 1);
        const beta = secondSpace === -1 ? 'ε' : secondStack.substring(secondSpace + 1);
        this.setState({
            x: x,
            b: b,
            alpha: alpha,
            beta: beta,
            gamma: gamma
        });
    }

    extractAdditionalDisplayItemValues(item) {
        const firstComma = item.itemForm.indexOf(',');
        const firstStack = item.itemForm.substring(1, firstComma);
        const secondComma = item.itemForm.lastIndexOf(',');
        const firstSpace = firstStack.indexOf(' ');
        const x = firstSpace === -1 ? firstStack : firstStack.substring(0, firstSpace);
        const secondStack = item.itemForm.substring(firstComma + 1, secondComma);
        const gamma = item.itemForm.substring(secondComma + 1, item.itemForm.length - 1);
        const secondSpace = secondStack.indexOf(' ');
        const b = secondSpace === -1 ? secondStack : secondStack.substring(0, secondSpace);
        const alpha = firstSpace === -1 ? 'ε' : firstStack.substring(firstSpace + 1);
        const beta = secondSpace === -1 ? 'ε' : secondStack.substring(secondSpace + 1);
        this.setState({
            displayX: x,
            displayB: b,
            displayAlpha: alpha,
            displayBeta: beta,
            displayGamma: gamma
        });
    }

    setAntecedence(item) {
        this.setState({antecedence: item});
        this.extractAdditionalItemValues(item);
        this.checkNewItem();
    }

    checkNewItem() {
        if (this.state.antecedence !== '') {
            let newItem = '[' + this.state.alpha + ',' + this.state.beta + ','
                + this.state.gamma + ']';
            this.props.sendNewItemToTask(newItem, 'remove ' + this.state.x,
                [this.state.antecedence.id]);
            this.clear();
        }
    }

    clear() {
        this.setState(this.initial_values);
    }

    render() {
        const x = this.state.displayX;
        const alpha = this.state.displayAlpha;
        const b = this.state.displayB;
        const beta = this.state.displayBeta;
        const gamma = this.state.displayGamma;
        const canDropItem = this.state.canDropItem;
        const itemHovering = canDropItem === 0 ? 0 : 1;
        return <div className='deduction-rule'>
            <div className='rule-name'>Remove:</div>
            <div className='middle-rule-part'>
                <div
                    className='antecedence'>
                    <Antecedence antecedenceName='cfg-leftcorner-remove'
                                 elementLists={[[x, alpha], [b, beta], [gamma]]}
                                 markings={[[canDropItem, itemHovering],
                                     [canDropItem, itemHovering], [itemHovering]]}
                                 setAntecedence={this.setAntecedence.bind(this)}
                                 canDropItem={CfgLeftCornerRemove.canDropItem}
                                 setDisplayItem={this.setDisplayItem.bind(this)}
                                 setCanDropItem={this.setCanDropItem.bind(this)}
                                 userSession={this.props.userSession}
                                 maxItem={this.props.maxItem}/>
                </div>
                <hr/>
                <div
                    className='consequence'>
                    <Consequence
                        elementLists={[[alpha], [beta], [gamma]]}
                        markings={[[itemHovering], [itemHovering], [itemHovering]]}
                        maxItem={this.props.maxItem}/>
                </div>
            </div>
        </div>
    }
}

export default CfgLeftCornerRemove;
